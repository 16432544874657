import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import Banner from "../../../assets/images/banner.png";
import User from "../../../assets/images/user.png";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import React, { useState } from "react";
import ArrowRight from "../../../assets/images/arrowRight.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "../../../api";
import { isEmptyNullUndefined } from "../../utils/utils";
import { AUTHENTICATE_URL } from "../../../api/apiServices";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
  const employeeDetails = useSelector((state) => state?.empData?.empData); 
  const [showPassword, setShowPassword] = useState({
    old:false,
    new:false,
    reWrite:false,
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  // console.log("employeeDetails", employeeDetails);
  const state = useSelector((state) => state);

  const changePasswordApi = axios.create({baseURL: AUTHENTICATE_URL,});

  const isUserToken = () => {
    const userToken = state && state.user && state.user.userToken;
    return !!(userToken && Object.keys(userToken).length > 0);
  };

  changePasswordApi.interceptors.request.use((request) => {
    // Do something before request is sent
    request.baseURL = AUTHENTICATE_URL;
    const isToken = isUserToken();
    if (isToken) {
      const userToken =
        state &&
        state.user &&
        state.user.userToken &&
        state.user.userToken.id_token;
      request.headers.Authorization = "Bearer " + userToken;
    } else {
      request.headers = {};
    }
    return request;
  });

  const [oldPassword, setOldPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [retypeNewPassword, setRetypeNewPassword] = useState(null)

  const [formError, setFormError] = React.useState({
    oldPassword: {isError: false, errorMessage: ''},
    newPassword: {isError: false, errorMessage: ''},
    retypeNewPassword: {isError: false, errorMessage: ''}
  })

  const handleOldPassword = (event) => {
    const tempOldPassword = {...oldPassword}
    const tempFormError = {...formError}
    tempFormError.oldPassword.isError = false
    tempFormError.oldPassword.errorMessage = ''
    setFormError(tempFormError)
    setOldPassword(event.target.value)
  }
  const handleNewPassword = (event) => {
    const tempNewPassword = {...newPassword}
    const tempFormError = {...formError}
    tempFormError.newPassword.isError = false
    tempFormError.newPassword.errorMessage = ''
    setFormError(tempFormError)
    setNewPassword(event.target.value)
  }
  const handleRetypeNewPassword = (event) => {
    const tempOldPassword = {...retypeNewPassword}
    const tempFormError = {...formError}
    tempFormError.retypeNewPassword.isError = false
    tempFormError.retypeNewPassword.errorMessage = ''
    setFormError(tempFormError)
    setRetypeNewPassword(event.target.value)
  }
  // console.log('oldPassword', oldPassword)



  const getValidate = () => {
    // console.log('in validate')
    let isValid = true;

    const tempOldPassword = oldPassword
    const tempNewPassword = newPassword
    const tempRetypeNewPassword = retypeNewPassword
    const tempError = { ...formError };

    if (!isEmptyNullUndefined(tempNewPassword)) {
      if (
        !tempNewPassword.match(passwordRegex)
      ) {
        // console.log('!tempNewPassword.match(passwordRegex)', !tempNewPassword.match(passwordRegex))
        tempError.newPassword.isError = true;
        tempError.newPassword.errorMessage =
          'must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
        isValid = false;
      }
    }
    if ((!isEmptyNullUndefined(tempNewPassword)) && (!isEmptyNullUndefined(tempRetypeNewPassword))) {
      if((tempNewPassword) != (tempRetypeNewPassword)) {
        tempError.retypeNewPassword.isError = true;
        tempError.retypeNewPassword.errorMessage = 'Passwords must match'
        isValid = false
      }
    }
    if (isEmptyNullUndefined(tempOldPassword)) {
      tempError.oldPassword.isError = true;
      tempError.oldPassword.errorMessage = 'Please Enter old password';
      isValid = false;
    }
    if (isEmptyNullUndefined(tempNewPassword)) {
      tempError.newPassword.isError = true;
      tempError.newPassword.errorMessage = 'Please Enter new password'
      isValid = false
    }
    if (isEmptyNullUndefined(tempRetypeNewPassword)) {
      tempError.retypeNewPassword.isError = true;
      tempError.retypeNewPassword.errorMessage = 'Please Re-enter new password'
      isValid = false
    }

    if (isValid) {
    //   return true;
    // console.log('validate successfull')
        submitChangePassword()
    } else {
      setFormError(() => tempError)
    // console.log('validate not successfull')
      
    }
  }

  const submitChangePassword = () => {    
    setSubmitLoader(true);
      let data = {
      "currentPassword": oldPassword,
      "newPassword": newPassword
      }

      changePasswordApi
        .post("/account/change-password", data)
        .then((res) => {
          toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;Password
              {` changed successfully`}
            </div>
          );
          setSubmitLoader(false);
          setOldPassword(null);
          setNewPassword(null);
          setRetypeNewPassword(null);
          // setChangePasswordDetails(initialEmptyChangePasswordForm);
          // setFormError(initialEmptyChangePasswordFormErrorList);
        })
        .catch((e) => {
          toast.error(e?.response?.data?.title);
          setSubmitLoader(false);
        });
  }
  
  const handleShowPasword = (value) => {
    let temp = structuredClone(showPassword);
    
    if (temp[value]) {
      temp[value] = false;
    } else {
      temp[value] = true;
    }
    setShowPassword(temp);
  }

  return (
    <div class="form-row-page-pass-change">
      <div className="card-div">
    <div className="user-change-password-page">
      <div className="row">
         
        <div className="col-12">
          <div
            // style={{height: '70vh'}}
            className="accorDetails personalInfo"
          >
            {/* <p className="accordian-title">Change Password </p> */}
            <div className="accordian-data">
              <div className="col-12">
                <div
                  // style={{ backgroundColor: "#F9F9F9" }}
                  className="accorDetails personalInfo"
                >
                  {/* <p className="accordian-title">Old Password</p> */}
                  {/* <div class="col-md-12 mb-3"> */}
                  <TextField
                      size="small"
                      name="Old Password"
                      type={showPassword?.old ? "text" : "password"}
                      // label="First Name"
                      label="Old Password"
                      id="outlined-basic"
                      onChange={(event) =>
                          handleOldPassword(event)
                      }
                      variant="outlined"
                      error={formError.oldPassword.isError}
                      // helperText={formError.oldPassword.errorMessage}
                      value={oldPassword ? oldPassword : ""}
                      sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                          border: "1px solid #dedede",
                          },
                          // "& .MuiInputBase-input:focus": {
                          //   border: "2px solid #fcd25e", // focus
                          // },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                          }
                      }}
                  />
                  <span class="eyeIcon" onClick={() => handleShowPasword("old")}>
              {
                showPassword?.old ?
                  <FaEye />
                  :
                  <FaEyeSlash />
              }               
            </span>
                </div>
                {
                  formError.oldPassword.isError && 
                  <FormHelperText className='Mui-error' sx={{marginTop:"-4px",marginBottom:"9px"}}>
                        {
                          formError.oldPassword.errorMessage
                        }
                </FormHelperText>
                }
              </div>
              <div className="col-12">
                <div
                  // style={{ backgroundColor: "#F9F9F9" }}
                  className="accorDetails personalInfo"
                >

                  <TextField
                      size="small"
                      name="New Password"
                      type={showPassword?.new ? "text" : "password"}
                      // label="First Name"
                      label="New Password"
                      id="outlined-basic"
                      onChange={(event) =>
                          handleNewPassword(event)
                      }
                      variant="outlined"
                      error={formError.newPassword.isError}
                      // helperText={formError.newPassword.errorMessage}
                      value={newPassword ? newPassword : ""}
                      sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                          border: "1px solid #dedede",
                          },
                          // "& .MuiInputBase-input:focus": {
                          //   border: "2px solid #fcd25e", // focus
                          // },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                          }
                      }}
                  />      
            <span class="eyeIcon" onClick={() => handleShowPasword("new")}>
              {
                showPassword?.new ?
                  <FaEye />
                  :
                  <FaEyeSlash />
              }               
            </span>
                </div>
                {
                  formError.newPassword.isError && 
                  <FormHelperText className='Mui-error' sx={{marginTop:"-4px",marginBottom:"9px"}}>
                        {
                          formError.newPassword.errorMessage
                        }
                </FormHelperText>
                }
              </div>
              <div className="col-12">
                <div
                  // style={{ backgroundColor: "#F9F9F9" }}
                  className="accorDetails personalInfo"
                >

                  <TextField
                      size="small"
                      name="Retype New Password"
                      type={showPassword?.reWrite ? "text" : "password"}
                      // label="First Name"
                      label="Retype New Password"
                      id="outlined-basic"
                      onChange={(event) =>
                          handleRetypeNewPassword(event)
                      }
                      variant="outlined"
                      error={formError.retypeNewPassword.isError}
                      // helperText={formError.retypeNewPassword.errorMessage}
                      value={retypeNewPassword ? retypeNewPassword : ""}
                      sx={{
                          my: 2,
                          width: "100%",
                          //backgroundColor: "#dedede",
                          "& fieldset": {
                          border: "1px solid #dedede",
                          },
                          // "& .MuiInputBase-input:focus": {
                          //   border: "2px solid #fcd25e", // focus
                          // },
                          "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                          }
                      }}
                  />      
                  <span class="eyeIcon" onClick={() => handleShowPasword("reWrite")}>
              {
                showPassword?.reWrite ?
                  <FaEye />
                  :
                  <FaEyeSlash />
              }               
            </span>

                </div>
                {
                  formError.retypeNewPassword.isError && 
                  <FormHelperText className='Mui-error' sx={{marginTop:"-4px",marginBottom:"9px"}}>
                        {
                          formError.retypeNewPassword.errorMessage
                        }
                </FormHelperText>
                }
                
              </div>
            </div>
            <div className="d-flex justify-content-center pt-2 main-btn">
              {/* <div className="valuesBtn draft">Auto Save 30 sec</div> */}
              <Button
                className="valuesBtn next"
                disabled={submitLoader}
                onClick={() => getValidate()}
              >
                  {
                      submitLoader ? 
                      <CircularProgress size={29} sx={{color:"#ffffff"}} />
                      :
                      "Change Password"
                  }
                
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
};

export default ChangePassword;
