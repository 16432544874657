import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import PaginationMedi from "../../common/pagination";
import { getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../utils/utils";
import TableSkel from "../../common/tableSkeleton";
import { CircularProgress, IconButton, Popover, Backdrop } from "@mui/material";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useEffect, useState } from "react";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import filterIcon from "../../../assets/images/filter.svg";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import SideDrawer from "../../common/sideDrawer/index.js";
import DialogPopup from "../../common/Dialog/index.js";
import { SlClose } from "react-icons/sl";

const FunctionalLeader = () => {

  const history = useHistory();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const annualcycleData = useSelector((state) => state.annualcycleReducer.annualcycle[0]);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [data, setData] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [approveLoader, setApproveLoader] = useState(false);
    const [count, setCount] = useState(0);
    const [approvedCount, setApprovedCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedReport, setSelectedReport] = useState(null);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("pending");
    const [approvedDataLoader, setapprovedDataLoader] = useState(false);
    const [approvedData, setapprovedData] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [alreadyApprovedEmps, setAlreadyApprovedEmps] = useState([]);
    const [newerRatedEmps, setNewerRatedEmps] = useState([]);
    const [elligibleForApproval, setElligibleForApproval] = useState([]);
    const [checkApproveLoader, setCheckApproveLoader] = useState(false);

//   console.log("selectedSessions",selectedSessions)
    
    const handleClickAction = (event, report) => {
        setSelectedReport(report);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {

        if(!isEmptyNullUndefined(annualcycleData?.calibrationCycle)){
            // getData();
            getCount();
            if(activeTab === "Approved"){
                getApprovedData()
            } else {
                getData();
            } 
        }
        
    }, [annualcycleData, activeTab])

    useEffect(() => {

        if(!isEmptyNullUndefined(data)) {
            getData();
        }       
        
    }, [currentPage])
    
    const getData = () => {
        setDataLoader(true);
        APIList.getSessionApprovalList({
              searchValue : "",
              entityId: annualcycleData?.calibrationCycle?.id , // calibrationCycleId
            //   entityName: annualcycleData?.calibrationCycle?.cycleName ,
              entityName: "calibration_cycle" ,
              employeeId: employeeDetails?.id ,
              status: "pending",
              pageNo: currentPage ,
              pageSize: 10
        })
        .then((res) => {
            // console.log("res---------->",res)
          setData(res?.data?.data);          
          setTotalPages(res?.data?.totalPages);
          setDataLoader(false);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.message}
              </div>
          );
          setDataLoader(false);
        })
    }

    const getApprovedData = () => {
        setapprovedDataLoader(true);
        APIList.getSessionApprovalList({
              searchValue : "",
              entityId: annualcycleData?.calibrationCycle?.id , // calibrationCycleId
            //   entityName: annualcycleData?.calibrationCycle?.cycleName ,
              entityName: "calibration_cycle" ,
              employeeId: employeeDetails?.id ,
              status: "Approved",
              pageNo: currentPage,
              pageSize: 10
        })
        .then((res) => {
            // console.log("res---------->",res)
          setapprovedData(res?.data?.data);          
          setTotalPages(res?.data?.totalPages);
          setapprovedDataLoader(false);
        })
        .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.message}
              </div>
          );
          setapprovedDataLoader(false);
        })
    }

    const getCount = () => {
        APIList.getSessionApprovalListCount({
            employeeId: employeeDetails?.id ,
            entityId: annualcycleData?.calibrationCycle?.id ,
            entityName: "calibration_cycle" ,
        })
        .then((res) => {
            setCount(res?.data?.pendingCount);
            setApprovedCount(res?.data?.approvedCount);
        }).catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
              )                 
          })
    }

    const handleSelectSession = (report) => {

        let temp = structuredClone(selectedSessions);

        if(temp?.includes(report?.requestItemId)) {
            temp = temp?.filter((x) => x !== report?.requestItemId);
        } else {
            temp?.push(report?.requestItemId);
        }

        setSelectedSessions(temp);

    }

    const handleSelectAll = () => {
        if(isAllAdded()) {
            setSelectedSessions([]);
        } else {            
            const allIds = data?.map( x => x.requestItemId);
            setSelectedSessions(allIds);
        }        
    }

    const isAllAdded = () => {
        const allIds = data?.map( x => x.requestItemId);
        const AllSelectedIds = selectedSessions;

        const allEntriesPresent = allIds.every(item => AllSelectedIds.includes(item));

        return allEntriesPresent;
    } 

    // console.log("resres",alreadyApprovedEmps, newerRatedEmps)

    const checkApprove = (selected, type) => {
        setCheckApproveLoader(true);
        APIList.checkBeforeApprove(
            {
                sessionId : selected?.id,
                companyId : employeeDetails?.company?.id,
            }
        )
        .then((res) => {
            setCheckApproveLoader(false);
            handleClose()
            setOpenDialog(true);
            setAlreadyApprovedEmps(res?.data?.employeesWithApprovedRatings);
            setNewerRatedEmps(res?.data?.employeesWithNewerRatings);
            setElligibleForApproval(res?.data?.eligibleEmployeesForApproval);
           
         }).catch((err) => {
           toast.error(
               <div style={{display:"flex", flexDirection:"row"}}>
                 <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                 &nbsp;&nbsp;{err?.title}
               </div>
             )                 
             setCheckApproveLoader(false);
         })
    }

    const handleApprove = (sess, aType) => {
        setApproveLoader(true);
        if(aType === "single") {
            APIList.approveSession([
                {
                    employeeId : employeeDetails?.id,
                    isApproved : true,
                    requestItemId : sess?.requestItemId
                }
            ])
            .then((res) => {
                toast.success(
                    <div className="flex flex-row">
                      <BsFillCheckCircleFill
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      {` Approved successfully`}
                    </div>
                  );
                getData();
                getCount();
                setApproveLoader(false);
                handleClose();
                setOpenDialog(false);
             }).catch((err) => {
               toast.error(
                   <div style={{display:"flex", flexDirection:"row"}}>
                     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                     &nbsp;&nbsp;{err?.title}
                   </div>
                 )                 
                setApproveLoader(false);
             })
            //  console.log({
            //     employeeId : employeeDetails?.id,
            //     isApproved : true,
            //     requestItemId : sess?.requestItemId
            // });
        } else {
            let payload = [];
            selectedSessions?.forEach((x) => {
                payload.push({
                    employeeId : employeeDetails?.id,
                    isApproved : true,
                    requestItemId : x
                })
            });
            APIList.approveSession(payload)
            .then((res) => {
                toast.success(
                    <div className="flex flex-row">
                      <BsFillCheckCircleFill
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      {` Approved successfully`}
                    </div>
                  );
                getData();
                getCount();
                setApproveLoader(false);
                handleClose();
             }).catch((err) => {
               toast.error(
                   <div style={{display:"flex", flexDirection:"row"}}>
                     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                     &nbsp;&nbsp;{err?.title}
                   </div>
                 )                 
                setApproveLoader(false);
             })
            // console.log(payload);
        }

    }

    const handleDeleteSession = () => {
        setDeleteLoader(true);
        APIList.deleteApproveRequest({sessionId : selectedReport?.id})
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  {` Deleted successfully`}
                </div>
              );
            getData();
            getCount();
            setDeleteLoader(false);
            handleClose();
         }).catch((err) => {
           toast.error(
               <div style={{display:"flex", flexDirection:"row"}}>
                 <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                 &nbsp;&nbsp;{err?.title}
               </div>
             )                 
             setDeleteLoader(false);
         })
    }

    const handleViewReport = (reqId, isApproved) => {
      // history?.push({pathname:"/functional-leader-view-report", state: {sessionid: reqId}});
      history?.push({pathname:"/functional-leader-view-report-with-blocker", state: {sessionid: reqId, isApproved:isApproved}});
    }

    return(
        <div className="functional-leader">
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={checkApproveLoader}
          >
            <CircularProgress color="inherit" />
        </Backdrop>

<div className="search-wrapper">
            <div className="r-f-tabs">
                    <div className={`tab ${activeTab === "pending" ? "active" : ""} `} 
                    onClick={() => setActiveTab("pending")}
                    >
                        Last session report 
                        <span>{(count > 0 && count < 10 )? `0${count}` : count}</span>
                    </div>
                    <div className={`tab ${activeTab === "Approved" ? "active" : ""} `} 
                    onClick={() => setActiveTab("Approved")}
                    >
                        Approved
                        <span>{(approvedCount > 0 && approvedCount < 10 )? `0${approvedCount}` : approvedCount}</span>
                    </div>
          </div>

            <div className="second-div">

                {/* <div className="view-reports">
                  View all reports
                </div> */}
                {/* {
                    approveLoader ? 
                    <button className="approve-all-btn">
                    <CircularProgress size={27} />
                    </button>
                  :
                  activeTab === "pending" &&
                  <button className="approve-all-btn" onClick={() => handleApprove(null, "multiple")} disabled={selectedSessions?.length < 1}>
                    Approve
                  </button>
                } */}
          
          {/* <div className="filter-tab" 
          onClick={() => setIsOpenFilter(true)}
          > 
            <img src={filterIcon} alt="" />
            Filter
            {
                isFilterOn && 
                <div className="red-dot"></div>
            }
          </div> */}
          <div className="page-i-btn" 
          onClick={() => setIsOpenDrawerForIbtn(true)}
          >
            i
          </div>
          </div>
          </div>

            <div className="functional-leader-main">

              {
                activeTab === "pending" ? 
<div className="tablee">
                <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th className="emp-name" 
                // onClick={handleSelectAll}
                > 
                {/* {
                    isAllAdded() ?
                    <MdOutlineCheckBox />
                    :
                    <MdOutlineCheckBoxOutlineBlank />
                } */}
                    <div className="sessionName">
                      Session Name
                    </div>
                </th> 
                <th className="mid-col">HRBP</th> 
                <th className="mid-col">Total Panelist</th> 
                <th className="last-col">Cohort Count</th> 
                <th className="last-col">Schedule</th> 
                <th className="last-col">Status</th> 
                <th className="last-col">Action</th> 
                </tr> 
                {
                    dataLoader ? 
                    <TableSkel />
                    :
                    !isEmptyNullUndefined(data) ? data?.map((report,index) => {
                     
                        return(                               
                                <tr> 
                                <td>
                                    <div className="title" 
                                    // onClick={() => handleSelectSession(report)}
                                    >
                                        {/* {
                                            
                                           selectedSessions?.includes(report?.requestItemId) ?
                                           
                                            <MdOutlineCheckBox />
                                            :
                                            <MdOutlineCheckBoxOutlineBlank />
                                        } */}
                                        <div className="empInfo">
                                            <div className="name">{report?.name}</div>
                                            <div className="sub">Code</div>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                    {report?.organiser?.name}                                    
                                </td> 
                                <td>
                                    {report?.participantCount}                                    
                                </td> 
                                <td>
                                    {report?.coHortCount}                                    
                                </td> 
                                <td>
                                <div>{getFormattedDate(report?.startTime, dateFormat)}</div>
                                    <div>{getFormattedTime(report?.startTime, 
                                        // is12HourFormat ? "hh:mm A" : "HH:mm"
                                        "hh:mm A"
                                        )}</div>                                 
                                </td> 
                                <td>
                                    {report?.status}                                    
                                </td>                                 

                                <td>
                                <div className="btns">
                                    <div className="send-btn" onClick={() => handleViewReport(report?.id, false)}>
                                        View Report
                                    </div>
                                    
                                    <div className="action">
                                    {
                                      <IconButton variant="outlined" 
                                            onClick={(event) => handleClickAction(event, report)}
                                            >
                                            {/* Provide Feedback */}
                                            <BiDotsVerticalRounded /> 
                                            </IconButton>
                                    }
                                            
                                            <Popover
                                                id={id} 
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                }}
                                            >
                                                {
                                                    approveLoader ? 
                                                <div className="popover-btns"><CircularProgress size={21} /></div>
                                                :
                                                <div className="popover-btns" 
                                                onClick={() => checkApprove(selectedReport, "single")}
                                                >Approve</div>
                                                }
                                                {/* {
                                                    deleteLoader ? 
                                                <div className="popover-btns"><CircularProgress size={21} /></div>
                                                :
                                                <div className="popover-btns red" 
                                                onClick={() => handleDeleteSession()}
                                                >Delete</div>
                                                } */}
                                                
                                                
                                            </Popover>
                                        </div>
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                    
                )
                :
                <div className="no-result">
                  No result found
                </div>
                }

                </table>
                
                </div>
                :
                <div className="tablee">
                <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th className="emp-name"> 
              
                    <div className="sessionName">
                      Session Name
                    </div>
                </th> 
                <th className="mid-col">HRBP</th> 
                <th className="mid-col">Total Panelist</th> 
                <th className="last-col">Cohort Count</th> 
                <th className="last-col">Schedule</th> 
                <th className="last-col">Status</th> 
                <th className="last-col">Action</th> 
                </tr> 
                {
                    approveLoader ? 
                    <TableSkel />
                    :
                    !isEmptyNullUndefined(approvedData) ? approvedData?.map((report,index) => {
                        return(                               
                                <tr> 
                                <td>
                                    <div className="title" 
                                    onClick={() => handleSelectSession(report)}
                                    >
                                        
                                        <div className="empInfo">
                                            <div className="name">{report?.name}</div>
                                            <div className="sub">Code</div>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                    {report?.organiser?.name}                                    
                                </td> 
                                <td>
                                    {report?.participantCount}                                    
                                </td> 
                                <td>
                                    {report?.coHortCount}                                    
                                </td> 
                                <td>
                                <div>{getFormattedDate(report?.startTime, dateFormat)}</div>
                                    <div>{getFormattedTime(report?.startTime, 
                                        // is12HourFormat ? "hh:mm A" : "HH:mm"
                                        "hh:mm A"
                                        )}</div>                                 
                                </td> 
                                <td>
                                    {report?.status}                                    
                                </td>                                 

                                <td>
                                <div className="btns">
                                    <div className="send-btn" onClick={() => handleViewReport(report?.id, true)}>
                                        View Report
                                    </div>
                                    
                                    <div className="action">
                                 
                                        </div>
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                    
                )
                :
                <div className="no-result">
                  No result found
                </div>
                }

                </table>
                
                </div>
              }
           
                
                {
                    !isEmptyNullUndefined(data) && 
                    (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }
            </div>
            
            {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                {/* <div className="i-btn-cintent">
                    Please provide content
                </div> */}
                <InfoContent pageName='functional-leader'/>
            </SideDrawer>
         } 

        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/functional-leader">
                
                <div className="check-approved-popup-main">
                <SlClose onClick={() => setOpenDialog(false)} />

                {
                   (isEmptyNullUndefined(alreadyApprovedEmps) && isEmptyNullUndefined(newerRatedEmps)) ?
                   <div className="messsage">
                        Are you sure you want to approve? Once approved you will not able to edit or make any further changes.
                    </div>
                  :
                    <div className="messsage">
                        The following employee's rating will not be approved as they are already approved or present in another session.
                    </div>
                }
                {
                    !isEmptyNullUndefined(alreadyApprovedEmps) &&
                    <>
                    <div className="table-heading">Already Approved - </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                alreadyApprovedEmps?.map((emp) => {
                                    return(
                                    <tr>
                                        <td>{emp?.name}</td>
                                    </tr> 
                                )
                            })
                            }
                            
                        </tbody>
                    </table>
                    </>
                }
                {
                    !isEmptyNullUndefined(newerRatedEmps) &&
                    <>
                    <div className="table-heading">Present in another session - </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                newerRatedEmps?.map((emp) => {
                                    return(
                                    <tr>
                                        <td>{emp?.name}</td>
                                    </tr> 
                                )
                            })
                            }
                            
                        </tbody>
                    </table>
                    </>
                }
                
                {
                    !isEmptyNullUndefined(elligibleForApproval) && 
                    <div className="btn-outer">
                        <button className="approve-btn" onClick={() => handleApprove(selectedReport, "single")} disabled={approveLoader}>
                            {
                                approveLoader ? 
                                <CircularProgress size={27} />
                                :
                                "Approve"
                            }
                            
                        </button>
                    </div>
                }
                    
                </div>
            </DialogPopup>
        }
        </div>
    )
} 

export default FunctionalLeader;
