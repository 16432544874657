import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";


const RatingTable = ({ data }) => {

  const groupedData = data.reduce((acc, currentValue) => {
    const { cycleName, ratingName, trendingRating } = currentValue;
    if (!acc[cycleName]) {
      acc[cycleName] = { cycleName, MBB: '', OKR: '' };
    }
    if (ratingName === 'MBB') {
      acc[cycleName].MBB = trendingRating;
    } else if (ratingName === 'OKR') {
      acc[cycleName].OKR = trendingRating;
    }
    return acc;
  }, {});

  return (
    <div className="accordian-data rating-table">
    <TableContainer component={Paper} className='rating-table' >
      <Table border={0}>
        <TableHead className="table-header">
          <TableRow style={{ background: '#f0f0f0' }}>
            <TableCell className="table-header" align='center'>Cycle Name</TableCell>
            <TableCell className="table-header" align='center' >MBB</TableCell>
            <TableCell className="table-header" align='center'>OKR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(groupedData).map((row, index) => (
            <TableRow key={index} style={{borderBottom: 'none' }} align='center'>
              <TableCell align='center' >{row.cycleName}</TableCell>
              <TableCell align='center'>{row.MBB}</TableCell>
              <TableCell align='center'>{row.OKR}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </div>
  );
};

export default RatingTable;


// import React from 'react';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from '@mui/material';
// import { green, purple } from '@mui/material/colors';

// const RatingTable = ({ data }) => {
//   // Group data by cycleId
//   // const groupedData = data?.reduce((acc, item) => {
//   //   const key = item.cycleId;
//   //   if (!acc[key]) {
//   //     acc[key] = [];
//   //   }
//   //   acc[key].push(item);
//   //   return acc;
//   // }, {});

//   const groupedDataa = data?.reduce((acc, item) => {
//     const key = item.cycleName;
//     if (!acc[key]) {
//       acc[key] = [];
//     }
//     acc[key].push(item);
//     return acc;
//   }, {});

//   const cycleTypes = [...new Set(data?.map(item => item.cycleType))];
//   const ratingNames = [...new Set(data?.map(item => item.ratingName))];

//   return (
//     <div className='accordian-data rating-table'>

//       <TableContainer
//         component={Paper}
//         style={{ maxHeight: 400, overflow: 'auto', maxWidth: 800, overflowX: 'auto' }}
//       >
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell
//                 style={{ border: '1px solid #ddd', backgroundColor: '#f2f2f2', minWidth: '150px' }}
//                 rowSpan={2}
//                 align='center'
//               >
//                 <p className="table-title">Cycle Name</p>

//               </TableCell>
//               {cycleTypes?.map((cycleType, index) => (
//                 <TableCell
//                   key={cycleType}
//                   colSpan={ratingNames.length}
//                   align='center'
//                   style={{
//                     border: '1px solid #ddd',
//                     backgroundColor: index % 2 === 0 ? green[100] : purple[100],
//                   }}
//                 >
//                   <p className="table-title">{cycleType}</p>

//                 </TableCell>
//               ))}
//             </TableRow>
//             <TableRow>
//               {cycleTypes?.map((cycleType) =>
//                 ratingNames?.map((ratingName) => (
//                   <TableCell
//                     key={`${cycleType}-${ratingName}`}
//                     style={{ border: '1px solid #ddd' }}
//                   >
//                     <p className="table-title">{ratingName}</p>
//                   </TableCell>
//                 )),
//               )}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {/* {groupedData  && Object.keys(groupedData).map((cycleId) => (
//               <TableRow key={cycleId}>
//                 <TableCell style={{ border: '1px solid #ddd' }}><p className="subTitle">{cycleId}</p></TableCell>
//                 {cycleTypes?.map((cycleType) =>
//                   ratingNames?.map((ratingName) => (
//                     <TableCell
//                       key={`${cycleType}-${ratingName}`}
//                       style={{ border: '1px solid #ddd' }}
//                     >
//                       <p className="subTitle">
//                         {groupedData[cycleId].find(
//                           (item) => item.cycleType === cycleType && item.ratingName === ratingName
//                         )?.trendingRating || ''}
//                       </p>
//                     </TableCell>
//                   )),
//                 )}
//               </TableRow>
//             ))} */}
//             {groupedDataa  && Object.keys(groupedDataa).map((cycleName) => (
//               <TableRow key={cycleName}>
//                 <TableCell style={{ border: '1px solid #ddd' }}><p className="subTitle">{cycleName}</p></TableCell>
//                 {cycleTypes?.map((cycleType) =>
//                   ratingNames?.map((ratingName) => (
//                     <TableCell
//                       key={`${cycleType}-${ratingName}`}
//                       style={{ border: '1px solid #ddd' }}
//                     >
//                       <p className="subTitle">
//                         {groupedDataa[cycleName].find(
//                           (item) => item.cycleType === cycleType && item.ratingName === ratingName
//                         )?.trendingRating || ''}
//                       </p>
//                     </TableCell>
//                   )),
//                 )}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//     </div>
//   );
// };

// export default RatingTable;

