import Avatar from "react-avatar";
import APIList from "../../../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, InputBase, Skeleton, Stack, styled } from "@mui/material";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import { Search, accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";
import TimePassedDisplay from "../timeShow";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import EmpSkel from "./skel";
import moment from "moment";

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
    color:"var(--primary)",
    fontSize: "1rem"
  }));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.7rem !important",
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '100%',
      // minWidth:"5rem"
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  }));  

const PerrFeedbackDrawer = ({member}) => {
    const currentDate = moment().startOf('day');

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);

    const [feedbackData, setFeedbackData] = useState(null);
    const [feedbackDataLoader, setFeedbackDataLoader] = useState(false);
    const [allStatuses, setAllStatuses] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [totalSearchPages, setTotalSearchPages] = useState(0);
    const [searchedList, setsearchedList] = useState(null);
    const [showAllSearches, setShowAllSearches] = useState(false);
    const [sendToEmp, setSendToEmp] = useState(null);
    const [initiateLoader, setInitiateLoader] = useState(false);
    const [initiateEmpNotSelectedErr, setInitiateEmpNotSelectedErr] = useState(false);

    const [status, setStatus] = useState('ACTIVE')

console.log("groupedData",groupedData);

function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target)
        ) {
           
                setSearch("");
                           
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);


    useEffect(() => {
        isActive()
        getFeedbacks();
        console.log('member', member)
    }, [member])
    
    useEffect(() => {
        let arr = [];

        allStatuses?.forEach((status) => {
            let oob = {
                [status]: feedbackData?.filter((x) => x?.status === status)
            }
            arr.push(oob);
        });

        setGroupedData(arr);
    }, [allStatuses])    

    useEffect(() => {
        const getSearch = setTimeout(() => {
            setSearchLoader(true);

            if (!isEmptyNullUndefined(search)) {
                    APIList.getFilteredSearchPeers({
                        // payload: {
                        //     employeeEligibilityDTO: {},
                        //     keyword: search,
                        //     employeeId: employeeDetails?.id,
                        //     managerId: employeeDetails?.manager?.id,
                        //     companyId: employeeDetails?.company?.id,
                        //     page : "peer-feedback"
                        // },
                        payload: {
                            employeeEligibilityDTO: {},
                            keyword: search,
                            employeeId: member?.employeeId,
                            managerId: employeeDetails?.id,
                            companyId: employeeDetails?.company?.id,
                            page : "peer-feedback"
                        },
                        page: 0,
                        size: showAllSearches ? 10000 : 3
                    })
                        .then((res) => {
                            setsearchedList(res?.data?.data);
                            setTotalSearchPages(res?.data?.totalPages);
                            setSearchLoader(false);
                        })
                        .catch((err) => {
                            setSearchLoader(false);
                        }
                        )
                
            }

        }, 500);    

        return () => clearTimeout(getSearch)
      }, [search, showAllSearches])

    const getFeedbacks = () => {
        setFeedbackDataLoader(true);
        APIList.getAllFeedbackFoeEmployee({empId: member?.employeeId})
        .then((res) => {
            setFeedbackData(res?.data);
            setAllStatuses(res?.data?.map((x) => x?.status).filter(
                (v, i, a) => a.findIndex((t) => t === v) === i,
              ).sort((a, b) => a.localeCompare(b)));
            setFeedbackDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setFeedbackDataLoader(false);
        })
    }

    const autoSearchText = (e) => {
        setSearch(e.target.value);
        setInitiateEmpNotSelectedErr(false);
      };

      const handleSendRequest = (emp) => {
        setSendToEmp(emp);
      };

      const handleInitiateRequest = () => {

        if(isEmptyNullUndefined(sendToEmp)){
            setInitiateEmpNotSelectedErr(true);
        } else {
            setInitiateLoader(true);
            APIList.initiateByManager({
                requestedBy: {
                    id: member?.employeeId
                    // dfeav.qacai@yopmail.com
                },
                requestedTo: {
                    id: sendToEmp?.employeeId
                    // emp1.1@yopmail.com
                },
                relationship: "Peer",
                
                managerInitiatesPeerFeedback:true,
                initiateManager:{id:employeeDetails?.id}
            })
            .then((re) => {
                toast.success(
                    <div className="flex flex-row">
                      <BsFillCheckCircleFill
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      &nbsp;Request sent successfully
                    </div>
                  );
                setSearch("");
                setSendToEmp(null);
                setInitiateLoader(false);
                getFeedbacks();
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setInitiateLoader(false);
            })
        }
        
      }

      const isActive = () => {
        if (currentDate.isBefore(annualcycleData.pmsCycle.peerAppraisalEndDate)) {
            console.log("Current date is before self appraisal end date");
            setStatus('ACTIVE')
            return false
          } else if (currentDate.isAfter(annualcycleData.pmsCycle.peerAppraisalEndDate)) {
            console.log("Current date is after self appraisal end date");
        
            setStatus('INACTIVE')
            return true

            
          } else {
            console.log("Current date is equal to self appraisal end date");
            setStatus('ACTIVE')
            return false
          }
      }

    return(
        <div className="my-team-drawer-feedback">
            <div className="empDetaila">
                <div className="empPic">
                {member?.profilePhotoPath ? 
                    <img src={member?.profilePhotoPath} className="userImg" alt="User Image" />
                    :
                    <Avatar 
                        name={member?.employeeName} 
                        size="45" 
                        className="userImg"
                        color={"#00425A"}    
                    />
                }
                </div>
                <div className="userInfo">
                    <p className="name">{member?.employeeName}</p>
                    {/* <p className="designation">{member?.employeeDesignation ? member?.employeeDesignation : "Designation"}</p> */}
                    <p className="designation">{member?.employeeDesignation ? member?.employeeDesignation : ""}</p>
                </div>
            </div> 

            <div className="initiate-request">
                {
                    !isEmptyNullUndefined(sendToEmp) ? 
                    <div className="selected-to-send">
                         <div className="empDetaila">
                            <div className="empPic">
                            {sendToEmp?.profilePhotoPath ? 
                                <img src={sendToEmp?.profilePhotoPath} className="userImg" alt="User Image" />
                                :
                                <Avatar 
                                    name={sendToEmp?.employeeName} 
                                    size="45" 
                                    className="userImg"
                                    color={"#00425A"}    
                                />
                            }
                            </div>
                            <div className="userInfo">
                                <p className="name">{sendToEmp?.employeeName}</p>
                                {/* <p className="designation">{sendToEmp?.employeeDesignation ? sendToEmp?.employeeDesignation : "Designation"}</p> */}
                                <p className="designation">{sendToEmp?.employeeDesignation ? sendToEmp?.employeeDesignation : ""}</p>
                                <p className="designation">{sendToEmp?.emailId}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                    {
                        (status == 'ACTIVE') && (
                            <div className="search-title">Send Request For Feedback</div>
                        )
                    }
                    {
                        (status == 'ACTIVE') && (
                                        <Search  
                                            ref={wrapperRef}
                                        >
                                            <SearchIconWrapper>
                                            <IoMdSearch />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                            placeholder="Search by username or email"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={autoSearchText}
                                            value={search}
                                            />
                                            {
                                                !isEmptyNullUndefined(search) && 
                                                <IoCloseSharp onClick={() => {setSearch(""); setShowAllSearches(false)}} style={{position:"absolute", right:"10px", top:"34%", color:"gray", cursor:"pointer"}} />
                                            }

                                            {
                                                !isEmptyNullUndefined(search) && 
                                                <div className="search-popup-req-feed">
                                                {
                                                    searchLoader ? 
                                                    <>                   
                                                    <div className="search-emp-outer">
                                                        <div className="empDetaila">
                                                            <div className="empPic">
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                            </div>
                                                            <div className="userInfo">
                                                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                                                <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                                                            </div>
                                                        </div>
                                                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                                                    </div>
                                                    <div className="search-emp-outer">
                                                        <div className="empDetaila">
                                                            <div className="empPic">
                                                            <Skeleton variant="circular" width={40} height={40} />
                                                            </div>
                                                            <div className="userInfo">
                                                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                                                <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                                                            </div>
                                                        </div>
                                                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                                                    </div>
                                                    </>
                                                    :
                                                    searchedList?.length > 0 ?
                                                    <div>
                                                { 
                                                searchedList?.map((emp, index) => {
                                                        return(
                                                            
                                                            <div key={index} className="search-emp-outer">
                                                                <div className="empDetaila">
                                                                    <div className="empPic">
                                                                        {emp?.profilePhotoPath ?
                                                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                                            :
                                                                            <Avatar
                                                                                name={emp?.employeeName}
                                                                                size="40"
                                                                                className="userImg"
                                                                                color={"#00425A"}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="userInfo">
                                                                        <p className="name">{emp?.employeeName}</p>
                                                                        <p className="designation">{emp?.emailId ? emp?.emailId : ""}</p>
                                                                        {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                                                                        <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : ""}</p>
                                                                    </div>
                                                                </div>
                                                                <button className="search-sednd-btn" onClick={() => handleSendRequest(emp)}>
                                                                    Select
                                                                </button>
                                                            </div>
                                                        )                        
                                                    })
                                                    }
                                                    {
                                                        !showAllSearches && (totalSearchPages > 1) && <div className="view-all-search" onClick={() => setShowAllSearches(true)}>View all</div>
                                                    }                    
                                                    </div>
                                                    :
                                                    <div className="no-result">No result</div>
                                                }
                                                </div>
                                            }
                                            
                                        </Search>
                        )
                    }
        
          </>
                }

                {
                    initiateEmpNotSelectedErr && 
                    <div style={{fontSize:"0.75rem", color:"red", marginBottom:"1rem"}}>*Please select the employee</div>
                }
                
                {(status == 'ACTIVE') && <button 
                className="send-re-btn" 
                onClick={handleInitiateRequest}
                disabled={initiateLoader}
                >
                    {
                        initiateLoader ? 
                        <CircularProgress 
                        size={25}
                        style={{margin:"0px 4rem", color:"#ffffff"}} />
                        :
                        "Send Request"
                    }
                    
                </button>}
            
            </div>

            <div className="feedback-status-main">
                {
                    feedbackDataLoader ? 
                    <>
                        <EmpSkel />
                        <EmpSkel />
                        <EmpSkel />
                        <EmpSkel />
                        <EmpSkel />
                    </>
                    :
                    groupedData?.map((data) => {
                        return(
                            <div className="block">
                                <div className="title">Feedback {Object.keys(data)[0] === "Completed" ? "Received" : Object.keys(data)[0]} <span className="count"> ({data[Object.keys(data)[0]]?.length})</span></div>
                                <div className="req-contents">
                                    {
                                        data[Object.keys(data)[0]]?.map((req) => {
                                            return(
                                                <div className="peer-req">
                                                    <div className="empDetaila">
                                                        <div className="empPic">
                                                        {req?.requestedTo?.profilePhoto ? 
                                                            <img src={req?.requestedTo?.profilePhoto} className="userImg" alt="User Image" />
                                                            :
                                                            <Avatar 
                                                                name={req?.requestedTo?.name} 
                                                                size="45" 
                                                                className="userImg"
                                                                color={"#00425A"}    
                                                            />
                                                        }
                                                        </div>
                                                        <div className="userInfo">
                                                            <p className="name">{req?.requestedTo?.name}</p>
                                                            {/* <p className="designation">{req?.requestedTo?.employeeDesignation ? req?.requestedTo?.employeeDesignation : "Designation"}</p> */}
                                                            <p className="designation">{req?.requestedTo?.employeeDesignation ? req?.requestedTo?.employeeDesignation : ""}</p>
                                                            <p className="designation">{req?.requestedTo?.employeeId ? `ID: ${req?.requestedTo?.employeeId}` : ""}</p>
                                                        </div>
                                                    </div>
                                                    <TimePassedDisplay startDate={req?.requestDate} />
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>                                
                            </div>
                        )
                    })
                }
            {/* <div className="acc-wrapper">
                        <Stack sx={{
                            margin:"1rem 0px",
                            "& .MuiAccordion-rounded":{
                                // border:"1px solid #dad2d2",
                                borderRadius:"10px !important",
                                boxShadow:"none"
                            }                           
                        }}>
                            <Accordion
                            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                            expanded={true} onChange={() => true} data-value={"stats"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                }}}
                            >
                                <p className="accordian-heading" ></p>
                                <BsChevronDown
                                    style={{...accordianSVGstyle ,transform: !true ? "rotate(0deg)" : "rotate(180deg)"}}
                                />          
                            </AccordionSummary>
                            <AccordionDetails
                            sx={{
                                fontSize:"1rem"
                            }}
                            >
                              

           
                                
                            </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </div> */}
            </div>
        </div>
    )
}

export default PerrFeedbackDrawer;