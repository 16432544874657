import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { allRoutes, managerRoutes, prodRoutes } from "./routes";
import Avatar from "react-avatar";
import { actions as logInActions } from "../../../redux/login/actions";
import { IoIosArrowBack } from "react-icons/io";
import Logout from "../../../assets/images/sidebar/logout";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";

const DynamicSidebar = ({ children }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    // const allRequestCounts = useSelector((state) => state?.countsReducer?.counts);
  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );

    const [routes, setRoutes] = useState(null);
    const [sidebarCollide, setSidebarCollide] = useState(false);
    const [aciveTab, setAciveTab] = useState("");
    const [activeHead, setActiveHead] = useState(null);

    let currentPath = window.location.pathname;

    useEffect(() => {
      if(aciveTab !== currentPath){
        setAciveTab(currentPath);
      }
    }, [currentPath])
    
    
    useEffect(() => {
      // if(employeeDetails?.isManager){
      //   if(employeeDetails?.company?.showAppraisal){
      //     setRoutes(managerRoutes);
      //   } else {
      //     setRoutes(managerRoutes);
      //   }
      // } else {
      //   if(employeeDetails?.company?.showAppraisal){
      //   setRoutes(allRoutes);
      // } else {
      //   setRoutes(prodRoutes);
      // }
      // }
      setRoutes(allRoutes);
    }, [employeeDetails]);

    const handleSidebarCollide = () => {
        setSidebarCollide(!sidebarCollide);
        // console.log('in')
        // console.log(sidebarCollide)
      }  
      
      const handleSidebarCollideTypeClassName = (element) => {
        // console.log('location', location.pathname)
        if((location.pathname === '/login') || (location.pathname === '/forgot-password') || (location.pathname === '/account/reset/finish')) {
          if(element === 'sidebar') {
            return "sidebar-login-page"
          } else {
            if(element === 'main') {
              return "main-login-page"
            }
          }
        } else {
          if(element === 'sidebar') {
            if(sidebarCollide) {
              return "sidebar-Collide"
            } else {
              return "sidebar"
            }
          } else if(element === 'main') {
            if(sidebarCollide) {
              return "main-Collide"
            } else {
              return "main"
            }
          }
        }
      }

      const handleNavigation = (route, isSubcat) => {
        
        setAciveTab(route?.path);

        if(!route?.subcategory) {

          history.push(route?.path);

          if(!isSubcat) {
            setActiveHead(null);
          } 
          
        } else {
          setSidebarCollide(false);
          if(activeHead === route?.path) {
            !sidebarCollide && setActiveHead(null);
          } else {
            setActiveHead(route?.path);
          }          
        }

      }

      const handleLogOut = () => {

        APIList.logout({token: userToken?.id_token})
        .then((res) => {
      })
      .catch((err) => {
          toast.error(
              <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
              </div>
          );
      })

        dispatch(logInActions.clearLogInToken());
          localStorage.setItem("loggedOutFromFooter",true);
          setTimeout(() => {
            history.push('/login');
          }, 1000);

          // window.location.reload();

      }
    return (
        <div className="main-container">
          <div className={handleSidebarCollideTypeClassName('sidebar')}>
            <div className="sidebar-main-outer">
            <div className="sidebarMain">
              <div className="headMiddle">
                <div className="header">
                      
                  <div className="user-container">
                    <div className="user-container-margin">
                      <div className="icon-container">
                        <div className="icon-size">
                          {employeeDetails?.profilePhoto ? 
                              <img src={employeeDetails?.profilePhoto?.path} className="user" alt="User Image" />
                              :
                              <Avatar 
                                  name={employeeDetails.name} 
                                  size="70" 
                                  className="user"
                                  color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                              />
                          }
                        </div>
                      </div>
                      <div className="title-container">
                        <div className="align-title">
                        <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0,2).join(' ')}</div>
                        <div className="lower-title">{employeeDetails?.employeeDesignation? employeeDetails?.employeeDesignation : "Employee"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle">
                <section className="routes">
                 {
                    routes?.map((route,index) => {
                      if (route?.name === "Home" && !employeeDetails?.permissions?.includes("home")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "My Profile" && !employeeDetails?.permissions?.includes("view-profile")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "My Assessment" && !employeeDetails?.permissions?.includes("self-assessment")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "My Team" && !employeeDetails?.permissions?.includes("my-team")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "Rating Calibration" && !employeeDetails?.permissions?.includes("rating-calibration")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "Manager Calibration" && !employeeDetails?.permissions?.includes("manager calibration")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "Functional Leader" && !employeeDetails?.permissions?.includes("functional-leader")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "Compensation" && !employeeDetails?.permissions?.includes("compenssation")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "EEP" && !employeeDetails?.permissions?.includes("EEP")) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                      if (route?.name === "Feedback" 
                            && !employeeDetails?.permissions?.includes("request-peer-feedback") 
                            && !employeeDetails?.permissions?.includes("give-peer-feedback") 
                            && !employeeDetails?.permissions?.includes("approve-page") 
                          ) {
                        // Exclude route-item when route.name is "xyz"
                        return null;
                      }
                        return(
                          
                            <div className="route-item">
                                <div className={`category ${(aciveTab === route?.path || activeHead === route?.path) ? "active" : ""}`} onClick={() => handleNavigation(route, false)}>
                                 <span> {route?.icon} </span>
                                 <span> 
                                  {route?.name}
                                   {
                                    route?.subcategory ? activeHead === route?.path ? <BsChevronUp /> : <BsChevronDown /> : ""
                                   }          
                                   </span>                          
                                </div>
                                {
                                    route?.subcategory && 
                                    activeHead === route?.path &&
                                    !sidebarCollide &&
                                    route?.subcategory?.map((sub) => {
                                      if (sub?.name === "Request Feedback" && !employeeDetails?.permissions?.includes("request-peer-feedback")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                      if (sub?.name === "Give Feedback" && !employeeDetails?.permissions?.includes("give-peer-feedback")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                      if (sub?.name === "Approve" && !employeeDetails?.permissions?.includes("approve-page")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                      if (sub?.name === "Analytics" && !employeeDetails?.permissions?.includes("compensation-analytics")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                      if (sub?.name === "My Team" && !employeeDetails?.permissions?.includes("compensation-my-team")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                      if (sub?.name === "Planning" && !employeeDetails?.permissions?.includes("compensation-planning")) {
                                        // Exclude route-item when route.name is "xyz"
                                        return null;
                                      }
                                        return(
                                            <div className={`sub-cat ${(aciveTab === sub?.path || window.location.pathname === sub?.path) ? "active" : ""}`} onClick={() => handleNavigation(sub, true)}>
                                              {sub?.name}
                                              {/* {sub?.name === "Give Feedback" ? `${sub?.name} (${allRequestCounts?.pendingRequestCount})` : sub?.name} */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                          
                            
                        )
                    })
                 }
                  </section>
                </div>
              </div>
              <div className="footer">
                {
                  // employeeDetails?.company?.showAppraisal && 
                  <div className="logout-size">
                  <div className="title">
                    Logout
                  </div>
                  <div 
                    onClick={() => handleLogOut()} className="logout-icon"
                    >
                    <Logout />
                  </div>
                </div>
                }
               
              </div>
              
            </div>
            <div className="for-collapse" onClick={() => handleSidebarCollide()}>
            <div className="collapsible-nav-bar">
                    <div className="collapsible-nav-size">
                      <IoIosArrowBack style={{transform: sidebarCollide ? "rotate(-180deg)" : "rotate(0deg)"}} />
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <main className={handleSidebarCollideTypeClassName('main')}>
            <div style={{
              height: '3.6rem'
            }} />
            {children}
          </main>
        </div>
      );
}

export default DynamicSidebar;