import { Button, FormHelperText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from 'react-toastify';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import APIList from '../../../../api';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { isEmptyNullUndefined } from '../../../utils/utils';

const ReSendReq = ({peer, setIsOpen, setSearch, getPageData, getSentCount, requestId}) => {  

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [dateExpected, setDateExpected] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [msgErr, setMsgErr] = useState(false);
    const [dateErr, setDateErr] = useState(false);
    const [pmsCycleData, setPmsCycleData] = useState(null);
    console.log('pmsCycleData', pmsCycleData)

    useEffect(() => {
      getAllActivepmsCycle(employeeDetails.company.id)
    }, [])

    let defaultMsg = `<p>
  <p>Dear ${peer?.requestedTo?.name},</p>

  <p> I hope this message finds you well.</p>

  <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

   Best regards,
   <p>${employeeDetails?.name}</p>
  </p>`;

  const [message, setMessage] = useState(defaultMsg);

    const handleChange = (value) => {
        setMessage(value);
        setMsgErr(false);
    }

  const getAllActivepmsCycle = async (companyId) => {
    // setIsLoading(() => true);
    try {
      const res = await APIList.getAllActivepmsCycle(companyId)
      // const res = await jwtAxios.get(
      //   // `${API_ROUTS.pmscycle}/get-all-by-company/${companyId}`,
      //   `${API_ROUTS.pmscycle}/company/${companyId}?status=ACTIVE`,
      //   // {cancelToken: source.token},
      // );
      if (res.status == 200) {
        if (res.data.length == 0) {
          // dispatch(showInfo('You have no pms cycle for selected company'));
          setPmsCycleData([]);
        } else {
          //*******Reversed original array***********/
          // const reversed = res.data.reverse();
          // setPmsCycleData(reversed);
          setPmsCycleData(res.data);
        }
        // setIsLoading(() => false);
      } else {
        setPmsCycleData([]);
        // setIsLoading(() => false);
      }
    } catch (error) {
      
      setPmsCycleData([]);
      // setIsLoading(() => false);
    }
  };
console.log("message",message)
    const submitRequest = () => {  
      
// console.log("getIsValid()",getIsValid())    
      if(getIsValid()){
        setSubmitLoader(true);
        APIList.sendFeedbackRequest({
            id: requestId,
            isResend: true,
            requestedBy:{ 
               id: employeeDetails?.id
            },
            requestedTo:{
                id: peer?.requestedTo?.id
            },
            relationship: "Peer",
            message: message,
            // expiryDate: dateExpected
        })  
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  {` Request sent successfully`}
                </div>
              );
              setSubmitLoader(false);
              setIsOpen(false);
              setSearch("");
              getPageData();
              getSentCount();
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSubmitLoader(false);
            setSearch("");
        })
      }                
    }

    const getIsValid = () => {
      let isValid = true;

      if(isEmptyNullUndefined(message)){
        setMsgErr(true);
        isValid = false;
      }
      // if(isEmptyNullUndefined(dateExpected)){
      //   setDateErr(true);
      //   isValid = false;
      // }

      return isValid;
    }
    
    return(
        <div className="send-feedback-req-drawer-main">
          <div className="empDetaila">
              <div className="empPic">
              {peer?.requestedTo?.profilePhoto?.path ? 
                  <img src={peer?.requestedTo?.profilePhoto?.path} className="userImg" alt="User Image" />
                  :
                  <Avatar 
                      name={peer?.requestedTo?.name} 
                      size="45" 
                      className="userImg"
                      color={"#00425A"}    
                  />
              }
              </div>
              <div className="userInfo">
                  <p className="name">{peer?.requestedTo?.name}</p>
                  {/* <p className="designation">{peer?.requestedTo?.employeeDesignation ? peer?.requestedTo?.employeeDesignation : "Designation"}</p> */}
                  <p className="designation">{peer?.requestedTo?.employeeDesignation ? peer?.requestedTo?.employeeDesignation : ""}</p>
              </div>
          </div>
            <SunEditor
            //  defaultValue={`<p>
            //  <p>Dear ${peer?.employeeName},</p>

            //  <p> I hope this message finds you well.</p>

            //  <p> As part of my performance appraisal process, I would greatly appreciate your valuable feedback.
            //   Your insights are crucial to my professional growth. Please take a moment to share your thoughts. Thank you for your time. </p>

            //   Best regards,
            //   <p>${employeeDetails?.name}</p>
            //  </p>`}
             defaultValue={message}
             autoFocus={true}
             height="20rem"
             setDefaultStyle="font-family: poppins; font-size: 14px;" 
             onChange={handleChange}
             />
                {msgErr && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    Please write message
                  </FormHelperText>
                )}
             {/* <div className='date-outer'>
             <div>Feedback Expected By : </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat={
                    "dd/MM/yyyy"
                  }
                  value={dateExpected}
                  label="select date"
                  name="feedbackExpectedBy"
                  minDate={new Date()}
                  maxDate={pmsCycleData?.[0]?.peerAppraisalEndDate ?? null}
                  onChange={(event) =>
                    {setDateExpected(event);setIsOpen(true);setDateErr(false)}
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      helperText={dateErr && "Please select date"}
                      sx={{
                        pointerEvents: 'none',
                        '& .MuiOutlinedInput-root': {
                          'button': {
                            pointerEvents: 'all',
                          }},
                        my: 2,
                        width: "63%",
                        "& fieldset": {
                          border: "1px solid #dedede",
                        },
                        "& .css-k4qjio-MuiFormHelperText-root": {
                          backgroundColor: "#ffffff",
                          margin: "0px",
                          paddingLeft: "0.5rem",
                        },
                        "& .MuiIconButton-root": {
                          borderRadius:"0px"
                        },
                      }}
                      {...params}
                      error={dateErr}
                    />
                  )}
                />                     
              </LocalizationProvider>
              </div> */}
              <div style={{marginBottom: '1rem'}} />
            <div className="send-btn-div">
             <Button 
                variant="contained"
                sx={{
                    color:"var(--secondary)",
                    backgroundColor:"var(--primary)",
                    borderColor:"var(--primary)",
                    "&:hover":{
                        backgroundColor:"#035c7c",
                        borderColor:"#035c7c",
                    },
                    // marginBottom:"1.5rem",
                    width:"7rem",
                }}
                disabled={submitLoader}
                onClick={submitRequest}
                >
                    Send                                     
                </Button>
                </div>
        </div>
    )
}

export default ReSendReq;