import { useEffect, useState } from "react";
// import BarGraph from "../../../common/barGraph";
// import { viewReportData } from "../dummyData";
import APIList from "../../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import SideDrawer from "../../../../common/sideDrawer";
// import EmpInfo from "./empInfo";
import { IoIosSave } from "react-icons/io";
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DialogPopup from "../../../../common/Dialog";
import checkIcon from "../../../../../assets/images/popup-check.png";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import ChangedReport from "../changedReport";
import TableSkel from "../../../../common/tableSkeleton";

const ChangedReportPageForFunctionalLeader = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [sessionInfo, setSessionInfo] = useState(null);
    // const [viewReport, setViewReport] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDraftPopup, setIsDraftPopup] = useState(false);   
    const [submitLoader, setSubmitLoader] = useState(false);

    const [reportChangeData, setReportChangeData] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);

    const [viewReport, setViewReport] = useState([]);    
    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);
    const [changedUser, setChangedUser] = useState(null);

    useEffect(() => {
      let tempReport = structuredClone(viewReport);
      let reqEmp = null;
      tempReport.forEach(obj => {
        obj.employees.forEach((employee, index) => {
            if (employee?.id === changedUser?.employeeId) {
                reqEmp = employee;
                obj.employees.splice(index, 1);
            }
        });
    });
    let reqObj = tempReport?.filter((x) => x?.mbbRating === changedUser?.postRating?.mbbRating && x?.preformanceRating === changedUser?.postRating?.preformanceRating)[0];
    reqObj?.employees?.push(reqEmp);
    console.log(tempReport,reqObj);
    setViewReport(tempReport);
    }, [reportChangeData])
    

    useEffect(() => {
    
        // setViewReport(location?.state?.viewReport);
    
        APIList.getCountOnlySessionById({id: location?.state?.sessionid})
        .then((res) => {
           setSessionInfo(res?.data);
        })
        .catch((err) => {
         toast.error(
             <div style={{display:"flex", flexDirection:"row"}}>
             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
             &nbsp;&nbsp;{err?.title}
             </div>
         );
        });

        getFinalReport();
        getFullReport();
    
      }, [])
    
    const getFinalReport = () => {
        setDataLoader(true);
        APIList.getReportChanges({
            companyId: employeeDetails?.company?.id,
            sessionId : location?.state?.sessionid
        })
        .then((res) => {
            setReportChangeData(res?.data);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
      }

    const getFullReport = () => {

        APIList.getSessionReportData({
            employeeEligibilityDTO: {},
            keyword: null,
            companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            setViewReport(res?.data?.postCalibratedRatings);
            // } else {
            //     setViewReport(res?.data?.preCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            // setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            // let all = [];
            // res?.data?.preCalibratedRatings?.forEach((item) => {
            //     item?.employees?.forEach((itemq) => {
            //         itemq["mbbRating"] = item?.mbbRating;
            //         itemq["preformanceRating"] = item?.preformanceRating;
            //         all.push(itemq);
            //     })
            // })
            // setAllPreEmployees(all);

            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

            setMbbRatings(Tempmbb);
            setPerformanceRatings(Tempperform);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
        })
    }

    const handleChange = (e, emp) => {
      
      let tempChanged = structuredClone(reportChangeData);

      let reqEmp = tempChanged?.filter((x) => x?.employeeId === emp?.employeeId)[0];

      reqEmp.postRating[e.target.name] = e.target.value;

      setChangedUser(reqEmp);

      setReportChangeData(tempChanged);
    }


  const handleSubmit = () => {
    setSubmitLoader(true);
    APIList.approveSessionFromReport({
        payload: viewReport,
        employeeId: employeeDetails?.id,
        sessionId : location?.state?.sessionid
    })
    .then((res) => {
        setOpenDialog(true);
        setSubmitLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setSubmitLoader(false);
    })
  }

  

    return(

        <div className="dashboard-main">
            <div className="rating-calibration-report-view">
                <div className="view-report-row">        
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br/> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br/> Count</div>
                    </div>             
                </div>

                <div className="final-report-heading">Final report</div>
                    
                <div className="changed-report">
            <div className="tablee">
            <table class="feedback-emp-list-table"> 
            
                <tr className="table-head-emp-list"> 
                    <th className="e-name">
                      E name 
                    </th> 
                    <th className="mid-cols">
                      Func/ sub-func
                    </th> 
                    <th className="mid-cols-small">
                    Is Recommended for Promotion
                    </th> 
                    <th className="mid-cols-small">
                    Is Promotion Approved
                    </th> 
                    <th className="mid-cols-small">
                    Rating Revised
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Pre MBSR MBB
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR Performance
                    </th> 
                    <th className="mid-cols-medium">
                    Post MBSR MBB
                    </th> 
                    
                    <th className="last-col-large">Reason</th> 
                </tr>
                
                {
                     dataLoader ? 
                     <TableSkel />
                     :
                  
                        reportChangeData?.map((data) => {
                            return(
                                <tr>
                                    <td>
                                        <div className="emp-i">
                                            <div className="name">{data?.name}</div>
                                            <div className="emp-id">{data?.employeeLevel}</div>
                                        </div>
                                    </td>
                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                    <td>
                                         <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                            {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                        </span>
                                    </td>
                                    <td>
                                        {/* {data?.promotionApproved} */}
                                    <span className={`${(data?.promotionApproved === "Yes") ? "revised" :"not-revised"}`}>
                                                 {(data?.eligibleForPromotion !== "Yes") ? "-" : isEmptyNullUndefined(data?.promotionApproved) ? "-" : data?.promotionApproved}
                                                </span>
                                    </td>
                                    <td>
                                        <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                        {
                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                        }
                                        </span>
                                    </td>
                                    <td>{data?.preRating?.preformanceRating}</td>
                                    <td>{data?.preRating?.mbbRating}</td>
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.preformanceRating}</td>
                                    }
                                    {
                                        ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ?
                                        <td>No change</td>
                                        :
                                        <td>{data?.postRating?.mbbRating}</td>
                                    }
                                    {/* <td>
                                        {data?.postRating?.preformanceRating}
                                        <Select
                                            name="preformanceRating"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={data?.postRating?.preformanceRating}
                                            // label="Mbb rating"
                                            onChange={(e) => handleChange(e, data)}
                                            sx={{
                                                // width:"100%", 
                                                color:"var(--teryiary)",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border:"none"
                                                }
                                            }}
                                        >
                                            {
                                                performanceRatings?.map((rating) => {
                                                    return(
                                                        <MenuItem value={rating}>{rating}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </td>
                                    <td>
                                        {data?.postRating?.mbbRating}
                                        <Select
                                            name="mbbRating"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={data?.postRating?.mbbRating}
                                            // label="Mbb rating"
                                            onChange={(e) => handleChange(e, data)}
                                            sx={{
                                                // width:"100%", 
                                                color:"var(--teryiary)",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border:"none"
                                                }
                                            }}
                                        >
                                            {
                                                mbbRatings?.map((rating) => {
                                                    return(
                                                        <MenuItem value={rating}>{rating}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </td> */}
                                    <td><div className="the-reason">{data?.reason}</div></td>
                                </tr>
                            )
                        })
                   
                
                
                }
                
            </table> 

          </div>
        </div>

                <div className="d-flex justify-content-end gap-3 pt-2 pb-4 main-btn">
                  
                    <button className="valuesBtn next"
                        onClick={() => handleSubmit()}
                    >
                        {
                            submitLoader ?
                                <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                                :
                                "Approve"
                        }
                    </button>
                </div>

            </div>


        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    {
                        isDraftPopup ? 
                        "Your Session Report has been securely saved as a draft. Continue editing whenever you're ready!"
                        :
                        "Session Report successfully submitted. Thank you for your dedication to the growth and development of team."
                    }                
                </div>
                <button className="button"
                    onClick={() => { history.push("/functional-leader")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        </div>
    )
}

export default ChangedReportPageForFunctionalLeader;