import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack, Typography } from "@mui/material";
import Banner from "../../../assets/images/banner.png";
import User from "../../../assets/images/user.png"; 
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import ArrowRight from "../../../assets/images/arrowRight.svg"
import FeedbackGiven from "../../../assets/images/feedbackGiven.svg"
// import Accordion from 'react-bootstrap/Accordion';
import UpArrow from "../../../assets/images/upArrow.svg"
import Checked from "../../../assets/images/checked.svg"
import { useDispatch, useSelector } from "react-redux";
import { FaCamera} from "react-icons/fa";
import ButtonM from "../../common/Button";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "../../../api";
import {actions as EmployeeDataActions} from "../../../redux/employeeData/actions";
import moment from "moment";
import { getFormattedDate, isEmptyNullUndefined } from "../../utils/utils";
import RatingTable from "../../common/RatingTable";
import React from "react";
import PerformanceHistory from "./componets/performanceHistory";
import CompHistory from "./componets/compHistory";

// const ProfileMenuItem = [
//     'Personal Information',
//     'Rating History',
//     'Performance history',
//     'Compensation history',
// ]

const UserProfilePage = () => {

    const dispatch = useDispatch();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    // console.log('employeeDetails', employeeDetails)

    const [expanded, setExpanded] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = React.useState(null);
    const [loadingRating, setLoadingRating] = React.useState(true);
    const [selectedMenu, setSelectedMenu] = React.useState('Personal Information');
    const [ratingLoader, setRatingLoader] = useState(false);

    // React.useEffect(() => {
    //     setSelectedMenu(ProfileMenuItem[0])
    // }, [])


    React.useEffect(() => {

        if(!isEmptyNullUndefined(employeeDetails?.id)) {

            setRatingLoader(true);

            APIList.getRatingHistory(employeeDetails?.id)
            .then((res) => {

                setRatingLoader(false);

             }).catch((err) => {

               toast.error(
                   <div style={{display:"flex", flexDirection:"row"}}>
                     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                     &nbsp;&nbsp;{err?.title}
                   </div>
                 )
                 setRatingLoader(false);
                 
             })
        }
        
      }, [employeeDetails]);

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleChangeProfileMenu = (item) => {
        setSelectedMenu(item)
    }

    // const getRatingHistory = async (empId) => {
    //     try {
    //         const response = await APIList.getRatingHistory(empId)
    //       if (response.status == 200) {
    //         setData(response.data);
    //       } else {
    //       }
    //       setLoadingRating(false)
    //     } catch (error) {
    //         setLoadingRating(false)
    //     }
    // };

    const handleImageChange= (event)=>{
        const file = event.target.files.length > 0 ? event.target.files[0] : undefined;
        // console.log('file',file);
        if(validateImage(file)){
            UploadFile(file);
        }else{
           
        }
    }
    const validateImage = (file) => {
        if (!file) {
          setError('Please select an image.');
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{'Please select an image.'}
            </div>
        );
          return false;
        }
        if (file.size > 2097152) {
          setError('Selected image exceeds 2MB limit.');
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{'Selected image exceeds 2MB limit.'}
            </div>
        );
          return false;
        }
        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
          setError('Please select a JPEG or PNG image.');
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{'Please select a JPEG or PNG image.'}
            </div>
        );
          return false;
        }
        setError(null);
        return true;
      };

      const UploadFile = async (file) => {
        setIsUploadingFile(() => true);
        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        APIList.fileUpload(formData , headers)
        .then((res) => {
            setSelectedImage(res.data)
            APIList.changeProfilePhote({
                "profilePhoto":{
                    "id":res.data.id
                }
            } ,employeeDetails.id)
            .then((res)=>{
                setIsUploadingFile(() => false);
                toast.success('Profile photo updated successfully');
                setTimeout(() => {
                    // window.location.reload();
                    dispatch(EmployeeDataActions.EmployeeDataRequest());
                }, 1000);

            }).catch((err)=>{
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                      <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                      &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setIsUploadingFile(() => false);
            })
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            setIsUploadingFile(() => false);
        })
      }; 

      function isObject(objValue) {
        return objValue && typeof objValue === 'object' && objValue.constructor === Object;
      }

    return(
        <div className="user-profile-page px-10 container">

            <img src={Banner} className="banner"></img>
            <div className="row">
                <div className="col-3" style={{position : 'relative'}}>
                    <div className="profile-details">
                        {/* <img src={User} className="user"></img> */}
                        <div className="user-container">
                            
                        {employeeDetails?.profilePhoto ? 
                            // <img src={selectedImage?.path || employeeDetails?.profilePhoto?.path } className="user" alt="User Image" />
                            <img src={employeeDetails?.profilePhoto?.path } className="user" alt="User Image" />
                            :
                            <Avatar 
                                name={employeeDetails.name} 
                                size="112" 
                                className="user"
                                color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                            />
                        }
                            <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            id="imageInput"
                            />
                            <label htmlFor="imageInput" className="btn btn-dark edit-button">
                            <FaCamera />
                            </label>
                        </div>
                        <p className="userName">{employeeDetails?.name}</p>
                        <p className="category">{employeeDetails?.employeeDesignation ? employeeDetails?.employeeDesignation : "Employee"}</p>
                        <div className="links">
                            {/* {
                                ProfileMenuItem.map(item => (
                                    (item === selectedMenu) ? <p className="head">{item}</p> : <p onClick={() => handleChangeProfileMenu(item)} className="link">{item}</p>
                                ))
                            } */}
                            <p onClick={() => handleChangeProfileMenu("Personal Information")} className={` ${selectedMenu === "Personal Information" ? "head" : "link"}`}>Personal Information</p>
                            <p onClick={() => handleChangeProfileMenu("Rating History")} className={` ${selectedMenu === "Rating History" ? "head" : "link"}`}>Rating History</p>
                            <p onClick={() => handleChangeProfileMenu("Performance history")} className={` ${selectedMenu === "Performance history" ? "head" : "link"}`}>Performance history</p>
                            {
                                employeeDetails?.permissions?.includes("compensation-history") && 
                                <p onClick={() => handleChangeProfileMenu("Compensation history")} className={` ${selectedMenu === "Compensation history" ? "head" : "link"}`}>Compensation history</p>
                            }
                            {/* <p className="head">Personal Information</p>
                            <p className="link">Rating History</p>
                            <p className="link">Education</p>
                            <p className="link">Performance History</p>
                            <p className="link">Learning</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-9"> 

                    {
                        (selectedMenu == 'Personal Information') && (
                            <>
                                <div className="accorDetails personalInfo">
                                    <p className="accordian-title">Personal Details</p>
                                    <div className="accordian-data">
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Employee Name</p>
                                            <p className="subTitle">{employeeDetails?.name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Employee Id</p>
                                            <p className="subTitle">{employeeDetails?.employeeId}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Email Id</p>
                                            <p className="subTitle">{employeeDetails?.emailId}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Employee type</p>
                                            <p className="subTitle">{employeeDetails?.employeeType?.name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Date of joining</p>
                                            <p className="subTitle">{getFormattedDate(employeeDetails?.dateOfJoining, dateFormat)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Level</p>
                                            <p className="subTitle">{employeeDetails?.employeeLevel?.name}</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="accorDetails personalInfo">
                                    <p className="accordian-title">Organisational Details</p>
                                    <div className="accordian-data">
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Employee Function</p>
                                            <p className="subTitle">{isObject(employeeDetails?.employeeFunction) ? employeeDetails?.employeeFunction?.name : employeeDetails?.employeeFunction}</p>
                                            {/* <p className="subTitle">{employeeDetails?.employeeFunction}</p> */}
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Employee Sub function</p>
                                            <p className="subTitle">{employeeDetails?.employeeSubFunction?.name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Manager Name</p>
                                            <p className="subTitle">{employeeDetails?.manager?.name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Manager Id</p>
                                            <p className="subTitle">{employeeDetails?.manager?.employeeId}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="title">Manager Email Id</p>
                                            <p className="subTitle">{employeeDetails?.manager?.emailId}</p>
                                        </div>
                                        

                                    </div>
                                </div>
                            </>
                        )
                    }

                    {
                        (selectedMenu == 'Rating History') && (
                            <>
                                <div className="accorDetails personalInfo">
                                    {/* <p className="accordian-title">Rating History </p> */}
                                    {
                                    ratingLoader ? (
                                        <Skeleton variant="rounded" height={260} />
                                    ) : (
                                            isEmptyNullUndefined(data) ? (
                                                <Typography
                                                    sx={{
                                                        fontSize: 'var(--textFontSize) !important',
                                                        color: 'var(--tertiary) !important',
                                                    }}                                              
                                                //  variant='h3'
                                                  fontWeight={500}> Rating history not available.</Typography>
                                            ) : (
                                                <RatingTable data={data} />
                                            )
                                    )
                                    }
                                </div>
                            </>
                        )
                    }

                    {
                        (selectedMenu == 'Performance history') && (
                            <>
                               <div className="accorDetails personalInfo">
                                    <p className="accordian-title">Performance history</p>
                                   <PerformanceHistory />
                                </div>
                            </>
                        )
                    }

                    {
                        (selectedMenu == 'Compensation history') && (
                            <>
                               <div className="accorDetails personalInfo compHistory">
                                    {/* <p className="accordian-title">Compensation history</p> */}
                                   <CompHistory />
                                </div>
                            </>
                        )
                    }


                </div>
            </div>
        </div>
    )
};

export default UserProfilePage; 
