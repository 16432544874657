const SVGFeedback = (props) => (
    <svg
      width={27}
      height={28}
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9853 15.4272L16.8 18.6125L15.487 17.2995C15.1782 16.9907 14.6772 16.9907 14.3685 17.2997C14.0595 17.6085 14.0595 18.1095 14.3685 18.4185L16.2409 20.2905C16.3952 20.445 16.5977 20.5223 16.8002 20.5223C17.0027 20.5223 17.205 20.445 17.3595 20.2905L21.104 16.546C21.413 16.2372 21.413 15.7362 21.104 15.4274C20.7951 15.1185 20.2943 15.1185 19.9853 15.4272Z"
        fill="white"
      />
      <path
        d="M23.5613 10.5254H18.5273V4.27886C18.5273 2.3829 16.9846 0.84021 15.0884 0.84021H3.43845C1.54248 0.84021 0 2.3829 0 4.27886V12.0685C0 13.6924 1.13173 15.056 2.64743 15.4143V17.364C2.64743 17.6839 2.84024 17.9723 3.13584 18.0949C3.23369 18.1354 3.33648 18.155 3.43845 18.155C3.64423 18.155 3.84652 18.0747 3.99792 17.9233L6.41402 15.5074H8.47293V21.7537C8.47293 23.6499 10.0154 25.1924 11.9114 25.1924H20.586L23.0019 27.6085C23.1533 27.7599 23.3556 27.8402 23.5616 27.8402C23.6633 27.8402 23.7661 27.8206 23.864 27.7801C24.1596 27.6575 24.3524 27.3691 24.3524 27.0492V25.1007C25.8685 24.7425 27 23.3778 27 21.7537V13.9641C27 12.0679 25.4573 10.5254 23.5613 10.5254ZM6.08629 13.9253C5.87638 13.9253 5.67513 14.0086 5.52702 14.1569L4.22946 15.4542V14.7164C4.22946 14.2794 3.87536 13.9253 3.43845 13.9253C2.41486 13.9253 1.58203 13.0923 1.58203 12.0685V4.27886C1.58203 3.25507 2.41486 2.42224 3.43845 2.42224H15.0884C16.1122 2.42224 16.9452 3.25507 16.9452 4.27886V10.5254H11.9114C10.0284 10.5254 8.49477 12.0471 8.47375 13.9253H6.08629ZM25.418 21.7537C25.418 22.7775 24.5851 23.6103 23.5613 23.6103C23.1244 23.6103 22.7703 23.9646 22.7703 24.4014V25.1394L21.473 23.8421C21.3247 23.6936 21.1234 23.6103 20.9135 23.6103H11.9114C10.8878 23.6103 10.055 22.7775 10.055 21.7537V13.9641C10.055 12.9403 10.8878 12.1074 11.9114 12.1074H23.5613C24.5851 12.1074 25.418 12.9403 25.418 13.9641V21.7537Z"
        fill="white"
      />
    </svg>
  );
  export default SVGFeedback;