import User from "../../../../../assets/images/user.png"; 
import UpArrow from "../../../../../assets/images/upArrow.svg"
import Checked from "../../../../../assets/images/checked.svg"
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useEffect, useState } from "react";
import DialogPopup from "../../../../common/Dialog";
import checkIcon from "../../../../../assets/images/popup-check.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { accordianSVGstyle, isEmptyNullUndefined, textAreaStyle, values, valuesFeedback } from "../../../../utils/utils";
import APIList from "../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import AccSkeleton from "../../../../common/akkSkeleton";
import { useSelector } from "react-redux";
import { async } from "q";
import { FaRegThumbsUp } from "react-icons/fa";
import Avatar from "react-avatar";
import SideDrawer from "../../../../common/sideDrawer";
import EmpProfile from "../../../../common/employeeProfile";
import { useLocation } from 'react-router-dom'
import { object } from "prop-types";
import { IoIosSave } from "react-icons/io";

const EmpFeedback = () => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const queDataLoader = useSelector((state) => state?.questionsData?.loader);
    const questions = useSelector((state) => state?.questionsData?.activeGroupedFeedbackQuestions);
    const allQuestions = useSelector((state) => state?.questionsData?.allActiveFeedbackQuestions);
    const valuesToShow = useSelector((state) => state?.questionsData?.values);

    const initialPayload = { 
        okrStatus: null,
        valueStatus: null,
        reviewedBy: {
            id: employeeDetails?.id,   
        },
        reviewedDate: Date.now(),
        // ...((location?.state?.aid !== null) ? { 
            selfAppraisal: {id:location?.state?.aid} 
        // } : {})
        ,
        status: "ACTIVE",
        subFeedbacks: [],
        }    

    const [expanded, setExpanded] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [empInfo, setEmpInfo] = useState(null);
    const [empInfoLoader, setEmpInfoLoader] = useState(false);
    const [feedbackPayload, setfeedbackPayload] = useState(initialPayload);
    const [feedbackDataLoader, setFeedbackDataLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [readMoreId, setReadMoreId] = useState(null);
    // const [allQuestions, setAllQuestions] = useState([]);
    // const [questions, setQuestions] = useState({});
    // const [queDataLoader, setQueDataLoader] = useState(false);
    // const [valuesToShow, setvaluesToShow] = useState(null);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    const [trialPayload, setTrialPayload] = useState(null);
    // console.log("trialPayload",trialPayload);
    // for side drawer only
    const drawerObj = {
        profile: false,
        info: false,
        peers: false,
        data: null,
    }
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [drawerData, setdrawerData] = useState(null);

    // for values only
    const [selectedDemonstrated, setSelectedDemonstrated] = useState([]);
    const [selectedNotDemonstrated, setSelectedNotDemonstrated] = useState([]);

    const [feedbackStatus, setFeedbackStatus] = useState("");
    const [isDraftPopup, setIsDraftPopup] = useState(false);   

    useEffect(() => {
        setFeedbackStatus(location?.state?.feedbackStatus);
        setEmpInfoLoader(true);        
      APIList.getEmployeeById({id:params?.id})
      .then((res) => {
        setEmpInfo(res?.data);
        if(isEmptyNullUndefined(res?.data?.manager)){
            history.push({pathname:`/no-feedback/${res?.data?.id}`, state: {employeeId:res?.data?.employeeId}});
        }
        setEmpInfoLoader(false);
      })
      .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
        )
        setEmpInfoLoader(false);
      });
    }, []);    

    useEffect(() => {
        if (isOpenDrawer) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }
    }, [isOpenDrawer]);    
    
    useEffect(() => {       

        setFeedbackDataLoader(true);
       if (employeeDetails?.employeeDesignation === "HR") {
        APIList.getFeedbackSelfApraisalData({id: location?.state?.aid, type:"peer"})
        .then((res) => {
            // console.log("reswerd",res)
            if(!isEmptyNullUndefined(res?.data) || Object.keys(res?.data)?.length !== 0) {
                // setfeedbackPayload(res?.data[0]);
                const resd = res?.data;
                let subFeeds = [];
                resd?.subFeedbacks?.forEach((sub) => {
                     subFeeds.push({...sub, question:{id: sub?.question?.id}})
                })
                const trialPayload1 = { 
                    id: resd?.id,
                    okrStatus: resd?.okrStatus,
                    valueStatus: resd?.valueStatus,
                    reviewedBy: {
                        // id: employeeDetails?.id,   
                        id: empInfo?.manager?.id,   
                    },
                    reviewedDate: Date.now(),
                    // ...((location?.state?.aid !== null) ? { 
                        selfAppraisal: {id:location?.state?.aid} 
                    // } : {})
                    ,
                    // status: "ACTIVE",
                    subFeedbacks: subFeeds,
                    } 
                    setTrialPayload(trialPayload1);
                    setfeedbackPayload(trialPayload1);
                // setTimer(false);
            } else {
                
                APIList.createFeedback({...feedbackPayload, status: "Draft"})
                .then((res) => {
                    setFeedbackDataLoader(true);
                    APIList.getFeedbackSelfApraisalData({id: location?.state?.aid, type:"peer"})
                    .then((res) => {
                        if(!isEmptyNullUndefined(res?.data) || Object.keys(res?.data)?.length !== 0) {
                            // setfeedbackPayload(res?.data[0]);
                            const resd = res?.data;
                            let subFeeds = [];
                            resd?.subFeedbacks?.forEach((sub) => {
                                 subFeeds.push({...sub, question:{id: sub?.question?.id}})
                            })
                            const trialPayload1 = { 
                                id: resd?.id,
                                okrStatus: resd?.okrStatus,
                                valueStatus: resd?.valueStatus,
                                reviewedBy: {
                                    // id: employeeDetails?.id,   
                                    id: empInfo?.manager?.id,     
                                },
                                reviewedDate: Date.now(),
                                // ...((location?.state?.aid !== null) ? { 
                                    selfAppraisal: {id:location?.state?.aid} 
                                // } : {})
                                ,
                                // status: "ACTIVE",
                                subFeedbacks: subFeeds,
                                } 
                                setTrialPayload(trialPayload1);
                                setfeedbackPayload(trialPayload1);
                        } 
                        setFeedbackDataLoader(false);
                        // setTimer(false);
                    })
                    .catch((err) => {
                        toast.error(
                            <div style={{display:"flex", flexDirection:"row"}}>
                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                            &nbsp;&nbsp;{err?.title}
                            </div>
                        );
                        history.push("/feedback");
                        setFeedbackDataLoader(false);
                    });
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    history.push("/feedback");
                })
            
            }
            setFeedbackDataLoader(false);
            // setTimer(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            history.push("/feedback");
            setFeedbackDataLoader(false);
        });
       } else {
        APIList.getFeedbackById({id: location?.state?.employeeId ,type:"manager"})
        .then((res) => {
            if(!isEmptyNullUndefined(res?.data) || Object.keys(res?.data)?.length !== 0) {
                // setfeedbackPayload(res?.data[0]);
                const resd = res?.data[0];
                let subFeeds = [];
                resd?.subFeedbacks?.forEach((sub) => {
                     subFeeds.push({...sub, question:{id: sub?.question?.id}})
                })
                const trialPayload1 = { 
                    id: resd?.id,
                    okrStatus: resd?.okrStatus,
                    valueStatus: resd?.valueStatus,
                    reviewedBy: {
                        id: employeeDetails?.id,   
                    },
                    reviewedDate: Date.now(),
                    // ...((location?.state?.aid !== null) ? { 
                        selfAppraisal: {id:location?.state?.aid} 
                    // } : {})
                    ,
                    // status: "ACTIVE",
                    subFeedbacks: subFeeds,
                    } 
                    setTrialPayload(trialPayload1);
                    setfeedbackPayload(trialPayload1);
                // setTimer(false);
            } else {
                
                APIList.createFeedback({...feedbackPayload, status: "Draft"})
                .then((res) => {
                    setFeedbackDataLoader(true);
                    APIList.getFeedbackById({id: location?.state?.employeeId ,type:"manager"})
                    .then((res) => {
                        if(!isEmptyNullUndefined(res?.data) || Object.keys(res?.data)?.length !== 0) {
                            // setfeedbackPayload(res?.data[0]);
                            const resd = res?.data[0];
                            let subFeeds = [];
                            resd?.subFeedbacks?.forEach((sub) => {
                                 subFeeds.push({...sub, question:{id: sub?.question?.id}})
                            })
                            const trialPayload1 = { 
                                id: resd?.id,
                                okrStatus: resd?.okrStatus,
                                valueStatus: resd?.valueStatus,
                                reviewedBy: {
                                    id: employeeDetails?.id,   
                                },
                                reviewedDate: Date.now(),
                                // ...((location?.state?.aid !== null) ? { 
                                    selfAppraisal: {id:location?.state?.aid} 
                                // } : {})
                                ,
                                // status: "ACTIVE",
                                subFeedbacks: subFeeds,
                                } 
                                setTrialPayload(trialPayload1);
                                setfeedbackPayload(trialPayload1);
                        } 
                        setFeedbackDataLoader(false);
                        // setTimer(false);
                    })
                    .catch((err) => {
                        toast.error(
                            <div style={{display:"flex", flexDirection:"row"}}>
                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                            &nbsp;&nbsp;{err?.title}
                            </div>
                        );
                        history.push("/feedback");
                        setFeedbackDataLoader(false);
                    });
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    history.push("/feedback");
                })
            
            }
            setFeedbackDataLoader(false);
            // setTimer(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            );
            history.push("/feedback");
            setFeedbackDataLoader(false);
        });
       }
     
    }, [empInfo]);    
    
    const getNoOfQuestions = () => {
        let total = 0;
    Object.keys(questions)?.forEach((key) => {
       if(!isEmptyNullUndefined(questions[key])) {
        total = total + questions[key]?.length;
       } else {
        total = total + 0;
       }
    }) 
    return total;
    }
    
    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    // const [timer, setTimer] = useState(true);

    // const timeoutPost = setTimeout(() => {
    //     console.log("timeoutPost")
    // }, 1000)

    // const timeoutEdit = setTimeout(() => {
    //     APIList.editFeedback({...feedbackPayload, status: "Draft"})
    //     .then((res) => {
           
    //     })
    //     .catch((err) => {
    //         toast.error(
    //             <div style={{display:"flex", flexDirection:"row"}}>
    //             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //             &nbsp;&nbsp;{err?.title}
    //             </div>
    //         );
    //         history.push("/feedback");
    //     })
    // }, 30000)
/* HTML: <div class="loader"></div> */

    const [autoSaveLoaer, setAutoSaveLoaer] = useState(false);

    useEffect(() => {
        const autoSaveData = setTimeout(() => {
            setAutoSaveLoaer(true);
            if (!isEmptyNullUndefined(feedbackPayload?.id)) {
                APIList.editFeedback({...feedbackPayload, status: "Draft"})
                .then((res) => {
                    setAutoSaveLoaer(false);
                })
                .catch((err) => {
                    toast.error(
                        err?.title !== "statusActive" &&
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setAutoSaveLoaer(false);
                    history.push("/feedback");
                })
            }            
        }, 2000)
    
        return () => clearTimeout(autoSaveData)
      }, [feedbackPayload])

    const handleChangeFeedback = (event, fieldtype, queData, valData) => {
        // console.log("queData",queData)
        let temp = structuredClone(feedbackPayload);
        
        if (fieldtype === "value") {
            let tempQ = temp?.subFeedbacks?.filter((x) => x?.question?.id === queData?.id);
            if(tempQ?.length > 0){
                let tempV = tempQ[0]?.selectedValues;
                if (tempQ[0]?.selectedValues?.filter((x) => x?.id === valData?.id)?.length > 0) {
                    temp.subFeedbacks.filter((x) => x.question.id === queData.id)[0].selectedValues = tempV?.filter((x) => x?.id !== valData?.id)
                } else {
                    tempV?.push({id: valData?.id});
                }
            } else {
                temp?.subFeedbacks?.push({
                
                    response: "",
                    question: {id: queData?.id},
                    feedbackType:queData?.type,
                    selectedValues: [{id: valData?.id}]
                
            });
            }
            
        }   

        if (fieldtype === "dropdown") {
           temp[event.target.name] = event.target.value; 
        }   

        if (fieldtype === "text") {
           let tempR = temp?.subFeedbacks?.filter((x) => x?.question?.id === event.target.id)[0];
           if (!isEmptyNullUndefined(tempR)) {
               tempR.response = event.target.value;
           } else {
            temp?.subFeedbacks?.push({
                
                    response: event.target.value,
                    question: {id: queData?.id},
                    feedbackType:queData?.type,
                    selectedValues: []
                
            })
           }
        }        

        setfeedbackPayload(temp);
        
    };

    const isValid = () => {
        let valid = true;

        if (isEmptyNullUndefined(feedbackPayload?.okrStatus)) {
          valid = false;
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              Please select OKR / Performance trending ratings.
            </div>
        );
        return false;
        }

        if (isEmptyNullUndefined(feedbackPayload?.valueStatus)) {
          valid = false;
          toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              Please select Medibuddy Beliefs (MBB) ratings.
            </div>
        );
        return false;
        }

        // if(feedbackPayload?.subFeedbacks?.length < getNoOfQuestions()){
        //     toast.error(
        //         <div style={{display:"flex", flexDirection:"row"}}>
        //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //           Please give your feedback for all question.
        //         </div>
        //     );
        //     return false;
        // }

        let subFeedQues = [];

        feedbackPayload?.subFeedbacks?.forEach((item) => {
            subFeedQues.push(item?.question?.id);
            })

        allQuestions?.forEach((que) => {
            if(que?.status === "ACTIVE" && !subFeedQues?.includes(que?.id) && valid){
                valid = false;
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                      <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                      Please give your feedback for {que?.type === "Performance" ? "OKR / Performance" : que?.type === "Values" ? "MediBuddy Beliefs (MBB)" : "Develop and Evolve"}
                    </div>
                );
                return false;
            }
        })

        // feedbackPayload?.subFeedbacks?.forEach((item) => {
        // //    if(isEmptyNullUndefined(item?.response) || item?.response?.split(' ').length > 500) {
        //    if(item?.response?.split(' ').length > 500) {
        //     valid = false;
        //     toast.error(
        //         <div style={{display:"flex", flexDirection:"row"}}>
        //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //           Your feedback should not be more than 500 words.
        //         </div>
        //     );
        //     return false;
        //    }
        // })

        // feedbackPayload?.subFeedbacks?.forEach((item) => {
        //    if(isEmptyNullUndefined(item?.response)) {
        //     valid = false;
        //     toast.error(
        //         <div style={{display:"flex", flexDirection:"row"}}>
        //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
        //           Please give your feedback for {item?.feedbackType}
        //         </div>
        //     );
        //     return false;
        //    }
        // })

        feedbackPayload?.subFeedbacks?.forEach((item) => {

            if(isEmptyNullUndefined(item?.response) && valid) {
                valid = false;
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                      <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                      Please give your feedback for {item?.feedbackType === "Performance" ? "OKR / Performance" : item?.feedbackType === "Values" ? "MediBuddy Beliefs (MBB)" : "Develop and Evolve"}
                    </div>
                );
                return false;
               }

           if(item?.feedbackType === "Values" && isEmptyNullUndefined(item?.selectedValues) && valid) {
            valid = false;
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  Please select values for {item?.feedbackType === "Performance" ? "OKR / Performance" : item?.feedbackType === "Values" ? "MediBuddy Beliefs (MBB)" : "Develop and Evolve"}
                </div>
            );
            return false;
           }

           if(item?.response?.split(' ').length > 500 && valid) {
            valid = false;
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  Your feedback should not be more than 500 words.
                </div>
            );
            return false;
           }
        })

        return valid;
    };

    const handleSaveAsDraft = () => {
        setIsDraftPopup(true);
        setSubmitLoader(true);
        APIList.editFeedback({...feedbackPayload, status: "Draft"})
        .then((res) => {
            setOpenDialog(true);
            setSubmitLoader(false);
        })
        .catch((err) => {
            toast.error(
                err?.title !== "statusActive" &&
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSubmitLoader(false);
        })
    }

    const handleSubmitFeedback = () => {
       
        setShowConfirmationPopup(false);
            if(!isEmptyNullUndefined(feedbackPayload?.id)) {
                setSubmitLoader(true);
                APIList.editFeedback({...feedbackPayload, status:"ACTIVE"})
                .then((res) => {
                    setOpenDialog(true);
                    setSubmitLoader(false);
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSubmitLoader(false);
                })
                
            } else {
                setSubmitLoader(true);
                APIList.createFeedback({feedbackPayload})
                .then((res) => {
                    setOpenDialog(true);
                    setSubmitLoader(false);
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSubmitLoader(false);
                })
                }
                      
    };

    const handleReadmore = (id) => {
        if(readMoreId === id) {
            setReadMoreId(null);
        } else {
            setReadMoreId(id);
        }
    };

    const handleSelectValue = (value) => {
        let tempSelecteddemonstrated = structuredClone(selectedDemonstrated);

        if (tempSelecteddemonstrated?.includes(value)) {
            tempSelecteddemonstrated = tempSelecteddemonstrated.filter(x => x!==value);
        } else {
            // if(!selectedNotDemonstrated?.includes(value)) {
            tempSelecteddemonstrated.push(value);
        // }
        }       

        setSelectedDemonstrated(tempSelecteddemonstrated);
    }

    const handleSelectValueNotDemo = (value) => {
        let tempSelectedNotdemonstrated = structuredClone(selectedNotDemonstrated);

        if (tempSelectedNotdemonstrated?.includes(value)) {
            tempSelectedNotdemonstrated = tempSelectedNotdemonstrated.filter(x => x!==value);
        } else {
            // if(!selectedDemonstrated?.includes(value)) {
                tempSelectedNotdemonstrated.push(value);
            // }            
        }       

        setSelectedNotDemonstrated(tempSelectedNotdemonstrated);
    }

    const handleOpenSideDrawer = (data, toShow) => {
        
        if (toShow === "empProfile") {
            let temp = {...drawerObj, profile: true, data: data && data};
            setdrawerData(temp);
        }
        if (toShow === "peer") {
            let temp = {...drawerObj, peers: true, data: data && data};
            setdrawerData(temp);
        }
        if (toShow === "info") {
            let temp = {...drawerObj, info: true, data: data && data};
            setdrawerData(temp);
        }

        setIsOpenDrawer(true);
    };
    
    const handleConfirmation = () => {
        if (isValid()){
            setShowConfirmationPopup(true);
        }
    }

    return(
        <div className="feedback-main">
            {
                empInfoLoader ? 
                <AccSkeleton />
                :
                <div className="feedbackAssessment m-3">
                        <div className="d-flex justify-content-between align-items-center bor-b">
                            <div className="left d-flex align-items-center gap-3">
                                {empInfo?.profilePhoto ?
                                    <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
                                    :
                                    <Avatar 
                                        name={empInfo?.name} 
                                        size="75" 
                                        className="userImg"
                                        color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                                    />
                                }
                                    <div>
                                        <p className="title">{empInfo?.name}</p>
                                        <p className="id">{empInfo?.employeeDesignation}</p>
                                    </div>
                            </div>
                            <div>                                
                            <button className="viewBtn" onClick={() => handleOpenSideDrawer(empInfo, "empProfile")}>View Profile</button>
                            {/* <button className="viewBtn">View Assesment</button> */}

                            </div>
                        </div>
                        {
                            (feedbackDataLoader || queDataLoader) ? 
                            <AccSkeleton />
                            :
                            <>
                           

                                {
                                    Object.keys(questions).length > 0 && Object.keys(questions).map((key, index) => {
                                        
                                        return(   
                                            !isEmptyNullUndefined(questions[key]) ?      
                                            <div className="acc-wrapper" key={index}>                                   
                                            <Stack sx={{
                                                margin:"1rem 0px",
                                                "& .MuiAccordion-rounded":{
                                                    border:"1px solid #dad2d2",
                                                    borderRadius:"10px !important",
                                                    boxShadow:"none"
                                                }                           
                                            }}>
                                                <Accordion
                                                // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
                                                expanded={expanded === key} onChange={() => handleChange(key)} data-value={key}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                                                sx={{
                                                    width:"100%",   
                                                    fontSize:"1.25rem",
                                                    fontWeight:"600",
                                                    "& .MuiAccordionSummary-content":{
                                                        width:"100%", 
                                                        display:"flex", 
                                                        justifyContent:"space-between",
                                                    }}}
                                                >
                                                    <p className="accordian-heading" >{key === "Performance" ? "OKR / Performance" : key === "Values" ? "MediBuddy Beliefs (MBB)" : "Develop and Evolve"}</p>
                                                    <div className="acc-head-btns">
                                                    {/* <p className="i-btn" onClick={() => handleOpenSideDrawer(key === "Performance" ? "okr" : key === "Values" ? "mbb" : "dev", "info")}><p>i</p></p> */}
                                                    <BsChevronDown
                                                        style={{...accordianSVGstyle ,transform: expanded !== key ? "rotate(0deg)" : "rotate(180deg)"}}
                                                    />          
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                sx={{
                                                    fontSize:"1rem"
                                                }}
                                                >
                                                     <div className="acc-head-btns1">
                                                    <p className="i-btn" onClick={() => handleOpenSideDrawer(key === "Performance" ? "okr" : key === "Values" ? "mbb" : "dev", "info")}><p>i</p></p>
                                                             
                                                    </div>
                                                 {
                                                    questions[key]?.map((item, index1) => {
                                                        return(
                                                            <div key={index1}>
                                                           
                                                            <div className="accDetail-questions">
                                                                <p>{index1 + 1}. {
                                                                item?.name?.split("\n")?.map((que,i) => {
                                                                    return(
                                                                        
                                                                         (i === 0) ?
                                                                         <span key={i}>{que}</span>
                                                                         :
                                                                         <p style={{marginLeft:"1rem"}} key={i}>{que}</p>
                                                                        
                                                                    )
                                                                })
                                                                }</p>

                                                                {
                                                                    // employeeDetails?.company?.showAppraisal && 
                                                                    <p className="icon-btns">
                                                                    <p className="peer-btn" onClick={() => handleOpenSideDrawer(null, "peer")}>Peer Feedback</p>
                                                                    {/* <p className="i-btn" onClick={() => handleOpenSideDrawer(null, "info")}><p>i</p></p> */}
                                                                </p>
                                                                }
                                                                
                                                            </div>
                                                            <div className="accDetal-xFeedback">
                                                                <p className="q-title">Employee Self Appraisal</p>
                                                                <p className="q-text">
                                                                Disable for this Mid year MBSR Check-in Cycle
                                                                    {/* {readMoreId === 0 ? feedbackString : feedbackString.substring(0, 140)}
                                                                    {readMoreId === 0 ? <span className="readmore-text" onClick={() => handleReadmore(0)}>read less</span> : <span className="readmore-text" onClick={() => handleReadmore(0)}>...read more</span>} */}
                                                                </p>
                                                            </div>
                                                        <p className="q-title-values">Manager Feedback </p>
                                                        
                                                        {
                                                            key === "Values" &&
                                                            <div className="rateOuter">
                                                            {
                                                            valuesToShow?.map((value,index2) => {
                                                                    return(
                                                                        <div
                                                                        key={index2}
                                                                        // className='rate'
                                                                         className={`rate ${feedbackPayload?.subFeedbacks?.filter((x) =>  x?.question?.id === item?.id)[0]?.selectedValues?.filter((x) =>  x?.id === value?.id)?.length > 0 ? "active" : ""}`} onClick={() => handleChangeFeedback(null, "value", item, value)}
                                                                         >
                                                                            <p className="rateTitle">{value?.name}</p>
                                                                            {/* {
                                                                                selectedDemonstrated?.includes(item) && <img src={Checked} className="checked"></img>
                                                                            } */}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                        }
                                                        
                                                            <TextField 
                                                                id={item?.id}
                                                                name="response"
                                                                // id="outlined-multiline-static" 
                                                                label="" 
                                                                variant="outlined" 
                                                                placeholder="Type here..." 
                                                                multiline
                                                                rows={3}
                                                                value={feedbackPayload?.subFeedbacks?.filter((x) =>  x?.question?.id === item?.id)[0]?.response || ""}
                                                                sx={textAreaStyle}
                                                                onChange={(e) => handleChangeFeedback(e, "text", item)}
                                                                error={(feedbackPayload?.subFeedbacks?.filter((x) =>  x?.question?.id === item?.id)[0]?.response?.split(' ').length > 500) ? true : false}
                                                                // helperText="*min 100 words"
                                                                />
                                                                {feedbackPayload?.subFeedbacks?.filter((x) =>  x?.question?.id === item?.id)[0]?.response?.split(' ').length > 500  && (
                                                                <FormHelperText sx={{ color: "var(--primary)" }}>
                                                                    *max 500 words
                                                                </FormHelperText>
                                                                 )} 
                    
                                                                <hr />

                                                                
                                                            </div>
                                                        )
                                                    })
                                                 }   
                                                 {
                                                    key === "Performance" &&
                                                    <>
                                                    <div className="dropdown-heading">
                                                        Please specify the "Performance (WHAT)" Rating
                                                        <p className="i-btn" onClick={() => handleOpenSideDrawer("okrRate", "info")}><p>i</p></p>
                                                    </div>
                                                     <FormControl sx={{width:{md:"50%",sm:"100%"}, margin:"1rem 0px"}}>
                                                        <InputLabel id="demo-simple-select-label">Trending Rating</InputLabel>
                                                            <Select
                                                                name="okrStatus"
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={feedbackPayload?.okrStatus || ""}
                                                                label="Trending Rating"
                                                                onChange={(e) => handleChangeFeedback(e, "dropdown")}
                                                            >
                                                                {/* <MenuItem disabled value="">
                                                                    <em>Placeholder</em>
                                                                </MenuItem> */}
                                                                <MenuItem value={"OutStanding"}>Outstanding</MenuItem>
                                                                <MenuItem value={"Exceeds"}>Exceeds</MenuItem>
                                                                <MenuItem value={"Meets"}>Meets</MenuItem>
                                                                <MenuItem value={"Inconsistent"}>Inconsistent</MenuItem>
                                                                <MenuItem value={"Unacceptable"}>Unacceptable</MenuItem>
                                                            </Select>
                                                    </FormControl>
                                                    </>
                                                 }

                                                 {
                                                    key === "Values" &&
                                                    <>
                                                     <div className="dropdown-heading">
                                                            Please specify the "MBB (How)" Rating
                                                            <p className="i-btn" onClick={() => handleOpenSideDrawer("valueRate", "info")}><p>i</p></p>
                                                        </div>
                                                        <FormControl sx={{width:{md:"50%",sm:"100%"}, marginTop:"1rem"}}>
                                                            <InputLabel id="demo-simple-select-label">Trending Rating</InputLabel>
                                                                <Select
                                                                    name="valueStatus"
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={feedbackPayload?.valueStatus || ""}
                                                                    label="Trending Rating"
                                                                    onChange={(e) => handleChangeFeedback(e, "dropdown")}
                                                                >
                                                                    {/* <MenuItem disabled value="">
                                                                        <em>Placeholder</em>
                                                                    </MenuItem> */}
                                                                    {/* <MenuItem value={"OutStanding"}>Outstanding</MenuItem> */}
                                                                    <MenuItem value={"Exemplary"}>Exemplary</MenuItem>
                                                                    <MenuItem value={"Strong"}>Strong</MenuItem>
                                                                    <MenuItem value={"Adequate"}>Adequate</MenuItem>
                                                                    <MenuItem value={"Insufficient"}>Insufficient</MenuItem>
                                                                </Select>
                                                        </FormControl>
                                                    </>
                                                 }
                                                            
                                                     
                                                   
                                                </AccordionDetails>
                                                </Accordion>
                                            </Stack>
                                            </div>
                                            :
                                            null
                                        )
                                    })
                                }

                        
                                    {
                                        location?.state?.feedbackStatus !== "Submitted" &&
                                        <div className="d-flex justify-content-end gap-3 pt-5 main-btn">
                                            <div className="valuesBtn draft">
                                                {
                                                    autoSaveLoaer ? 
                                                        <>
                                                        <IoIosSave style={{fontSize:"1.15rem"}} />&nbsp; Saving<span className="auto-save-loader">.....</span>
                                                        </>
                                                        :
                                                        "Auto Save 2 sec"
                                                }                                                
                                                </div>
                                            <div className="valuesBtn save-draft"
                                                onClick={() => handleSaveAsDraft()}
                                                >
                                                {
                                                    submitLoader ?
                                                        <CircularProgress size={29} sx={{ color: "var(--primary)" }} />
                                                        :
                                                        "Save as Draft"
                                                }

                                            </div>
                                            <button className="valuesBtn next"
                                                onClick={() => handleConfirmation()}
                                            >
                                                {
                                                    submitLoader ?
                                                        <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                                                        :
                                                        "Submit"
                                                }
                                            </button>
                                        </div>
                                    }
                        
                       
                            </>
                        }
                        
                    </div>
            }
             
        {
            showConfirmationPopup && 
            <DialogPopup openDialog={showConfirmationPopup} setOpenDialog={setShowConfirmationPopup} redirect={false} >
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                Thank you for completing the appraisal. Your feedback is important. Please note that once submitted, revisions will not be feasible. Click 'Confirm' if you intend to proceed. If you want to further edit or revise, click 'Cancel'
                </div>
                <div className="multi-btn" >
                <button className="button"
                    onClick={() => handleSubmitFeedback()}
                >Confirm</button>
                <button className="button"
                onClick={() => setShowConfirmationPopup(false)}
                >Cancel</button>
                </div>
                </div>
            </DialogPopup>
        }
        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/feedback">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    {
                        isDraftPopup ? 
                        "Your Feedback has been securely saved as a draft. Continue editing whenever you're ready!"
                        :
                        "Appraisal successfully submitted. Your valuable feedback has been recorded. Thank you for your dedication to the growth and development of team."
                    }                
                </div>
                <button className="button"
                    onClick={() => { history.push("/feedback")}}
                >Done</button>
                </div>
            </DialogPopup>
        }

        {
            isOpenDrawer && 
            <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                <div>
                    {
                        drawerData?.profile &&
                        <EmpProfile empInfo={drawerData?.data} />
                    }
                    {
                        drawerData?.peers &&
                        <div>Peer Feedback</div>
                    }
                    {
                        drawerData?.info && drawerData?.data === "okrRate" &&
                        <div className="scrl-div">
                            <div className="heading">Please specify the "Performance (WHAT)" Rating</div>
                            <table className="drawer-table">
    <tr>
      <th>Rating</th>
      <th>What does it mean?</th>
      <th>Performance (WHAT)</th>
    </tr>
    <tr>
      <td>O</td>
      <td>Outstanding</td>
      <td>This rating describes the employee whose overall performance & impact consistently exceeds expectations of a role in a given level. This rating is used as special recognition for extraordinary accomplishments that have a significant impact on the organization. This employee will demonstrate high-level capabilities and proactively take on higher levels of responsibility.
      Consistently exceeds expectations</td>
    </tr>
    <tr>
      <td>E</td>
      <td>Exceeds</td>
      <td>This rating describes the employee whose overall performance & impact frequently exceeds expectations of a role in a given level. Their performance has a major contribution to the overall output of the team/ function/ organization.
      Frequently exceeds expectations</td>
    </tr>
    <tr>
      <td>M</td>
      <td>Meets</td>
      <td>This rating describes the employee whose overall performance & impact is consistent with/ may occasionally exceed expectations of a role in a given level, even after consideration of any relevant environmental factors.
      Consistent with/occasionally exceeds expectations</td>
    </tr>
    <tr>
      <td>In</td>
      <td>Inconsistent</td>
      <td>This rating describes the employee whose overall performance & impact partially meets the expectations of a role in a given level. Results in some of the OKRs are inadequate, even after consideration of any relevant environmental factors. Certain OKRs are only accomplished with a level of help or supervision that is disproportionate to that which would reasonably be expected from an individual in this role and at this level.Partially meets expectations</td>
    </tr>
    <tr>
      <td>U</td>
      <td>Unacceptable</td><td>This rating describes the employee who overall performance & impact does not consistently meet the expectations of a role in a given level. Results in most of the OKRs are inadequate, even after consideration of any relevant environmental factors. Failure to meet the OKR targets has a negative impact on the ability of the team/ function/organization to achieve its goals.Does not consistently meet expectations</td>
    </tr>
  </table>
                        </div>
                    }
                    {
                        drawerData?.info && drawerData?.data === "valueRate" &&
                        <div className="scrl-div">
                            <div className="heading">Please specify the "MBB (How)" Rating</div>
                            <table className="drawer-table">
    <tr>
      <th>Coding</th>
      <th>Rating</th>
      <th>Definition</th>
    </tr>
    <tr>
      <td>E</td>
      <td>Exemplary</td>
      <td>This employee consistently demonstrates exemplary behavior consistent with MediBuddy values. The individual demonstrates role model behavior, is strong in most of the MediBuddy values and deficient in none of them.</td>
    </tr>
    <tr>
      <td>S</td>
      <td>Strong</td>
      <td>This employee consistently demonstrates behavior that is consistent with the expectations of MediBuddy values. Is solid in demonstrating MBB values and sometimes goes above and beyond.</td>
    </tr>
    <tr>
      <td>A</td>
      <td>Adequate</td>
      <td>This employee frequently demonstrates behavior that is consistent with the expectations of MediBuddy values. While there are areas of improvement, areas of deficiency do not impact the performance of self and/or others in the company. There might also be situations when the employee side-steps the opportunities of going above and beyond.</td>
    </tr>
    <tr>
      <td>I</td>
      <td>Insufficient</td>
      <td>This employee inconsistently demonstrates MediBuddy values. His/Her area of deficiency is adversely impacting the performance of self and/or others. There are instances of behavior that creates a culture that will be of detriment to the Company.</td>
    </tr>
  </table>

                        </div>
                    }
                    {
                        drawerData?.info && drawerData?.data === "okr" &&
                        <div className="scrl-div">
                            <div className="heading">Helpful tips to write Manager Assessment : </div> 
                           <p>
                           <li> Identify the key achievements in each of the assigned OKRs - this will ensure nothing is missed out</li>
                           <li> Focus on the Impact (Impact should be a measurable/ quantifiable output) created by the employee</li>
                           <li> Be specific, factual and metric driven while writing the feedback</li>
                            <li>Be specific for writing the reasons for why OKRs were not achieved </li>
                            <li>Think through the potential of the respective employee and write development areas for further growth     </li>         
                            </p>

                            <div className="big-heading">Example 1 (Goal Achieved) </div>

                            <div className="heading">Employee Assessment : </div>
                            <p>
                           <span className="situatin"> Received the requirement from the client to increase VMER (Video Consultation) conversion from 35% to 70% in TAT of T0 (same day).</span>
                           <span className="behavior"> I started tracking the daily calls and provided training to the team members on call creating urgency over call. I also worked with my manager to implement an auto dialler which improved the productivity of the team. I prepared the daily dashboard to check the conversion trend. </span>
                           <span className="impact"> VMER conversion increased from 35% to 70% in TAT of T0 which impressed the client and helped in getting future business.</span>
                            </p>

                            <div className="heading">Manager Assessment : </div>
                            <p>
                           <span className="behavior"> You ensured that the conversion rate reduced thereby giving a higher quality service to the customers. With keeping in mind that the problem is solved for long term you have fixed the root cause problem with your deep dive analysis. </span>
                           <span className="impact"> This is a great example of Execution Excellence being able to create an impact. </span>
                            </p>

                            <div className="big-heading">Example 2 (Goal not Achieved) </div>

                            <div className="heading">Employee Assessment : </div>
                            <p>
                           <span className="situatin"> I am responsible for smooth payout for doctors. However it was missed because few logs that are used for part of calculating the payment were missing during MER process. A few Doctors complained about it and said that they would stop taking further cases.</span>
                           <span className="behavior"> I had asked to the tech team for giving the logs details which I didn't received on time and hence I couldn't do the payment calculation which further led to the delay and the doctors stopped taking the cases since payment was not made on time. </span>
                           <span className="impact"> Hence this delay happened in customers consultation as most of the doctors were not taking the case.</span>
                            </p>

                            <div className="heading">Manager Assessment : </div>
                            <p>
                           <span className="situatin"> Any delay in doctor payment is a critical issue which needs to be resolved immediately.</span>
                           <span className="behavior">Rather than waiting for the Tech Team to send us the logs and then calculating the payment, you could have shown proactiveness in resolving finding an immediate solution.</span>
                           <span className="impact">There was a clear opportunity to create a better process which you missed and this impacted our services. You failed to show "End to End Ownership". </span>
                            </p>

                            <div className="color-info">
                                <p><p className="color-block situatin"></p>Situation</p>
                                <p><p className="color-block behavior"></p>Behavior</p>
                                <p><p className="color-block impact"></p>Impact</p>
                            </div>
                        </div>
                    }
                    {
                        drawerData?.info && drawerData?.data === "dev" &&
                        <div className="scrl-div">
                            <div className="heading">Development Feedback : </div> 
                            <p>
                            Identifying development areas is a crucial step in personal and professional growth. It involves recognizing strengths, opportunities for improvement, and continuous evolution towards success.                           
                            </p>
                        </div>
                    }
                    {
                        drawerData?.info && drawerData?.data === "mbb" &&
                        <div className="scrl-div">
                            {/* <strong>Development Feedback : </strong> <br/>
                            <p>
                            Identifying development areas is a crucial step in personal and professional growth. It involves recognizing strengths, opportunities for improvement, and continuous evolution towards success.                           
                            </p> */}

                           <div className="heading"> Customer Obsession</div> 
                                We work tirelessly to earn and keep customer trust. We work backward from customer needs and go above & beyond to
                                enable great experience. We resolve customer pain points and stay with helping them till the issue is resolved. If we are not
                                confident of using our product ourselves or for our family, we don't ship it to the customer. 
                               <div className="heading"> Speed</div> 
                                Speed matters in business. Our systems, mechanisms, and plans are designed to make us move faster. We realize many
                                decisions and actions are reversible (two-way street) and are willing to experiment, succeed/fail, learn and move forward. We
                                invent, simplify, and value calculated risk-taking to move faster. We don't postpone, or wait for things to happen. We adapt
                                to change proactively and quickly to fit the needs of the situation 
                                <div className="heading">   End to End Ownership</div> 
                                We think like owners and act on behalf of the entire company, beyond just our own team. We don't give excuses or say "that's
                                not my job." We ensure that the ball doesn't get dropped, we work across teams, collaborating and helping each other to
                                achieve the end goal.
                              <div className="heading">  Execution Excellence</div> 
                                We always insist on the highest standards and do not settle for 'good enough' - many people may think these standards are
                                unreasonably high. We continuously look to innovate and drive our teams to efficiently deliver high output of quality products,
                                services, and processes on time and at scale. We work closely with our stakeholders/partners to ensure that the end result is
                                achieved and nothing is dropped.We do not send defects down the line, and ensure that problems are fixed, so they stay fixed.
                               <div className="heading"> Hire & Develop the Best</div> 
                                We raise the bar with every hire, performance assessment,and promotiontobuildhighperforming teams. We invest in coaching
                                & building our talent. We recognize exceptional talent, and willingly move them to roles where they will contribute their best
                                to the organization and also grow in their careers. We empower our talent and our teams to achieve high standards of decision
                                making and delivery. We recognize capabilities different from our own and integrate diverse perspectives to build solutions.
                               <div className="heading"> Think Big, Think Long Term</div> 
                                Thinking small will keep us small. We constantly think for the long term & think of ways to take our organization to the next
                                orbit. We use technology, systems, & mechanisms to scale & build leverage. We pursue big ideas and instill belief in our teams
                                to achieve them.
                              <div className="heading">  Invent & Simplify to Do More with Less</div> 
                                We accomplish more even with time and resource constraints. These constraints force us to become more resourceful, self-
                                sufficient, and inventive, with a high focus on customer quality. We are not fixed in our thinking and create new approaches
                                to problem solving when the conventional approaches do not work or are not available. We innovate & creatively problem
                                solve rather than waiting for others to solve our problems, or by putting/throwing more money or people to solve something.
                                For us it is about overcoming limitations in the most economical and simple way and not being cheap. Being inventive and
                                resourceful is critical, else our inefficiencies will increase the cost of healthcare.
                              <div className="heading">  Dive Deep, Be Hands On</div> 
                                We stay in touch with what's happening in our team at all levels. We effectively delegate, provide ownership, and
                                independence while ensuring the right audit/review mechanisms to keep us connected with metrics, anecdotes, and defects
                                related to our process and products. No task is beneath us, we are willing to roll up our sleeves and take the required actions
                                to deliver results.
                              <div className="heading">  Be Bold, Disagree & Commit</div> 
                                We respectfully say what we believe when it is in the best interest of our customer/company. Our communication is direct, we
                                say what we mean, even when doing so is uncomfortable or exhausting. We do not compromise for the sake of social cohesion.
                                Once a decision is determined (even if we originally disagreed), we commit wholly and put all our energies behind it.
                                <div className="heading">  Pride in the Purpose of Our Work</div> 
                                We are proud to work at MediBuddy because everything we do profoundly impacts lives around the world. We realise that
                                our individual roles impact the lives & health of our customers and this energizes each one of us to contribute our best to build
                                great products and services.
                              <div className="heading">  Earn Trust & Be Vocally Self Critical</div> 
                                We genuinely listen to and build collaborative working relationships with others. We understand that it is our personal
                                responsibility to earn trust and not demand trust & respect. We earn trust of others by resolving and solving problems
                                together. We do not hide our missteps, instead, we are forthcoming about them with an attitude to do better. We come
                                together to solve the problem and to ensure that the errors do not translate to customer impact. We come forward with
                                problems or information, even when doing so is awkward or embarrassing. Individuals who are vocally self critical earn the
                                trust of others and are appreciated for putting their ego aside.
                              <div className="heading">  Make High Quality Decisions</div> 
                                We demonstrate a high level of openness and are willing to reconsider our deepest convictions, ideas, and assumptions. Our
                                solutions are backed by data, anecdotes, subject matter inputs, past experiences, and logic.
                                We identify the key inputs to our business and work on strengthening them in order to deliver high quality and timely outputs.
                                We believe that great ideas can come from anyone and we do not let experience, hierarchy, title, or tenure become barriers to
                                finding the best solutions. To take others along, we make the effort to explain the background and the logic of our inputs,
                                decisions, and solutions to people.

                        </div>
                    }
                </div>
            </SideDrawer>
        }
                    
        </div>
    )
}

export default EmpFeedback;