import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

        LineController, // Add LineController import
        LineElement, // Add LineElement import
  } from 'chart.js';
import { useEffect, useState } from 'react';
  import { Bar } from 'react-chartjs-2';
import { isEmptyNullUndefined } from '../../utils/utils';
import { Collapse } from '@mui/material';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

        LineController, // Register LineController
        LineElement, // Register LineElement
  );
  
  export const options = {
    responsive: true,
    tension: 0.34,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
        y: {
          beginAtZero: true,
          suggestedMax: 100,
          ticks: {
            stepSize: 10,
          },
          title: {
            display: true,
            text: 'Percentage ( % )',
            font: {
                // size: 16,
                family:'poppins'
            }
          },
          ticks: {
            font: {
                // size: 16,
                family:'poppins'
            }
        }
        },
        x: {
            ticks: {
                font: {
                    // size: 16,
                    family:'poppins'
                }
            }
        },
        // rightYAxis: {
        //   position: 'right',
        //   beginAtZero: true,
        //   suggestedMax: 100,
        //   ticks: {
        //     stepSize: 10,
        //   },
        // },
      },

  };

  const blockerData = [
    {
        "preformanceRating": "Unacceptable",
        "mbbRating": "Insufficient",
        comboRating: "UI",
        multipler: 1
    },
    {
        "preformanceRating": "Inconsistent",
        "mbbRating": "Insufficient",
        comboRating: "InI",
        multipler: 2

    },
    {
        "preformanceRating": "Unacceptable",
        "mbbRating": "Adequate",
        comboRating: "UA",
        multipler: 2
    },
    {
        "preformanceRating": "Meets",
        "mbbRating": "Insufficient",
        comboRating: "MI",
        multipler: 3
    },
    {
        "preformanceRating": "Unacceptable",
        "mbbRating": "Strong",
        comboRating: "US",
        multipler: 3
    },    
    {
        "preformanceRating": "Exceeds",
        "mbbRating": "Insufficient",
        comboRating: "EI",
        multipler: 4
    },
    {
        "preformanceRating": "Unacceptable",
        "mbbRating": "Exemplary",
        comboRating: "UE",
        multipler: 4
    },
    {
        "preformanceRating": "Inconsistent",
        "mbbRating": "Adequate",
        comboRating: "InA",
        multipler: 4
    },
    {
        "preformanceRating": "Outstanding",
        "mbbRating": "Insufficient",
        comboRating: "OI",
        multipler: 5
    },
    {
        "preformanceRating": "Meets",
        "mbbRating": "Adequate",
        comboRating: "MA",
        multipler: 6
    },
    {
        "preformanceRating": "Inconsistent",
        "mbbRating": "Strong",
        comboRating: "InS",
        multipler: 6
    },
    {
        "preformanceRating": "Inconsistent",
        "mbbRating": "Exemplary",
        comboRating: "InE",
        multipler: 8
    },
    {
        "preformanceRating": "Exceeds",
        "mbbRating": "Adequate",
        comboRating: "EA",
        multipler: 8
    },
    {
        "preformanceRating": "Meets",
        "mbbRating": "Strong",
        comboRating: "MS",
        multipler: 9
    },
    {
        "preformanceRating": "Outstanding",
        "mbbRating": "Adequate",
        comboRating: "OA",
        multipler: 10
    },
    {
        "preformanceRating": "Meets",
        "mbbRating": "Exemplary",
        comboRating: "ME",
        multipler: 12
    },
    {
        "preformanceRating": "Exceeds",
        "mbbRating": "Strong",
        comboRating: "ES",
        multipler: 12
    },
    {
        "preformanceRating": "Outstanding",
        "mbbRating": "Strong",
        comboRating: "OS",
        multipler: 15
    },
    {
        "preformanceRating": "Exceeds",
        "mbbRating": "Exemplary",
        comboRating: "EE",
        multipler: 16
    },
    {
        "preformanceRating": "Outstanding",
        "mbbRating": "Exemplary",
        comboRating: "OE",
        multipler: 20
    },
]



const LineGraphForAll = ({data, preData}) => {


    const tempgraphdata = {
        labels: [],
        datasets: []
    }


    useEffect(() => {
        
        let tempResData = []
        let tempPreResData = []

        let graphLineForData = {
            type: 'line',
            label: 'Post',
            data: [],
            // "yAxisID": "rightYAxis",
            backgroundColor: "green",
            borderColor: "green",
        }

        let preGraphLineForData = {
            type: 'line',
            label: 'Pre',
            data: [],
            // "yAxisID": "rightYAxis",
            backgroundColor: "#ffe200",
            borderColor: "#ffe200",
        }

        if(isEmptyNullUndefined(data)) {

        } else {
            tempResData = JSON.parse(JSON.stringify(data))
        }
        if(isEmptyNullUndefined(preData)) {

        } else {
            tempPreResData = JSON.parse(JSON.stringify(preData))
        }
        
        const totalEmployees = tempResData?.reduce((total, item) => total + item?.employees?.length, 0);



        blockerData?.forEach((item) => {
            // post graph data
            tempResData?.forEach((x) => {
            if ((item?.mbbRating === x?.mbbRating) && (item?.preformanceRating === x?.preformanceRating)) {
                graphLineForData.data.push((Number(x?.employees?.length)/Number(totalEmployees))*100);
                tempgraphdata.labels.push(`${item?.comboRating}(${x?.employees?.length})`)
            }
            })
            
            // pre graph data
            tempPreResData?.forEach((x) => {
            if ((item?.mbbRating === x?.mbbRating) && (item?.preformanceRating === x?.preformanceRating)) {
                preGraphLineForData.data.push((Number(x?.employees?.length)/Number(totalEmployees))*100);
            }
            })
        })

        tempgraphdata.datasets.push(graphLineForData)
        tempgraphdata.datasets.push(preGraphLineForData)


        setGraphdata(() => tempgraphdata)

    },[])

    const [graphdata, setGraphdata] = useState({
        labels: [],
        datasets: []
    })

    const [showGraphTab, setShowGraphTab] = useState("Hide Graph");  

    return(
        <div>
            <div style={{justifyContent: 'flex-end'}} className="tabs-outer">
                <div className="tabs">
                    <div className={`tab ${showGraphTab === "Show Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Show Graph")}>Show Graph</div>
                    <div className={`tab ${showGraphTab === "Hide Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Hide Graph")}>Hide Graph</div>
                </div>
            </div> 
            <div>
                <Collapse in={showGraphTab === "Show Graph" ? true : false}>
                    <Bar options={options} data={graphdata} />
                </Collapse>
            </div> 
            {/* <div><Bar options={options} data={graphdata} /></div>  */}
        </div>
    )
}

export default LineGraphForAll;