import { IoMdSearch } from "react-icons/io";
import { Search, SearchIconWrapper, StyledInputBase, getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../../utils/utils";
import { IoCloseSharp } from "react-icons/io5";
import TableSkel from "../../../common/tableSkeleton";
import Avatar from "react-avatar";
import { Button, IconButton, Popover, Stack } from "@mui/material";
import { BiDotsVerticalRounded } from "react-icons/bi";
import PaginationMedi from "../../../common/pagination";
import SideDrawer from "../../../common/sideDrawer";
import ViewRequest from "../viewSentRequest";
import SideFilters from "../../../common/sideFilters";
import FilterSelect from "../../../common/filterSelect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import APIList from "../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import filterIcon from "../../../../assets/images/filter.svg";
import checkIcon from "../../../../assets/images/popup-check.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SendReq from "../sendRequest";
import ReSendReq from "../reSendRequest";

const SentRequestTab = () => {

    const history = useHistory();

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [expanded, setExpanded] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [empListLoader, setEmpListLoader] = useState(false);
    // const [showAppraisal, setshowAppraisal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [activeTab, setActiveTab] = useState("send");
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [totalSearchPages, setTotalSearchPages] = useState(0);

    const [selectedPeer, setSelectedPeer] = useState(null);
    const [isViewReq, setIsViewReq] = useState(false);
    const [isFilterOn, setIsFilterOn] = useState(false);
    const [sentCount, setSentCount] = useState(0);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
    const [isSendRequest, setIsSendRequest] = useState(false);

    function HandleOutsideClick(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (
              ref.current &&
              !ref.current.contains(event.target)
            ) {               
                    setSearch("");
                               
            }
          }
    
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      const wrapperRef = useRef(null);
      HandleOutsideClick(wrapperRef);

    // start for pop-over
    const [anchorEl, setAnchorEl] = useState(null);    
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickAction = (event, emp) => {
        // console.log("emp",emp);
        setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
    // end for pop-over
    
   
    

    // useEffect(() => {
    //     getSentPageData();      
    // }, [currentPage]);      

      useEffect(() => {
        setEmpListLoader(true);
        const getSearch = setTimeout(() => {
            console.log("here too")
            APIList.getAllSentRequest({
                payload : {
                    employeeEligibilityDTO: filterPayload,
                    employeeId : employeeDetails?.id,
                    keyword: search
                },
                page: currentPage, //0,
                size: 10
            })        
            .then((res) => {
                setEmpList(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                setFirstLoad(false);
                setIsOpenFilter(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
            })
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [search, currentPage])  

useEffect(() => {
//    if(!isEmptyNullUndefined(employeeDetails?.company?.showAppraisal)){
//     setshowAppraisal(employeeDetails?.company?.showAppraisal)
//    }   
   getSentCount();
  }, [employeeDetails]); 
  
  const getSentCount = () => {
    APIList.getSentRequestCount({id: employeeDetails?.id})
   .then((res) => {
    setSentCount(res?.data?.submittedRequestCount);
   })
   .catch((err) => {
    // console.log(err);
   })
  }

    const getSentPageData = () => {
        setEmpListLoader(true);
        APIList.getAllSentRequest({
            payload : {
                employeeEligibilityDTO: filterPayload,
                employeeId : employeeDetails?.id,
                keyword: search
            },
            page: currentPage,
            size: 10
        })        
        .then((res) => {
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setEmpListLoader(false);
            setFirstLoad(false);
            setIsOpenFilter(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }
    
    useEffect(() => {
      setSortEmpList(empList);
    }, [empList])

    const handleSubmitFilter = () => {
        // setActiveTab("send");
        if(Object.keys(filterPayload).length === 0){
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;Please select some filters 
                </div>
            );
        } else {           
            setIsFilterOn(true);
            getSentPageData();
        }      
    }

    const handleResetFilters = () => {
        setEmpListLoader(true);
        APIList.getAllSentRequest({
            payload : {
                employeeEligibilityDTO: {},
                employeeId : employeeDetails?.id,
                keyword: search
            },
            page: 0,
            size: 10
        })        
        .then((res) => {
            setEmpList(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
            setEmpListLoader(false);
            setFirstLoad(false);
            setIsFilterOn(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
      };
  
  const autoSearchText = (e) => {
    setCurrentPage(0);
    setSearch(e.target.value);
  };
 
  const handleRecall = () => {
    APIList.recallFeedbackRequest(
        {
            requestId:selectedPeer?.id
        }
    ).then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              {` Recalled successfully`}
            </div>
          );
        handleClose();
        getSentPageData();
    }).catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
    })

  } 

  const handleViewRequest = () => {
    // console.log("emp",emp);
    // setSelectedPeer(emp);
    setIsSendRequest(false);
    setIsOpenDrawer(true);
    setIsViewReq(true);
    handleClose();
  };

  const handleSendRequest = () => {
      setIsSendRequest(true);
      setIsOpenDrawer(true);
      handleClose();
    };

  const getSentReqStatus = (status) => {
    switch (status) {
        case "Pending":
          return "initial";
          break;
        case "Declined":
            return "red";
          break;
        case "Recalled":
            return "red";
          break;
        case "Completed":
            return "initial";
          break;
        case "Expired":
            return "red";
          break;
        default:
            return "initial";
      }
  }

    return (
        <div className="feedback-request-one">

            <div className="search-wrapper">
                <div className="r-f-tabs">
                    {
                        employeeDetails?.permissions?.includes("create-request-peer-feedback") && 
                            <div className={`tab`} onClick={() => history.push("/self-assesment/request-feedback")}>Send Request</div>
                    }
                    
                    <div className={`tab active`} >Submitted Request <span>{sentCount < 10 ? `0${sentCount}` : sentCount}</span></div>
                </div>


                <div className="second-div">
                    <Search
                        ref={wrapperRef}
                    >
                        <SearchIconWrapper>
                            <IoMdSearch />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search by employee name or email"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={autoSearchText}
                            value={search}
                        />
                        {
                            !isEmptyNullUndefined(search) &&
                            <IoCloseSharp onClick={() => setSearch("")} style={{ position: "absolute", right: "10px", top: "34%", color: "gray", cursor: "pointer" }} />
                        }


                    </Search>
                    {/* <div className="filter-tab" onClick={() => setIsOpenFilter(true)}>
                        <img src={filterIcon} alt="" />
                        Filter
                        {
                            isFilterOn &&
                            <div className="red-dot"></div>
                        }
                    </div> */}
                    <div className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
                        i
                    </div>
                </div>
            </div>

            {
                // empListLoader ? 
                // <EmplistSkel />
                // :
                <div className="row">
                    <div className="col-12">
                        <div className="feedback-one">



                            <div className="tablee">
                                <table class="feedback-emp-list-table">

                                    <tr className="table-head-emp-list-sent">
                                        {/* <th>My Immediate team member
                                        </th> */}
                                        <th>Employee Name
                                        </th> 
                                        <th className="mid-cols">
                                            Request Date
                                        </th>
                                        <th className="mid-cols">Request End Date</th>
                                        <th className="mid-cols">Status</th>
                                        <th></th>
                                    </tr>



                                    {
                                        empListLoader ?
                                            <TableSkel />
                                            :
                                            sortEmpList?.map((emp, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="empDetaila">
                                                                <div className="empPic">
                                                                    {emp?.requestedTo?.profilePhoto?.path ?
                                                                        <img src={emp?.requestedTo?.profilePhoto?.path} className="userImg" alt="User Image" />
                                                                        :
                                                                        <Avatar
                                                                            name={emp?.requestedTo?.name}
                                                                            size="45"
                                                                            className="userImg"
                                                                            color={"#00425A"}
                                                                        />
                                                                    }
                                                                </div>
                                                                <div className="userInfo">
                                                                    <p className="name">{emp?.requestedTo?.name}</p>
                                                                    {/* <p className="designation">{emp?.requestedTo?.employeeDesignation ? emp?.requestedTo?.employeeDesignation : "Designation"}</p> */}
                                                                    <p className="designation">{emp?.requestedTo?.employeeDesignation ? emp?.requestedTo?.employeeDesignation : ""}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="btns">
                                                                <div>{getFormattedDate(emp?.requestDate, dateFormat)}</div>
                                                                <div>{getFormattedTime(emp?.requestDate, "hh:mm A")}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="btns">
                                                                <div>{getFormattedDate(emp?.expiryDate, dateFormat)}</div>
                                                                {/* <div>{getFormattedDate(emp?.requestDate, "hh:mm a")}</div> */}
                                                                <div>{getFormattedTime(emp?.expiryDate, "hh:mm A")}</div>
                                                            </div>
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="btns">
                                                                {emp?.status == 'Pending' ? (
                                                                    <span>Pending For Feedback</span>
                                                                ) : (
                                                                    <span style={{ color: getSentReqStatus(emp?.status) }} >
                                                                        {
                                                                            emp?.status === "Completed" &&
                                                                            <img src={checkIcon} className="checkIcon"></img>
                                                                        }
                                                                        {
                                                                            emp?.status === "Completed" ?
                                                                                "Feedback Given"
                                                                                :
                                                                                emp?.status
                                                                        }

                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                            
                                                        
                                                        <td>
                                                            <div className="action">
                                                                    
                                                                <IconButton variant="outlined" onClick={(event) => handleClickAction(event, emp)}>
                                                                    {/* Provide Feedback */}
                                                                    <BiDotsVerticalRounded />
                                                                </IconButton>
                                                                <Popover
                                                                    id={id}
                                                                    open={open}
                                                                    anchorEl={anchorEl}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    sx={{
                                                                        "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                                                            boxShadow: "4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                                            border: "1px solid  #e9e9e9",
                                                                            // padding:"0.25rem",
                                                                            fontFamily: "poppins",
                                                                            fontSize: "0.75rem",
                                                                            borderRadius: "10px",
                                                                            padding: "5px 0px",
                                                                            cursor: "pointer",
                                                                            marginLeft: "-1rem"
                                                                        },
                                                                        "& .MuiPopover-paper" : {
                                                                            boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                                            border:"1px solid  #e9e9e9",
                                                                            // padding:"0.25rem",
                                                                            fontFamily:"poppins",
                                                                            fontSize:"0.75rem",
                                                                            borderRadius: "10px",
                                                                            padding: "5px 0px",
                                                                            cursor:"pointer",
                                                                            marginLeft:"-1rem"                                                        
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="popover-btns" onClick={() => handleViewRequest()}>View sent request</div>

                                                                    {
                                                                        (selectedPeer?.status === "Pending" || selectedPeer?.status === "Pending For Approval") &&
                                                                        <div className="popover-btns red" onClick={() => handleRecall()}>Recall</div>
                                                                    }
                                                                    {
                                                                        selectedPeer?.status === "Recalled" &&
                                                                        <div className="popover-btns" onClick={() => handleSendRequest()}>Resend</div>
                                                                    }

                                                                </Popover>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                )
                                            }
                                            )
                                    }

                                </table>
                                {
                                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>

                                }

                                {
                                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                                }


                            </div>

                        </div>
                    </div>
                </div>
            }

            {
                isOpenDrawerForIbtn &&
                <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                    <div className="i-btn-cintent">
                        Please provide content
                    </div>
                </SideDrawer>
            }

            {
                isOpenDrawer && isViewReq && !isEmptyNullUndefined(selectedPeer) &&
                <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                    <ViewRequest req={selectedPeer} />
                </SideDrawer>
            }
            {
            isOpenDrawer && isSendRequest && !isEmptyNullUndefined(selectedPeer) &&
            <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                <ReSendReq peer={selectedPeer} setIsOpen={setIsOpenDrawer} setSearch={setSearch} getPageData={getSentPageData} getSentCount={getSentCount} requestId={selectedPeer?.id} />
            </SideDrawer>
            } 
            {
                isOpenFilter &&
                <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} >
                    <div className="filter-wrapper">

                        <div className="filters-heading">Filters</div>

                        <Stack>
                            {
                                !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                    return (
                                        <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                    )
                                })
                            }
                        </Stack>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={empListLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </div>
                </SideFilters>
            }
        </div>
    )
}

export default SentRequestTab;