import { IoIosArrowBack } from "react-icons/io";
import bellIcon from "../../../assets/images/header/bell.svg";
import settingsIcon from "../../../assets/images/header/settings.svg";
import {
  getHeaderMidTitle,
  getHeaderTitle,
  isEmptyNullUndefined,
} from "../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as logInActions } from "../../../redux/login/actions";
import Profile from "../../../assets/images/sidebar/profile";
import { RiLockPasswordFill } from "react-icons/ri";
import Logout from "../../../assets/images/sidebar/logout";
import { useLocation } from "react-router-dom";
import Avatar from "react-avatar";
import { actions as EmployeeDataActions } from "../../../redux/employeeData/actions";
import { actions as QueActions } from "../../../redux/questions/actions";
import { actions as DropdownDataActions } from "../../../redux/dropdown/actions";
import { actions as CountsActions } from "../../../redux/countts/actions";
import { actions as PmscycleActions } from "../../../redux/pmsCycle/actions";
import { actions as AnnualcycleActions } from "../../../redux/annualCycle/actions";
import { actions as NotificationActions } from "../../../redux/notification/actions";

import mediLogo from "../../../assets/images/mdiLogo/logo.PNG";
import reloadIcon from "../../../assets/images/reload.png";
import APIList from "../../../api";
import ChangePassword from "../../pages/changePassword";
import ChangePassPopup from "./components/changePasswordPopup";
import PopupChangePassword from "./components/passwordChange";
import NotificationDrawer from "../notificationDrawer";
import Notification from "../../pages/Notification";

const Header = () => {
  const annualcycleData = useSelector(
    (state) => state.annualcycleReducer.annualcycle[0]
  );
  const employeeDetails = useSelector((state) => state?.empData?.empData);
  const employeeDetailsLoader = useSelector((state) => state?.empData?.loader);
  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );
  const notificationData = useSelector(
    (state) => state?.notificationReducer?.notification
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSettingDropDownOpen, setIsSettingDropDownOpen] = useState(false);
  const [isNotificationDropDownOpen, setIsNotificationDropDownOpen] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if(localStorage.getItem("loggedOutFromFooter")){ 
     localStorage.removeItem("loggedOutFromFooter");
     window.location.reload();
    }
   }, []);

  // ***** for dynamic themes start *****

  useEffect(() => {
    const primaryColor = employeeDetails?.company?.primaryColor;
    const secondaryColor = employeeDetails?.company?.secondaryColor;
    const tertiaryColor = employeeDetails?.company?.tertiaryColor;
    if (
      employeeDetails?.company?.isCustomizationrequired == true ||
      employeeDetails?.company?.isCustomizationrequired == "true"
    ) {
      if (!isEmptyNullUndefined(primaryColor)) {
        document.documentElement.style.setProperty("--primary", primaryColor);
      } else {
        document.documentElement.style.setProperty("--primary", "#00425A");
      }
      if (!isEmptyNullUndefined(secondaryColor)) {
        document.documentElement.style.setProperty(
          "--secondary",
          secondaryColor
        );
      } else {
        document.documentElement.style.setProperty("--secondary", "#BAD7E9");
      }
      if (!isEmptyNullUndefined(tertiaryColor)) {
        document.documentElement.style.setProperty("--tertiary", tertiaryColor);
      } else {
        document.documentElement.style.setProperty("--tertiary", "#7E7E7E");
      }
    } else {
      document.documentElement.style.setProperty("--primary", "#00425A");
      document.documentElement.style.setProperty("--secondary", "#BAD7E9");
      document.documentElement.style.setProperty("--tertiary", "#7E7E7E");
    }

    // if(!isEmptyNullUndefined(employeeDetails?.defaultLanguage)){
    //   dispatch(EmployeeDataActions.setPrefLanguage({lang:employeeDetails?.defaultLanguage}));
    // }
  }, [employeeDetails]);

  // ***** for dynamic themes end *****

  useEffect(() => {
    if (!isEmptyNullUndefined(userToken)) {
      dispatch(EmployeeDataActions.EmployeeDataRequest());
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (!isEmptyNullUndefined(userToken)) {
      APIList.getAccount()
        .then((res) => {
          // console.log("res",res);
          setOpenDialog(res?.data?.firstLogin);
          // if(res?.data?.firstLogin) {
          //   history.push("/change-password");
          // }
        })
        .catch((err) => {
          // console.log("err",err);
        });
    }
  }, []);

  useEffect(() => {
    if (!isEmptyNullUndefined(employeeDetails?.id)) {
      // dispatch(
      //   QueActions.getQuestionsRequest({
      //     companyID: employeeDetails?.company?.id,
      //   })
      // );
      dispatch(
        DropdownDataActions.DropdownDataRequest({
          companyID: employeeDetails?.company?.id,
        })
      );
      // dispatch(CountsActions.getCountsRequest({id: employeeDetails?.id}));

      dispatch(
        PmscycleActions.PmscycleDataRequest({
          companyID: employeeDetails?.company?.id,
        })
      );
      dispatch(
        AnnualcycleActions.AnnualcycleDataRequest({
          companyID: employeeDetails?.company?.id,
        })
      );
      dispatch(
        NotificationActions.NotificationCountDataRequest({
          employeeId: employeeDetails?.id,
        })
      );
      dispatch(
        AnnualcycleActions.ManFeedbackCloseLoopRequest({
          companyId: employeeDetails?.company?.id,
        })
      );
      // dispatch(
      //   NotificationActions.NotificationDataRequest({
      //     type: "ALL",
      //     employeeId: employeeDetails?.id,
      //   })
      // );
      
      // dispatch(
      //   NotificationActions.NotificationCountDataRequest({
      //     employeeId: employeeDetails?.id,
      //   })
      // );
    }
  }, [employeeDetails?.id]);

  useEffect(() => {

    const interval = setInterval(() => {
      if(!isEmptyNullUndefined(employeeDetails?.id)){
      dispatch(
        NotificationActions.NotificationCountDataRequest({
          employeeId: employeeDetails?.id,
        })
      );
    }
    }, 300000);
  

    if (notificationData.length == 0) {
      if(!isEmptyNullUndefined(employeeDetails?.id)){
      dispatch(
        NotificationActions.NotificationDataRequest({
          type: "ALL",
          employeeId: employeeDetails?.id,
        })
      );
      }
    }

    return () => clearInterval(interval);

  }, []);

  const handleBack = () => {
    return history.goBack();
  };

  const settingHandler = () => {
    if (!isSettingDropDownOpen) {
      setIsSettingDropDownOpen(true);
    }
    // setIsSettingDropDownOpen(!isSettingDropDownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSettingDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const settingRouteList = [
    {
      name: "Profile",
      path: "/profile",
      icon: <Profile />,
    },
    {
      name: "Change Password",
      path: "/change-password",
      icon: <RiLockPasswordFill />,
    },
  ];

  const redirection = (url) => {
    if (!isEmptyNullUndefined(url)) {
      setIsSettingDropDownOpen(false);
      return history.push(`${url}`);
    }
  };

  const truncatePath = (url) => {
    const temp = JSON.parse(JSON.stringify(url));

    const lastSlashIndex = temp.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      // temp.substring(0, lastSlashIndex);
      temp.slice(0, lastSlashIndex);
      if (temp.substring(0, lastSlashIndex)) {
        return temp.substring(0, lastSlashIndex);
      } else {
        return url;
      }
      // return url;
    }
    // console.log('temp', temp)
  };

  const activeBar = (routes) => {
    // const activeSubBar = (subItem) => {
    //   // console.log(' activeBar subItem', subItem);
    //   return (
    //     <div className="active-sub-item">
    //       {/* <div>{subItem?.icon ? subItem.icon : ''}</div> */}
    //       <div className="itme-title">{subItem.name}</div>
    //     </div>
    //   );
    // };

    // const inActiveSubBar = (subItem) => {
    //   // console.log('inactive subItem', subItem);
    //   return (
    //     <div className="inactive-sub-item">
    //       {/* <div>{subItem?.icon ? subItem.icon : ''}</div> */}
    //       <div onClick={() => redirection(subItem.path)} className="itme-title">{subItem.name}</div>
    //     </div>
    //   );
    // };

    // const renderSubcategory = () => {
    //   if (routes?.subcategory) {
    //     return (
    //       <div className="subcategory-main-container">
    //         {routes.subcategory.map((subItem) => {
    //           console.log('======subItem.path', subItem.path);
    //           console.log('======location.pathname', location.pathname);
    //           return subItem.path === location.pathname
    //             ? activeSubBar(subItem)
    //             : inActiveSubBar(subItem);
    //         })}
    //       </div>
    //     );
    //   }
    //   return null; // If no subcategory, return null or any default content
    // };

    return (
      <div className="active-nav-route-button">
        <div
          onClick={() => redirection(routes.path)}
          className="nav-button-radious"
        >
          <div className="icon-name-container">
            <div className="icon-container">{routes.icon}</div>
            <div className="route-name-container">{routes.name}</div>
          </div>
        </div>
        {/* {renderSubcategory()} */}
        {/* {!sidebarCollide ? renderSubcategory() : <></>} */}
      </div>
    );
  };

  const inActiveBar = (routes) => {
    return (
      <div className="inactive-nav-route-button">
        <div
          onClick={() => redirection(routes.path)}
          className="nav-button-radious"
        >
          <div className="icon-name-container">
            <div className="icon-container">{routes.icon}</div>
            <div className="route-name-container">{routes.name}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="siteHeader">
      <div className="laft-side-block">
        <IoIosArrowBack onClick={handleBack} />
        <div className="header-title">{getHeaderTitle()}</div>
      </div>

      {/* <div className="year-text">H1 2024 MBSR</div> */}
      <div className="year-text">
        <div className="sitelogo">
          <img
            src={employeeDetails?.company?.icon?.path ?? mediLogo}
            alt="MediBuddy"
          />
        </div>
        {annualcycleData?.cycleName ?? getHeaderMidTitle()}
      </div>

      <div
        style={{
          pointerEvents: isSettingDropDownOpen ? "none" : "auto",
        }}
        className="iconsBlock"
      >
        <img onClick={() => window.location.reload()} src={reloadIcon} alt="" />

        <img
          src={bellIcon}
          alt=""
          onClick={() => setIsNotificationDropDownOpen(true)}
        />
        {/* <img
                    style={{ cursor: "pointer" }}
                    onClick={() => settingHandler()}
                    src={settingsIcon}
                    alt=""
                /> */}
        <div
          className="icon-container"
          style={{ cursor: "pointer" }}
          onClick={() => settingHandler()}
        >
          {/* <div className="icon-size"> */}
          <div
            className={
              employeeDetails?.profilePhoto ? "icon-size" : "icon-size-avatar"
            }
          >
            {/* <img src={User} /> */}
            {employeeDetails?.profilePhoto ? (
              <img
                src={employeeDetails?.profilePhoto?.path}
                className="user"
                alt="User Image"
              />
            ) : (
              <Avatar
                name={employeeDetails.name}
                size="70"
                className="user avatar-style"
                color={Avatar.getRandomColor("sitebase", [
                  "orange",
                  "violet",
                  "maroon",
                  "teal",
                  "brown",
                ])}
              />
            )}
          </div>
        </div>

        {isSettingDropDownOpen ? (
          <div className="setting-dropdown-main-container" ref={dropdownRef}>
            <div>
              <div className="menu-list">
                {settingRouteList.map((routes) => {
                  if (routes?.name === "Profile" && !employeeDetails?.permissions?.includes("view-profile")) {
                    // Exclude route-item when route.name is "xyz"
                    return null;
                  }
                  // return (
                  //     // <div onClick={() => redirection(item.path)} className="menu">{item.name}</div>
                  //     <div className="inactive-nav-route-button">
                  //         <div
                  //         onClick={() => redirection(routes.path)}
                  //         className="nav-button-radious"
                  //         >
                  //         <div className="icon-name-container">
                  //             <div className="icon-container">{routes.icon}</div>
                  //             <div className="route-name-container">{routes.name}</div>
                  //         </div>
                  //         </div>
                  //     </div>
                  // )
                  return truncatePath(routes.path) ===
                    truncatePath(location.pathname)
                    ? activeBar(routes)
                    : inActiveBar(routes);
                })}
                {/* <div onClick={() => {
                                    dispatch(logInActions.clearLogInToken())
                                    }} className="logout-icon"
                                >Logout</div> */}
                <div className="inactive-nav-route-button">
                  <div
                    onClick={() => {
                      dispatch(logInActions.clearLogInToken());
                    }}
                    className="nav-button-radious"
                  >
                    <div className="icon-name-container">
                      <div className="icon-container">{<Logout />}</div>
                      <div className="route-name-container">Logout</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {
        openDialog && (
          <ChangePassPopup openDialog={openDialog}>
            <PopupChangePassword setOpenDialog={setOpenDialog} />
          </ChangePassPopup>
        )
        // true &&
        // <ChangePassPopup openDialog={true}>
        //   <PopupChangePassword setOpenDialog={setOpenDialog} />
        // </ChangePassPopup>
      }

      {isNotificationDropDownOpen && (
        <NotificationDrawer
          isOpen={isNotificationDropDownOpen}
          setIsOpen={setIsNotificationDropDownOpen}
        >
          <Notification />
        </NotificationDrawer>
      )}
    </div>
  );
};

export default Header;