import { Box, FormControl, InputLabel, LinearProgress, MenuItem, Popover, Select } from "@mui/material";
import scheduleImg from "../../../assets/images/ratingCalib/addSession/schedule.png";
import checkIcon from "../../../assets/images/popup-check.png";
import pie from "../../../assets/images/pielike.png";
import sched from "../../../assets/images/ratingCalib/addSession/schedule.png";
import Calendar from "../../common/calender";
import { Search, SearchIconWrapper, StyledInputBase, getFormattedDate } from "../../utils/utils";
import { IoIosArrowDown, IoMdSearch } from "react-icons/io";
import { useState } from "react";
import downloadIcon from "../../../assets/images/dashboard/download.png"
import Avatar from "react-avatar";
import { dummy } from "../requestFeedback/dummyData.js";
import TableSkel from "../../common/tableSkeleton/index.js";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [activeTab, setActiveTab] = useState("Overview");

    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClickAction = (event, emp) => {
        // setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChangeTab = (value) => {
         setActiveTab(value);
    };

    const chartData = {
        labels: ["Completed", "Remaining"],
        datasets: [
          {
            // label: "# of Votes",
            data: [500,202],
            backgroundColor: [
                "#00425A", 
                "#DFF6FF"
            ],
            borderColor: [
                "#00425A", 
                "#DFF6FF"
            ],
            borderWidth: 1,
          },
        ],
      };

      const chartOptions = {
        plugins: {
            legend: {
              position: 'right', // You can change this to 'top', 'left', 'right', or customize it based on your needs
            },
          },
      };

    return(
        <div className="dashboard-main">
            <div className="dash-top">
            <div className="search-wrapper">
            <div className="r-f-tabs">
            <div className={`tab ${activeTab === "Overview" && "active"}`} onClick={() => handleChangeTab("Overview")}>Overview</div>
            <div className={`tab ${activeTab === "Reports" && "active"}`} onClick={() => handleChangeTab("Reports")}>Reports <span>05</span></div>
            </div>
            
          <Search>
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ 'aria-label': 'search' }}
            //   onChange={autoSearchText}
            />
          </Search>
          </div>
            </div>
            {
                activeTab === "Overview" ?
                <div className="outer-layout">
                <div className="first-column">
                    <div className="first-row">
                        <div className="header-section">
                            <div className="heading">Feedback task Overview</div>
                            <div className="drop-block">
                            <img src={scheduleImg} alt="" />
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="relationship">
                                {/* Month */}
                                </InputLabel>
                                <Select
                                    value="December"
                                    labelId="month"
                                    name="month"
                                    // onChange={(event) =>
                                    //   handleChangeDependent(event, "dropdown")
                                    // }
                                    variant="outlined"
                                    // error={formError.relation.isError}
                                    // helperText={formError.relation.errorMessage}
                                    // label="Select relationship"
                                    // label="Month"
                                    size="small"
                                    sx={{
                                    width: "100%",
                                    // backgroundColor: "var(--graybg)",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiInputBase-input:focus": {
                                        border: "none", // focus
                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "0.75rem",
                                    },
                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                    },
                                    //   ...requiredSelectStyled,
                                    }}
                                >
                                        <MenuItem
                                        //   key={relation}
                                          value="December"
                                        //   sx={{ textTransform: "capitalize" }}
                                        >
                                        December
                                        </MenuItem>
                                    
                                </Select>
                                {/* {formError.relation.errorMessage && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {formError.relation.errorMessage}
                                    </FormHelperText>
                                )} */}
                                </FormControl>
                                
                            </div>
                        </div>
                        <div className="first-task-row">
                            <div className="block-1">
                                <img src={checkIcon} className="checkIcon"></img>
                                <div className="number">24</div>
                                <div className="text">Total feedback completed</div>
                            </div>
                            <div className="block">
                                <div className="number">28</div>
                                <div className="text">Total pending requests</div>
                            </div>
                            <div className="block">
                                <div className="number">14</div>
                                <div className="text">Total declined requests</div>
                            </div>
                            <div className="block">
                                <div className="number">28</div>
                                <div className="text">Total expired requests</div>
                            </div>
                        </div>
                        <div className="second-task-row">
                        <div className="block-1">
                                <img src={pie} className="checkIcon"></img>
                                <div className="number">90</div>
                                <div className="text">Total request submitted</div>
                            </div>
                            <div className="block">
                                <div className="number">24</div>
                                <div className="text">Team members</div>
                            </div>
                            <div className="block">
                                <div className="number">10</div>
                                <div className="text">Total team comments</div>
                            </div>
                            <div className="block">
                                <div className="number">12</div>
                                <div className="text">Requests are abbout to expire</div>
                            </div>
                        </div>
                    </div>
                    <div className="second-row">
                    <div className="header-section">
                        <div className="heading">Organisation update</div>
                    </div>
                    <div className="main-b">
                        <div className="org-update-row">
                            <img src="https://media.istockphoto.com/id/1175493551/photo/update-concept.jpg?s=2048x2048&w=is&k=20&c=pwU7CBTwmffg1qh4efxPvkFxGLjmRUnSRlyxZucNcx8=" alt="" />
                            <div className="update-info">
                                <div className="head">Introducing new product</div>
                                <div className="description">
                                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                                </div>
                            </div>
                        </div>
                        <div className="org-update-row">
                            <img src="https://media.istockphoto.com/id/1175493551/photo/update-concept.jpg?s=2048x2048&w=is&k=20&c=pwU7CBTwmffg1qh4efxPvkFxGLjmRUnSRlyxZucNcx8=" alt="" />
                            <div className="update-info">
                                <div className="head">Introducing new product</div>
                                <div className="description">
                                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="second-column">
                    <div className="for-calender">
                    <Calendar />
                    </div>
                    <div className="upcoming-events">
                        <div className="heading">Upcoming events</div>
                        <div className="card-u">
                            <div className="arrival">
                                <img src={sched} alt="" />
                                In 2 days
                            </div>
                            <div className="card-head">Research and Development</div>
                            <div className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </div>
                            <div className="card-footer"></div>
                        </div>
                        <div className="card-u">
                            <div className="arrival">
                                <img src={sched} alt="" />
                                In 2 days
                            </div>
                            <div className="card-head">Research and Development</div>
                            <div className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </div>
                            <div className="card-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="outer-layout-reports">
                <div className="first-row">
                    <div className="row-block apprecial">
                        <div className="top">
                            <div className="heading">
                                <div className="text">Self apprecial</div>
                            </div>
                            <div className="dropdn">
                            <img src={scheduleImg} alt="" />
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="relationship">
                                {/* Month */}
                                </InputLabel>
                                <Select
                                    value="December"
                                    labelId="month"
                                    name="month"
                                    // onChange={(event) =>
                                    //   handleChangeDependent(event, "dropdown")
                                    // }
                                    variant="outlined"
                                    // error={formError.relation.isError}
                                    // helperText={formError.relation.errorMessage}
                                    // label="Select relationship"
                                    // label="Month"
                                    size="small"
                                    sx={{
                                    width: "100%",
                                    // backgroundColor: "var(--graybg)",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiInputBase-input:focus": {
                                        border: "none", // focus
                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "0.75rem",
                                    },
                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                    },
                                    //   ...requiredSelectStyled,
                                    }}
                                >
                                        <MenuItem
                                        //   key={relation}
                                          value="December"
                                        //   sx={{ textTransform: "capitalize" }}
                                        >
                                        December
                                        </MenuItem>
                                    
                                </Select>
                                {/* {formError.relation.errorMessage && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {formError.relation.errorMessage}
                                    </FormHelperText>
                                )} */}
                                </FormControl>
                            </div>
                        
                        </div>
                        <div className="middle">
                        <Doughnut data={chartData} options={chartOptions} />
                        </div>
                        <div className="bottom">
                            
                        <button>Complete your appraecial</button>
                        </div>
                    </div>
                    <div className="row-block team">
                        <div className="top">
                            <div className="heading">
                                <div className="text">My Team</div>
                                <div className="subtext">20 Team members</div>
                            </div>
                            <div className="dropdn">
                            <img src={scheduleImg} alt="" />
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="relationship">
                                {/* Month */}
                                </InputLabel>
                                <Select
                                    value="December"
                                    labelId="month"
                                    name="month"
                                    // onChange={(event) =>
                                    //   handleChangeDependent(event, "dropdown")
                                    // }
                                    variant="outlined"
                                    // error={formError.relation.isError}
                                    // helperText={formError.relation.errorMessage}
                                    // label="Select relationship"
                                    // label="Month"
                                    size="small"
                                    sx={{
                                    width: "100%",
                                    // backgroundColor: "var(--graybg)",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiInputBase-input:focus": {
                                        border: "none", // focus
                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "0.75rem",
                                    },
                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                    },
                                    //   ...requiredSelectStyled,
                                    }}
                                >
                                        <MenuItem
                                        //   key={relation}
                                          value="December"
                                        //   sx={{ textTransform: "capitalize" }}
                                        >
                                        December
                                        </MenuItem>
                                    
                                </Select>
                                {/* {formError.relation.errorMessage && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {formError.relation.errorMessage}
                                    </FormHelperText>
                                )} */}
                                </FormControl>
                            </div>
                        </div>
                        <div className="middle">
                        <div className="avatars">
                            <a href="#" className="avatars__item"><img className="avatar" src="https://randomuser.me/api/portraits/women/65.jpg" alt="" /></a>
                            <a href="#" className="avatars__item"><img className="avatar" src="https://randomuser.me/api/portraits/men/25.jpg" alt="" /></a>
                            <a href="#" className="avatars__item"><img className="avatar" src="https://randomuser.me/api/portraits/women/25.jpg" alt="" /></a>
                            <a href="#" className="avatars__item"><img className="avatar" src="https://randomuser.me/api/portraits/men/55.jpg" alt="" /></a>
                            <div className="avatars__item">10+</div>
                        </div>
                        <div className="req-submitted">
                            <div className="title">Request submitted : 15/30</div>
                            <div className="progress">
                                <div className="act-pro" style={{width:"50%"}}></div>
                            </div>
                        </div>
                        <div className="req-inprogress">
                        <div className="title">Request in progress : 3/5</div>
                            <div className="progress">
                                <div className="act-pro" style={{width:"70.45%"}}></div>
                            </div>
                        </div>
                        </div>
                        <div className="bottom">
                            <button>Download report <img src={downloadIcon} alt="" /></button>
                        </div>
                    </div>
                    <div className="row-block comments">
                    <div className="top">
                        <div className="heading">
                                <div className="text">Comments</div>
                                <div className="subtext">02 New comments</div>
                        </div>
                    </div>
                    <div className="emp-comments">
                    <div className="empDetaila">
                        <div className="empPic">
                        {false ? 
                            <img src="https://randomuser.me/api/portraits/women/65.jpg" className="userImg" alt="User Image" />
                            :
                            <Avatar 
                                name={"moye more"} 
                                size="45" 
                                className="userImg"
                                color={"#00425A"}    
                            />
                        }
                        </div>
                        <div className="userInfo">
                            <p className="name">Moye More <span>Comment on - </span> Akash</p>
                            <p className="designation">02 minutes ago</p>
                        </div>
                    </div>
                    <div className="desc">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                    </div>
                    </div>
                    <div className="emp-comments">
                    <div className="empDetaila">
                        <div className="empPic">
                        {false ? 
                            <img src="https://randomuser.me/api/portraits/women/65.jpg" className="userImg" alt="User Image" />
                            :
                            <Avatar 
                                name={"moye more"} 
                                size="45" 
                                className="userImg"
                                color={"#00425A"}    
                            />
                        }
                        </div>
                        <div className="userInfo">
                            <p className="name">Moye More <span>Comment on - </span> Akash</p>
                            <p className="designation">02 minutes ago</p>
                        </div>
                    </div>
                    <div className="desc">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                    </div>
                    </div>
                    </div>
                </div>
                <div className="second-row">
                    <div className="top-row">
                        <div className="left">
                        <div className="heading">
                                <div className="text">Feedback Request (32)</div>
                                <div className="subtext">4 new request</div>
                            </div>
                            
                        </div>
                        <div className="right">
                        <div className="dropdn">
                            <img src={scheduleImg} alt="" />
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="relationship">
                                {/* Month */}
                                </InputLabel>
                                <Select
                                    value="December"
                                    labelId="month"
                                    name="month"
                                    // onChange={(event) =>
                                    //   handleChangeDependent(event, "dropdown")
                                    // }
                                    variant="outlined"
                                    // error={formError.relation.isError}
                                    // helperText={formError.relation.errorMessage}
                                    // label="Select relationship"
                                    // label="Month"
                                    size="small"
                                    sx={{
                                    width: "100%",
                                    // backgroundColor: "var(--graybg)",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    "& .MuiInputBase-input:focus": {
                                        border: "none", // focus
                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "0.75rem",
                                    },
                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                    },
                                    //   ...requiredSelectStyled,
                                    }}
                                >
                                        <MenuItem
                                        //   key={relation}
                                          value="December"
                                        //   sx={{ textTransform: "capitalize" }}
                                        >
                                        December
                                        </MenuItem>
                                    
                                </Select>
                                {/* {formError.relation.errorMessage && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {formError.relation.errorMessage}
                                    </FormHelperText>
                                )} */}
                                </FormControl>
                        </div>
                        <button>Download report <img src={downloadIcon} alt="" /></button>
                        </div>
                    </div>
                    <div className="bottom-row">
                    <div className="tablee">
            <table class="feedback-emp-list-table"> 

                <tr className="table-head-emp-list"> 
                    <th>Name 
                        {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                    </th> 
                    <th className="mid-cols">
                     Request Date
                        {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="mid-cols">Expiry Date</th> 
                    <th className="mid-cols">
                    Relationship
                        {/* &nbsp;<BiSort onClick={() => handleSort("feedbackstatus")} style={{cursor:"pointer"}} /> */}
                        </th> 
                    <th className="last-col head">Status</th> 
                    {/* <th></th>  */}
                </tr>
                {
                     false ? 
                     <TableSkel />
                     :
                dummy?.data?.map((emp,index) => {
                        return(   
                            // activeTab === "pending" ?
                                <tr> 
                                    <td>
                                        <div className="empDetaila">
                                            <div className="empPic">
                                            {emp?.profilePhotoPath ? 
                                                <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                :
                                                <Avatar 
                                                    name={emp.employeeName} 
                                                    size="45" 
                                                    className="userImg"
                                                    color={"#00425A"}    
                                                />
                                            }
                                            </div>
                                            <div className="userInfo">
                                                <p className="name">{emp?.employeeName}</p>
                                                <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                            </div>
                                        </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{getFormattedDate(Date.now(), dateFormat)}</div>
                                        <div>{getFormattedDate(Date.now(), "hh:mm")}</div>
                                    </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                        <div>{getFormattedDate(Date.now(), dateFormat)}</div>
                                        <div>{getFormattedDate(Date.now(), "hh:mm")}</div>
                                    </div>
                                    </td> 
                                    <td>
                                    <div className= "btns">
                                    
                                    {emp?.relationship}
                                    </div>
                                    </td> 
                                    <td className="last-col">                                    
                                    <div className= "btnss" onClick={(event) => handleClickAction(event, emp)}>
                                    <button className="send-btn">
                                        Action <IoIosArrowDown />
                                    </button>                                    
                                    </div>    
                                    <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                sx={{
                                                    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    },
                                                    "& .MuiPopover-paper" : {
                                                        boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                        border:"1px solid  #e9e9e9",
                                                        // padding:"0.25rem",
                                                        fontFamily:"poppins",
                                                        fontSize:"0.75rem",
                                                        borderRadius: "10px",
                                                        padding: "5px 0px",
                                                        cursor:"pointer",
                                                        marginLeft:"-1rem"                                                        
                                                    }
                                                }}
                                            >
                                                <div className="popover-btns" >Give Feedback</div>
                                                <div className="popover-btns red">Decline</div>
                                            </Popover>
                                    </td> 
                               
                                </tr>
                           
                                    
                            
                        )
                    }
                   
                // }
)
                }
                </table>
                    </div>
                </div>
            </div>
            </div>
            }
           
        </div>
    )
}

export default Dashboard;