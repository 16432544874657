import loginBg from "../../../assets/loginBg.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions as logInActions } from "../../../redux/login/actions";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonM from "../Button";
import ReactDOM from 'react-dom';
import Popout from 'react-popout-v2';
import { isEmptyNullUndefined } from "../../utils/utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginBg from "../../../assets/images/loginImg.png"
import mediLogo from "../../../assets/images/mdiLogo/loginLogo.png"

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameText, setUsernameText] = useState(true);
  const [loginPopup, setLoginPopup] = useState(false);

  // const user = useSelector((state) => state && state.user && state.user.user);
  const userToken = useSelector((state) => state && state.user && state.user.userToken);
  const loading = useSelector((state) => state && state.user && state.user.loader);

  const prodEnv = "https://medibuddy.benevolve.com/login";

  useEffect(() => {
   if(localStorage.getItem("loggedOutFromFooter")){ 
    localStorage.removeItem("loggedOutFromFooter");
    window.location.reload();
   }
  }, []);

  // logging in through admin portal 
 useEffect(() => {
    if (params?.xyz?.length > 0) {
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: params?.xyz
      }))
    }
  }, [params]);

 useEffect(() => {
    // if (userToken && Object.keys(userToken).length > 0) {      
    if (userToken?.id_token !== undefined) {      
      // dispatch(logInActions.UserRequest());
      history.push('/profile');
    }
  }, [userToken]);

  // useEffect(() => {
  //   if ((user && Object.keys(user).length > 0) && (user && !user.loader)) {
  //     history.push('/home');
  //   }
  // }, [user]);

  useEffect(() => {
    // console.log("in use call back out");

      if(isEmptyNullUndefined(userToken)){
        // console.log("in use call back if");
        setIsToken("no");
      }
  }, [submit]);
  
  const checkValidation = () => {
    setUsernameText(false);
    if (username && password) {
      dispatch(logInActions.LogInRequest({
        username: username,
        password: password
      }));
    } 
      setSubmit(true);
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      checkValidation();
    }
  }

 window.addEventListener('message', function(e){
    // console.log("addEventListener", e.data);
    if(e?.data?.id_token){
      dispatch(logInActions.LogInRequest({
        username: "logInThroughPopupWindow",
        password: e?.data?.id_token
      }))
    }
  })  

  const handleLoginClick = () => {
    const loginWindow = window.open('https://niumin.benevolve.com/saml/login?disco=true&idp=http://www.okta.com/exk65vckf83YbwDl1697', 'Login', 'width=500,height=600');
  };

  return (
    <div className="login-main">
      <div className="row" style={{maxWidth:"1200px", margin:"auto"}}>
        <div className="col-7 left">
          <img src={LoginBg} style={{width: '100%'}} className="loginBg" ></img>
        </div>
        <div className="col-5 right">
          <div className="loginBlock">
            <div className="loginBlockInner">
              <img className="logo-img" src={mediLogo} alt="logo" />
              <div className="loginHeadingOuter">
                <p className="loginHeading">Login</p>
              </div>
              {
                window?.location?.href !== prodEnv && 
                  <div className="input-wrapper">
                <div className="name-ip">
                  <input
                    name="email"
                    onChange={(event) => {setUsername(event.target.value); setIsToken(""); setSubmit(false);}}
                    className={(submit && (username.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    type="text"
                    placeholder="Username"                
                  />
                  {
                    usernameText &&
                    <p className="helping-text" style={{ lineHeight: '0px'}}>
                      Your username is your company e-mail id.
                    </p>
                  }
                  {submit && (username.trim() === "" || (!loading && isToken == "no")) && (
                    <p className="error-text">
                      Your username is your company e-mail id.<br/>
                      *Please provide a valid Username.
                    </p>
                  )}
                </div>
                  </div>
              }

              {
                window?.location?.href !== prodEnv && 
                  <div className="password-wrap">
                <div className="password-ip">
                  <div className="password-outer">
                  <input
                    name="password"
                    onChange={(event) => {setPassword(event.target.value); setIsToken(""); setSubmit(false);}}
                    className={(submit && (password.trim() === "" || (!loading && isToken == "no"))) ? "input border border-red-500 focus:outline-none" : "input focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"}
                    type = {showPassword ? "text" : "password"}
                    placeholder="Password"
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <span class="eyeIcon" onClick={() => setShowPassword(!showPassword)}>
                    {
                      showPassword ?
                        <FaEye />
                        :
                        <FaEyeSlash />
                    }               
                  </span>
                  </div>
                  {submit && (password.trim() === "" || (!loading && isToken == "no")) && (
                    <p className="error-text">
                      *Please provide a valid Password.
                    </p>
                  )}
                </div>
                  </div>
              }
              
              {
                window?.location?.href !== prodEnv && 
                  <div className="d-flex justify-content-between pt-4 pb-2">
                    <div class="form-check checkbox">
                      <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                      <label class="form-check-label">Remember Me</label>
                    </div>
                  <div className='forgot-pass w-full text-right'>
                    <Link to='/forgot-password' className='link'>
                    Forgot Password ?
                    </Link>
                  </div>
                  </div>
              }

              {
                window?.location?.href !== prodEnv && 
                  <div className="flex flex-wrap pb-2">
                    <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                      <ButtonM text='Login' onpress={() => checkValidation()} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                    </div>
                  </div>
              }     

              {
                window?.location?.href !== prodEnv && 
                  <div className="or-text">-------  OR  -------</div>
              }
              
              
              <div className="flex flex-wrap pb-2">
                <div className="w-full loginBtnOuter" id="login_btn_for_employee">
                  <ButtonM text='Login Via Company' onpress={() => setLoginPopup(true)} loading={loading} disabled={loading} bgcolor="#00425A" color="#ffffff" bghover="#2e8004" />       
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
      {
        loginPopup &&
        <Popout url='https://medibuddy.benevolve.com/sso/idpselection' title='SSO Login'  reactDom={ReactDOM}
        // onClosing={() => setLoginPopup(false)}
        onClose={() => setLoginPopup(false)
        }>
        </Popout>
      }
      
    </div>
  );
};

export default LogIn;
