import React from 'react';

const PaginationMedi = ({ currentPage, totalPages, onPageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 5;
      const halfMaxPages = Math.floor(maxPagesToShow / 2);
  
      let startPage = Math.max(1, currentPage - halfMaxPages);
      let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
      if (currentPage <= halfMaxPages) {
        endPage = maxPagesToShow;
      } else if (currentPage >= totalPages - halfMaxPages) {
        startPage = totalPages - maxPagesToShow + 1;
      }

      if (Number(totalPages) < 5) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
            //   <li key={i} className={currentPage === i ? 'active' : ''}>
                <button key={i} className={currentPage === (i - 1) ? 'active' : ''} onClick={() => onPageChange(i-1)}>{i}</button>
            //   </li>
            )}

      } else {
        for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
        //   <li key={i} className={currentPage === i ? 'active' : ''}>
            <button key={i} className={currentPage === i-1 ? 'active' : ''} onClick={() => onPageChange(i-1)}>{i}</button>
        //   </li>
        );
      }
      }
  
      
  
      return pageNumbers;
    };
  
    return (
      <div className="pagination">
        {
            currentPage !== 0 && 
            <button
            disabled={currentPage === 0}
            onClick={() => onPageChange(currentPage - 1)}
            className="arrow"
            >
            &lt;
            </button>
        }
        
        {/* <ul> */}
            {renderPageNumbers()}
            {/* </ul> */}
            {
                currentPage !== totalPages - 1 && 
                <button
                disabled={currentPage === totalPages- 1}
                onClick={() => onPageChange(currentPage + 1)}
                className="arrow"
                >
                &gt;
                </button>
            }
        
      </div>
    );
  };
  
export default PaginationMedi;
