import Dashboard from "../../../../assets/images/dynamicSidebar/home";
import Profile from "../../../../assets/images/dynamicSidebar/profile";
import SelfAssessment from "../../../../assets/images/dynamicSidebar/performance";
import Givefeedback from "../../../../assets/images/dynamicSidebar/giveFeedback";
import RatingCollab from "../../../../assets/images/dynamicSidebar/msbr";
import MyTeam from "../../../../assets/images/dynamicSidebar/myteam";
import Feedback from "../../../../assets/images/dynamicSidebar/feedback";
import FunctionalLeader from "../../../../assets/images/dynamicSidebar/functionalLeader";
import ManagerCalibration from "../../../../assets/images/dynamicSidebar/functionalLeader";
import Comp from "../../../../assets/images/dynamicSidebar/compIcon";

export const allRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        icon: <Dashboard />,
      },
      {
        path: "/profile",
        name: "My Profile",
        icon: <Profile />,
      },
      {
        path: "/self-assesment/allinone",
        name: "My Assessment",
        icon: <SelfAssessment />,
      },
      {
        path: "cat-head",
        name: "Feedback",
        icon: <Feedback />,
        subcategory: [          
          {
            path: "/self-assesment/request-feedback",
            name: "Request Feedback",
            icon: <SelfAssessment />,
          },
          {
            path: "/self-assesment/aprove",
            name: "Approve",
            icon: <SelfAssessment />,
          },
          {
            path: "/give-peer-feedback",
            name: "Give Feedback",
            icon: <Givefeedback />,
          } ,
        ]
      } ,     
      
      
      // {
      //   path: "/feedback",
      //   name: "My Team",
      //   icon: <MyTeam />,
      // },

      {
        path: "/my-team",
        name: "My Team",
        icon: <MyTeam />,
      },
      
      {
        path: "/hrb",
        name: "Rating Calibration",
        icon: <RatingCollab />,
      },
      {
        path: "/manager-calibration",
        name: "Manager Calibration",
        icon: <ManagerCalibration />,
      },
      {
        path: "/functional-leader",
        name: "Functional Leader",
        icon: <FunctionalLeader />,
      },
      {
        path: "/employee-profile",
        name: "EEP",
        icon: <FunctionalLeader />,
      },
      
      {
        path: "cat-head-comp",
        name: "Compensation",
        icon: <Comp />,
        subcategory: [          
          {
            path: "/compensation",
            name: "Planning",
            icon: <Comp />,
          },
          {
            path: "/compensation-team",
            name: "My Team",
            icon: <Comp />,
          },
    
          {
            path: "/Compensation-analytics",
            name: "Analytics",
            icon: <Comp />,
          },
        ]
      },   
     
];

export const prodRoutes = [   
     {
        path: "/profile",
        name: "My Profile",
        icon: <Profile />,
      },  
      {
        path: "/feedback",
        name: "My Team",
        icon: <MyTeam />,
      },
     
]