import { all, fork, select, takeEvery } from "redux-saga/effects";
import logInSagas from "../redux/login/sagas";
import empDataSagas from "../redux/employeeData/sagas";
import selfApraisalDataSagas from "../redux/selfApraisal/sagas";
// import queSagas from "../redux/questions/sagas";
import dropdownSagas from "../redux/dropdown/sagas";
import countsSagas from "../redux/countts/sagas";
import pmscycleSagas from "../redux/pmsCycle/sagas";
import annualcycleSagas from "../redux/annualCycle/sagas";
import notificationSagas from "../redux/notification/sagas";

function* watchAndLog() {
  yield takeEvery("*", function* logger(action) {
    const state = yield select();
    console.debug("action", action);
    console.debug("state after", state);
  });
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(logInSagas),
    fork(empDataSagas),
    fork(selfApraisalDataSagas),
    // fork(queSagas),
    fork(dropdownSagas),
    fork(countsSagas),
    fork(pmscycleSagas),
    fork(annualcycleSagas),
    fork(notificationSagas),
  ]);
};
