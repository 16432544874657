import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../api/index';
import { actions, types } from './actions';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
// import { actions as logInActions } from "../login/actions";

const getCountsRequest = function* getCountsRequest({ payload }) {
    try {
        const { data } = yield call(api.getAllCounts, payload);
        if (data) {
            yield put(actions.getCountsRequestSuccess(data));
        } else {
            yield put(actions.getCountsRequestFailed());
        }
    } catch (err) {
        toast.error(<div className='flex flex-row'><RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />&nbsp;&nbsp;{ err.title }</div>);
        yield put(actions.getCountsRequestFailed());      
    }
};

export default function* sagas() {
    yield takeLatest(types.GET_COUNTS_REQUEST, getCountsRequest);
}
