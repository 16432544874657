import { IoMdSearch } from "react-icons/io";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import { InputBase, Skeleton, alpha, styled } from "@mui/material";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import APIList from "../../../../../api";
import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";

 const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "2.7rem !important",
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
      width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 

const SearchBar = ({placeHoldText, setSelected}) => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [searchedList, setsearchedList] = useState(null);
    const [showAllSearches, setShowAllSearches] = useState(false);
    const [totalSearchPages, setTotalSearchPages] = useState(0);

    function HandleOutsideClick(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (
              ref.current &&
              !ref.current.contains(event.target)
            ) {
               
                    // setIsSearch(false);                    
                    setSearch("");
                               
            }
          }
    
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      const wrapperRef = useRef(null);
      HandleOutsideClick(wrapperRef);

    // start for pop-over
    const [anchorEl, setAnchorEl] = useState(null);    
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    

    useEffect(() => {
        // setFilterPayload({});         
        setSearchLoader(true);
        const getSearch = setTimeout(() => {
            
            if(!isEmptyNullUndefined(search)){
                if(placeHoldText === "Request feedback for") {
                //   APIList.getImmediateTeam({
                //     payload : {
                //             employeeEligibilityDTO: {},
                //             keyword:search,
                //             employeeId: employeeDetails?.id,
                //             companyId: employeeDetails?.company?.id,
                //             managerId: employeeDetails?.manager?.id,
                //     },
                //     page: 0,
                //     size: showAllSearches ? 10000 : 3
                // })       
                APIList.getFilteredSearchPeers({
                  payload : {
                      employeeEligibilityDTO : {},
                      keyword : search,                            
                      employeeId: employeeDetails?.id,
                      managerId: employeeDetails?.manager?.id,
                      companyId : employeeDetails?.company?.id
                  },
                  page: 0,
                  size: showAllSearches ? 10000 : 3
              }) 
                .then((res) => {
                  setsearchedList(res?.data?.data);
                  // setTotalPages(res?.data?.totalPages);
                  setTotalSearchPages(res?.data?.totalPages);
                  setSearchLoader(false);
                  // console.log(res)
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSearchLoader(false);
                })
                } else {
                    APIList.postEmployeesSearch({
                        payload : {
                            employeeEligibilityDTO : {},
                            keyword : search,                            
                            employeeId: employeeDetails?.id,
                            managerId: employeeDetails?.manager?.id,
                            companyId : employeeDetails?.company?.id
                        },
                        page: 0,
                        size: showAllSearches ? 10000 : 3
                    })  
                    .then((res) => {
                        setsearchedList(res?.data?.data);
                        // setTotalPages(res?.data?.totalPages);
                        setTotalSearchPages(res?.data?.totalPages);
                        setSearchLoader(false);
                        // console.log(res)
                    })
                    .catch((err) => {
                        // console.log(err);
                        toast.error(
                          <div style={{display:"flex", flexDirection:"row"}}>
                          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                          &nbsp;&nbsp;{err?.title}
                          </div>
                      );
                        setSearchLoader(false);
                    }
                    )
                }
                    
            }
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [search, showAllSearches])

    const autoSearchText = (e) => {
        setShowAllSearches(false);
        setSearch(e.target.value);
      }

    const handleSelect = (emp) => {
        setSelected(emp);
    }  

    return(
        <div className="initiate-search-div">
        <Search  
        ref={wrapperRef}
        >
          <SearchIconWrapper>
            <IoMdSearch />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={placeHoldText}
            inputProps={{ 'aria-label': 'search' }}
            onChange={autoSearchText}
            value={search}
          />
          {
              !isEmptyNullUndefined(search) && 
              <IoCloseSharp onClick={() => {setSearch(""); setShowAllSearches(false)}} style={{position:"absolute", right:"10px", top:"34%", color:"gray", cursor:"pointer"}} />
          }

          {
              !isEmptyNullUndefined(search) && 
              <div className="search-popup-req-feed">
              {
                  searchLoader ? 
                  <>                   
                  <div className="search-emp-outer">
                      <div className="empDetaila">
                          <div className="empPic">
                          <Skeleton variant="circular" width={40} height={40} />
                          </div>
                          <div className="userInfo">
                              <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                              <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                          </div>
                      </div>
                      <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                  </div>
                  <div className="search-emp-outer">
                      <div className="empDetaila">
                          <div className="empPic">
                          <Skeleton variant="circular" width={40} height={40} />
                          </div>
                          <div className="userInfo">
                              <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                              <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                          </div>
                      </div>
                      <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                  </div>
                  </>
                  :
                  searchedList?.length > 0 ?
                  <div>
                 { 
                 searchedList?.map((emp, index) => {
                      return(
                          <div key={index} className="search-emp-outer" onClick={() => handleSelect(emp)}>
                              <div className="empDetaila">
                                  <div className="empPic">
                                      {emp?.profilePhotoPath ?
                                          <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                          :
                                          <Avatar
                                              name={emp?.employeeName}
                                              size="40"
                                              className="userImg"
                                              color={"#00425A"}
                                          />
                                      }
                                  </div>
                                  <div className="userInfo">
                                      <p className="name">{emp?.employeeName}</p>
                                      <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                  </div>
                              </div>
                              {/* <button className="search-sednd-btn" onClick={() => handleSendRequest(emp)}>
                                  Send
                              </button> */}
                          </div>
                      )                        
                  })
                  }
                  {
                      !showAllSearches && (totalSearchPages > 1) && <div className="view-all-search" onClick={() => setShowAllSearches(true)}>View all</div>
                  }                    
                  </div>
                  :
                  <div className="no-result">No result</div>
              }
              </div>
          }
          
        </Search>
        </div>
    )
}

export default SearchBar;