import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { useEffect, useState } from 'react';
  import { Bar } from 'react-chartjs-2';
import { isEmptyNullUndefined } from '../../utils/utils';
import { Collapse } from '@mui/material';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    //   scales: {
    //     y: {
    //       beginAtZero: true
    //     }
    //   }
    //   title: {
    //     display: true,
    //     text: 'Chart.js Bar Chart',
    //   },
    },
    scales: {
        y: {
          beginAtZero: true,
          suggestedMax: 100,
          ticks: {
            // stepSize: 20, // Adjust this based on your preference for the scale intervals
            stepSize: 10,
          },
        },
    }

  };



const BarGraph = ({data}) => {

    let barBackgroundColor = {
        0 : 'rgba(138, 154, 26, 0.8)',
        1 : 'rgba(71, 22, 137, 0.8)',
        2 : 'rgba(71, 22, 27, 0.88)',
        3 : 'rgba(248, 0, 0, 0.4)',
        4 : 'rgba(455, 99, 99, 0.5)',
        5 : 'rgba(555, 99, 132, 0.5)',
        6 : 'rgba(655, 99, 132, 0.5)',
        7 : 'rgba(755, 99, 132, 0.5)',
        8 : 'rgba(855, 99, 132, 0.5)',
        9 : 'rgba(955, 99, 132, 0.5)',
        10 : 'rgba(452, 99, 132, 0.5)',
    }

    const tempgraphdata = {
        labels: [],
        datasets: []
    }

    useEffect(() => {
        let tempResData = []
        if(isEmptyNullUndefined(data)) {

        } else {
            tempResData = JSON.parse(JSON.stringify(data))
        }
        let selectedColor = 0

        tempResData.forEach((preCalibratedRatingsE, preCalibratedRatingsI) => {
            if(tempgraphdata.labels.includes(preCalibratedRatingsE.mbbRating)) {

                let selectedLablesIndex = tempgraphdata.labels.findIndex(labelsElement => labelsElement === preCalibratedRatingsE.mbbRating)

                let datasetsIndex = tempgraphdata.datasets.findIndex(datasetsElement => datasetsElement.label ===  preCalibratedRatingsE.preformanceRating)

                if(datasetsIndex == -1) {
                    let tempdatasetsElement = {
                        label: preCalibratedRatingsE.preformanceRating,
                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        backgroundColor: barBackgroundColor[selectedColor],
                        data: [],
                    }
                    tempdatasetsElement.data.push(preCalibratedRatingsE.employees.length)

                    tempgraphdata.datasets.push(tempdatasetsElement)

                    selectedColor++

                } else {
                    // need to check 
                    tempgraphdata.datasets[datasetsIndex].data.push(preCalibratedRatingsE.employees.length)
                }



            } else {
                tempgraphdata.labels.push(preCalibratedRatingsE.mbbRating)

                let selectedLablesIndex = tempgraphdata.labels.findIndex(labelsElement => labelsElement === preCalibratedRatingsE.mbbRating)

                let datasetsIndex = tempgraphdata.datasets.findIndex(datasetsElement => datasetsElement.label ===  preCalibratedRatingsE.preformanceRating)

                if(datasetsIndex == -1) {

                    let tempdatasetsElement = {
                        label: preCalibratedRatingsE.preformanceRating,
                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        backgroundColor: barBackgroundColor[selectedColor],
                        data: [],
                    }

                    tempdatasetsElement.data.push(preCalibratedRatingsE.employees.length)

                    tempgraphdata.datasets.push(tempdatasetsElement)

                    selectedColor++

                } else {
                    //// not check yet
                    tempgraphdata.datasets[datasetsIndex].data.push(preCalibratedRatingsE.employees.length)
                }
            }
        })

        setGraphdata(() => tempgraphdata)

    },[])

    const [graphdata, setGraphdata] = useState({
        labels: [],
        datasets: []
    })

    const [showGraphTab, setShowGraphTab] = useState("Hide Graph")

  

    return(
        <div>
            <div style={{justifyContent: 'flex-end'}} className="tabs-outer">
                <div className="tabs">
                    <div className={`tab ${showGraphTab === "Show Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Show Graph")}>Show Graph</div>
                    <div className={`tab ${showGraphTab === "Hide Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Hide Graph")}>Hide Graph</div>
                </div>
            </div> 
            <div>
                <Collapse in={showGraphTab === "Show Graph" ? true : false}>
                    <Bar options={options} data={graphdata} />
                </Collapse>
            </div> 
            {/* <div><Bar options={options} data={graphdata} /></div>  */}
        </div>
    )
}

export default BarGraph;