import React, { useEffect, useState } from "react";
import APIList from "../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const InfoContent = ({ pageName }) => {

  const employeeDetails = useSelector((state) => state?.empData?.empData); 

  const [infoData, setInfoData] = useState(null);

  useEffect(() => {
    getInfoContent(employeeDetails?.company?.id , pageName);
  }, []);

  const getInfoContent = (companyId,pageName) => {
    APIList.getInfoDataByPageNameAndCompanyId(companyId,pageName)
      .then((res) => {
        setInfoData(res.data);
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
      });
  };

  return (
    <div>
      <span className="custom-html-content">
        <div dangerouslySetInnerHTML={{ __html: infoData?.description }} />
      </span>
    </div>
  );
};

export default InfoContent;
