import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { isEmptyNullUndefined } from '../../utils/utils';
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useSelector } from 'react-redux';

const DateTimePicker = ({label, changeDate, value, error, disabled, maxDate}) => {

  const location = useLocation();
  
  const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

  // State for date and time
  const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState('12:00');
// console.log("selectedDate",selectedDate);

useEffect(() => {
 if(!isEmptyNullUndefined(value)){
    setSelectedDate(value);
 }
}, [])


  // Handler for date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    changeDate(date);
  };

  // Handler for time change
//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//   };

  return (
    <div className={`custom-date-time-picker ${error ? "error" : ""}`}>
      {/* <h2>Date and Time Picker</h2> */}
      
      {/* Date picker */}
      <div className='heading'>{label}</div>
      <DatePicker
        // value={!isEmptyNullUndefined(location?.state?.id) && moment(selectedDate).format("DD/MM/YYYY hh:mm a")} 
        // selected={isEmptyNullUndefined(location?.state?.id) && selectedDate }
        // value={selectedDate ? selectedDate : undefined}
        selected={selectedDate ? moment(selectedDate).toDate() : null}
        onChange={handleDateChange}
        minDate={new Date()}
        maxDate={maxDate}
        placeholderText='Select Date And Time'
        showTimeSelect
        dateFormat= {`${dateFormat} hh:mm a`}
        disabled={disabled}
      />
      
      {/* Time picker */}
      {/* <TimePicker
        value={selectedTime}
        onChange={handleTimeChange}
      /> */}
      
      {/* Display selected date and time */}
      {/* <div>
        Selected Date: {selectedDate.toLocaleDateString()}<br />
        Selected Time: {selectedTime}
      </div> */}
    </div>
  );
};

export default DateTimePicker;