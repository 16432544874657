import { FormHelperText, InputBase, Skeleton, TextField, alpha, styled } from "@mui/material";
import { isEmptyNullUndefined } from "../../../../../utils/utils";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import Avatar from "react-avatar";
import { useEffect, useRef, useState } from "react";
import APIList from "../../../../../../api";
import { useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 


const HostSession = ({handleChangeSessionInfo, sessionPayload, sessionPayloadErr}) => {
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    
    const [hostSearch, setHostSearch] = useState("");
    const [searchedList, setsearchedList] = useState(null);
    const [searchLoader, setSearchLoader] = useState(false);
    const [showAllSearches, setShowAllSearches] = useState(false);
    const [totalSearchPages, setTotalSearchPages] = useState(0);

    function HandleOutsideClick(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (
              ref.current &&
              !ref.current.contains(event.target)
            ) {
                               
                    setHostSearch("");
                               
            }
          }
    
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      const wrapperRef = useRef(null);
      HandleOutsideClick(wrapperRef);

    useEffect(() => {
            setSearchLoader(true);
        const getSearch = setTimeout(() => {
                if(!isEmptyNullUndefined(hostSearch)){
                APIList.getCohostSearchPeers({
                    payload : {
                        employeeEligibilityDTO : {},
                        keyword : hostSearch,
                        employeeId: employeeDetails?.id,
                        managerId: employeeDetails?.manager?.id,
                        companyId : employeeDetails?.company?.id
                    },
                    page: 0,
                    size: showAllSearches ? 10000 : 3
                })  
                .then((res) => {
                    setsearchedList(res?.data?.data);
                    setTotalSearchPages(res?.data?.totalPages);
                    setSearchLoader(false);
                })
                .catch((err) => {
                    setSearchLoader(false);
                }
                )
            // }         
            } else {
                setsearchedList(null);
                setSearchLoader(false);
            }
              
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [hostSearch, showAllSearches])

      const autoSearchTextHost = (e) => {
        setShowAllSearches(false);
        setHostSearch(e.target.value);
      }

    return(
        <div className="host-session">
                                    <div className="schedule-form-outer-block">
                                        <div className="session-name-ip">
                                <TextField
                                    // size="small"
                                    name="name"
                                    label="Session Name"
                                    id="outlined-basic"
                                    onChange={(event) => handleChangeSessionInfo(event, null)}
                                    variant="outlined"
                                    error={sessionPayloadErr?.name?.isError}
                                    // helperText={sessionPayloadErr?.name?.errMsg}
                                    value={sessionPayload?.name ? sessionPayload?.name : ""}
                                    sx={{
                                        // my: 2,
                                        width: "100%",
                                        backgroundColor: "var(--graybg)",
                                        "& fieldset": {
                                            border:  sessionPayloadErr?.name?.isError ? "1px solid red" : "none",
                                        },
                                        "& .MuiInputBase-input:focus": {
                                          border: "none", // focus
                                        },
                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                        },
                                    }}
                                    />
                                    {sessionPayloadErr?.name?.isError && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                    {sessionPayloadErr?.name?.errMsg}
                                    </FormHelperText>
                                    )}
                                    </div>
                      
                        <div className="search-wrapper"  ref={wrapperRef}>
                                   { !isEmptyNullUndefined(sessionPayload?.coHost) ?
                        <div className="search-added-emp-btn">
                            <div className="empDetaila">
                                <div className="empPic">
                                    {sessionPayload?.coHost?.profilePhotoPath ?
                                        <img src={sessionPayload?.coHost?.profilePhotoPath} className="userImg" alt="User Image" />
                                        :
                                        <Avatar
                                            name={sessionPayload?.coHost?.name}
                                            size="40"
                                            className="userImg"
                                            color={"#00425A"}
                                        />
                                    }
                                </div>
                                <div className="userInfo">
                                    <p className="name">{sessionPayload?.coHost?.name}</p>
                                    <p className="designation">{sessionPayload?.coHost?.employeeDesignation ? sessionPayload?.coHost?.employeeDesignation : "Designation"}</p>
                                </div>
                            </div>
                            <FiEdit onClick={() => {handleChangeSessionInfo(null, "editHost"); setHostSearch("")}} />
                        </div>
                                    :
                                    <>
                            <Search  
        //   ref={wrapperRef}
          >
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Co host"
              inputProps={{ 'aria-label': 'search' }}
              onChange={autoSearchTextHost}
              value={hostSearch}
            />
            {
                !isEmptyNullUndefined(hostSearch) && 
                <IoCloseSharp onClick={() => {setHostSearch(""); setShowAllSearches(false)}} style={{position:"absolute", right:"1rem", top:"34%", color:"gray", cursor:"pointer"}} />
            }

            {
                
                !isEmptyNullUndefined(hostSearch) && 
                <div className="search-popup-req-feed">
                {
                    searchLoader ? 
                    <>                   
                    <div className="search-emp-outer">
                        <div className="empDetaila">
                            <div className="empPic">
                            <Skeleton variant="circular" width={40} height={40} />
                            </div>
                            <div className="userInfo">
                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                            </div>
                        </div>
                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                    </div>
                    <div className="search-emp-outer">
                        <div className="empDetaila">
                            <div className="empPic">
                            <Skeleton variant="circular" width={40} height={40} />
                            </div>
                            <div className="userInfo">
                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width:"3rem" }} /></p>
                            </div>
                        </div>
                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width:"2rem"  }} /></p>
                    </div>
                    </>
                    :
                    searchedList?.length > 0 ?
                    <div>
                    {searchedList?.map((emp, index) => {
                        return(
                            // activeTab === "send" &&
                            
                            <div key={index} className="search-emp-outer">
                                <div className="empDetaila">
                                    <div className="empPic">
                                        {emp?.profilePhotoPath ?
                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar
                                                name={emp?.employeeName}
                                                size="40"
                                                className="userImg"
                                                color={"#00425A"}
                                            />
                                        }
                                    </div>
                                    <div className="userInfo">
                                        <p className="name">{emp?.employeeName}</p>
                                        <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                    </div>
                                </div>
                                {

                                    <button className="search-add-emp-btn" onClick={() => handleChangeSessionInfo(emp, "addHost")}>
                                        Add
                                    </button>
                                }
                                
                            </div>
                           
                        )                        
                    })
                   } 
                   {
                      !showAllSearches && (totalSearchPages > 1) && <div className="view-all-search" onClick={() => setShowAllSearches(true)}>View all</div>
                   }
                  </div>
                    :
                    !isEmptyNullUndefined(hostSearch) &&
                    <div className="no-result">No result</div>
                }
                </div>
            }
          </Search>
          {sessionPayloadErr?.coHost?.isError && (
            <FormHelperText sx={{ color: "#d32f2f" }}>
            {sessionPayloadErr?.coHost?.errMsg}
            </FormHelperText>
            )}
            </>
}
                            </div>
                                    
                                </div>
        </div>
    )
}

export default HostSession;