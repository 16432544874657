import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import logInReducer from "../redux/login/reducers";
import empDataReducer from "../redux/employeeData/reducers";
import selfApraisalDataReducer from "../redux/selfApraisal/reducers";
// import queReducer from "../redux/questions/reducers";
import dropdownReducer from "../redux/dropdown/reducers";
import countsReducer from "../redux/countts/reducers";
import pmscycleReducer from "../redux/pmsCycle/reducers";
import annualcycleReducer from "../redux/annualCycle/reducers";
import notificationReducer from "../redux/notification/reducers";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),    
    user: logInReducer,
    empData: empDataReducer,
    selfApraisalData: selfApraisalDataReducer,
    // questionsData: queReducer,
    dropdownData: dropdownReducer,
    countsReducer: countsReducer,
    pmscycleReducer: pmscycleReducer,
    annualcycleReducer: annualcycleReducer,
    notificationReducer: notificationReducer,
  });

export default createRootReducer;
