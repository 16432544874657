import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import SideDrawer from "../../../../common/sideDrawer";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const parse = require('html-react-parser');

const Trial = ({ sections, isDownloadPdf, setIsDownloadPdf }) => {
    const printRef = useRef();

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [expanded, setExpanded] = useState(null);
    const [showPeerFeedback, setShowPeerFeedback] = useState(false);
    const [peerFeedbackData, setPeerFeedbackData] = useState([]);

    useEffect(() => {
     if(isDownloadPdf){
        handleGeneratePdf();
     }
    }, [isDownloadPdf])
    

    const handleChange = (index) => {
        if (expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index);
        }
    };

    const handleShowPeerFeedback = (question) => {
          setShowPeerFeedback(true);
          setPeerFeedbackData(question);
    }

    const handleGeneratePdf = async () => {
        // const input = printRef.current;
        // const canvas = await html2canvas(input, { scale: 5 });
        // const imgData = canvas.toDataURL('image/png');
        
        // const pdf = new jsPDF('p', 'pt', 'a4');
        // const imgProps = pdf.getImageProperties(imgData);
        // const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
        // // If the content height is greater than the page height, we need to split it into multiple pages
        // let heightLeft = pdfHeight;
        // let position = 0;
    
        // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        // heightLeft -= pdf.internal.pageSize.getHeight();
    
        // while (heightLeft >= 0) {
        //   position = heightLeft - pdfHeight;
        //   pdf.addPage();
        //   pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        //   heightLeft -= pdf.internal.pageSize.getHeight();
        // }
    
        // pdf.save('download.pdf');

    //    < ------------ end ----------- >

        // const input = printRef.current;
        // const pdf = new jsPDF('p', 'mm', 'a4');
        // const pageHeight = pdf.internal.pageSize.getHeight();
        // const pageWidth = pdf.internal.pageSize.getWidth();
        // const margin = 10; // Set margin size in mm
    
        // const canvas = await html2canvas(input, {
        //   scale: 2 // Increase the resolution for better quality
        // });
        // const imgData = canvas.toDataURL('image/png');
        // const imgProps = pdf.getImageProperties(imgData);
        // const imgWidth = pageWidth - 2 * margin; // Account for horizontal margins
        // const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
        // let heightLeft = imgHeight;
        // let position = margin; // Start from the top margin
    
        // pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        // heightLeft -= (pageHeight - 2 * margin); // Account for vertical margins
    
        // while (heightLeft > 0) {
        //   position = heightLeft - imgHeight + margin;
        //   pdf.addPage();
        //   pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        //   heightLeft -= (pageHeight - 2 * margin);
        // }
    
        // pdf.save('download.pdf');

        const input = printRef.current;
        html2canvas(input, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdf = new jsPDF('p', 'mm', [imgWidth, imgHeight]);
        
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`${employeeDetails?.name}-${employeeDetails?.employeeId}-(${sections?.annualReviewCycleName}).pdf`);
        });

        setIsDownloadPdf(false);
      };

 
    return (
        <div className="performance-rate">
            <div className="ratings-annual">
                    <p className="header-annualcycle">
                    <span>{sections?.annualReviewCycleName}</span>                    
                    </p>
            {
                sections?.ratings?.map((rating) => {
                return(
                    <p className="rating-outer">
                        <span>{rating?.name}</span> : <span>{rating?.rating}</span>                    
                    </p>
                )
                })
            }
            </div>
                                            
            {
                sections?.sections?.map((section, index) => {
                    return(<Stack
                        sx={{
                            margin: "1rem 0px",
                            "& .MuiAccordion-rounded": {
                                border: "1px solid #dad2d2",
                                borderRadius: "10px",
                                boxShadow: "none",
                            },
                        }}
                    >
                        <Accordion
                            expanded={expanded === `Tab-${index}`}
                            onChange={(e) => {
                                handleChange(`Tab-${index}`);
                                e.stopPropagation();
                            }}
                            data-value={`Tab-${index}`}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.25rem",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    },
                                }}
                            >
                                <p className="accordian-heading">
                                 {parse(section?.label)}
                                </p>
                                <BsChevronDown
                                    style={{
                                        ...accordianSVGstyle,
                                        transform:
                                            expanded !== `Tab-${index}`
                                                ? "rotate(0deg)"
                                                : "rotate(180deg)",
                                    }}
                                />
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    fontSize: "1rem",
                                }}
                            >
                                <div className="detail-outer">

                                    {
                                        section?.subSections?.map((question, qIndex) => {
                                            return(
                                                <div className={`question-outer ${((qIndex + 1) !== section?.subSections?.length) ? "border-b" : ""}`}>
                                                    <div className="que">{parse(question?.label)}</div>

                                                    {
                                                        !isEmptyNullUndefined(question?.peerFeedbacks) && 

                                                        <div className="peer-feedback-section">
                                                            <div className="peer-feedback-btn-outer">
                                                                <button className="show-peer-feedback-button" onClick={() => handleShowPeerFeedback(question)}>Peer Feedback</button>
                                                            </div>
                                                        </div>

                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.selfAppraisalResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {employeeDetails?.profilePhoto ? 
                                                                    <img src={employeeDetails?.profilePhoto?.path} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={employeeDetails.name} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{employeeDetails?.employeeDesignation? employeeDetails?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">{console.log("question", question)}
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseString) && 
                                                                question?.selfAppraisalResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseBoolean) ? 
                                                                question?.selfAppraisalResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseInteger) && 
                                                                question?.selfAppraisalResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.rating) && 
                                                                question?.selfAppraisalResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.selfAppraisalResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.managerResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {sections?.managerProfilePhotoPath ? 
                                                                    <img src={sections?.managerProfilePhotoPath} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={sections?.managerName} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{sections?.managerName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{sections?.managerDesignation ? sections?.managerDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseString) && 
                                                                question?.managerResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseBoolean) ? 
                                                                question?.managerResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseInteger) && 
                                                                question?.managerResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.rating) && 
                                                                question?.managerResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.managerResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>)
                })
            }
            
            {
                showPeerFeedback && 
                <div className="peer-feedback-in-sidebar">
                    <SideDrawer isOpen={showPeerFeedback} setIsOpen={setShowPeerFeedback} >
                    <div className="heading">Peer Feedback</div>
                    <div className="detail-outer mt-4">
                    <div className="que-peer">{parse(peerFeedbackData?.label)}</div>
                                    {
                                        peerFeedbackData?.peerFeedbacks?.map((peer, qIndex) => {
                                            return(
                                                <div className={`question-outer`}>

                                                    <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            <div className="icon-container">
                                                                <div className="icon-size">
                                                                {peer?.profilePhotoPath ? 
                                                                    <img src={peer?.profilePhotoPath} className="user" alt="User Image" />
                                                                    :
                                                                    <Avatar 
                                                                        name={peer.employeeName} 
                                                                        size="45" 
                                                                        className="userImg"
                                                                        color={"#00425A"}
                                                                    />
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="title-container">
                                                                <div className="upper-title">{peer?.employeeName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{peer?.employeeDesignation? peer?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseString) && 
                                                                peer?.responseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseBoolean) ? 
                                                                peer?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.responseInteger) && 
                                                                peer?.responseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(peer?.responseObject?.rating) && 
                                                                peer?.responseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(peer?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        peer?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                  
                                                    
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                    </SideDrawer>
                </div>
            }



<div className="pdf-content" ref={printRef} >
            {/* <p className="annual-cycle-heading">
                {sections?.annualReviewCycleName}   
            </p> */}
            {/* <p className="emp-name-heading">
                {employeeDetails?.name}   
            </p> */}
           <div className="ratings-annual">
                    <p className="header-annualcycle">
                    <span>{sections?.annualReviewCycleName}</span>                    
                    </p>
            {
                sections?.ratings?.map((rating) => {
                return(
                    <p className="rating-outer">
                        <span>{rating?.name}</span> : <span>{rating?.rating}</span>                    
                    </p>
                )
                })
            }
            </div>
            {
                sections?.sections?.map((section, index) => {
                    return(
                    <Stack
                        sx={{
                            margin: "1rem 0px",
                            "& .MuiAccordion-rounded": {
                                border: "1px solid #dad2d2",
                                borderRadius: "10px",
                                boxShadow: "none",
                            },
                        }}
                    >                               
                                <p className="accordian-heading">
                                  {parse(section?.label)}
                                </p>
                                <div className="detail-outer">
                                    {
                                        section?.subSections?.map((question, qIndex) => {
                                            return(
                                                <div className={`question-outer ${((qIndex + 1) !== section?.subSections?.length) ? "border-b" : ""}`}>
                                                    <div className="que">{parse(question?.label)}</div>                                                   

                                                    {
                                                        !isEmptyNullUndefined(question?.selfAppraisalResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                         
                                                            <div className="title-container">
                                                                <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{employeeDetails?.employeeDesignation? employeeDetails?.employeeDesignation : ""}</div>
                                                            </div>
                                                        </div>

                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">{console.log("question", question)}
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseString) && 
                                                                question?.selfAppraisalResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseBoolean) ? 
                                                                question?.selfAppraisalResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.responseInteger) && 
                                                                question?.selfAppraisalResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.rating) && 
                                                                question?.selfAppraisalResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.selfAppraisalResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.selfAppraisalResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.managerResponseObject) && 
                                                        <div className="emp-response-outer">
                                                        <div className="emp-details">
                                                        <div className="user-container">
                                                            
                                                            <div className="title-container">
                                                                <div className="upper-title">{sections?.managerName?.split(' ').slice(0,2).join(' ')}</div>
                                                                <div className="lower-title">{sections?.managerDesignation ? sections?.managerDesignation : ""}</div>
                                                            </div>

                                                        </div>
                                                        </div>
                                                        <div className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseString) && 
                                                                question?.managerResponseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseBoolean) ? 
                                                                question?.managerResponseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.responseInteger) && 
                                                                question?.managerResponseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.rating) && 
                                                                question?.managerResponseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(question?.managerResponseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        question?.managerResponseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        </div>
                                                    }

                                                    {
                                                        !isEmptyNullUndefined(question?.peerFeedbacks) && 

                                                        <div className="peer-feedback-in-sidebar">
                                                            
                                                            <div className="heading">Peer Feedback</div>
                                                        
                                                       
                                                        <div className="detail-outer">
                                                                        {
                                                                            question?.peerFeedbacks?.map((peer, qIndex) => {
                                                                                return(
                                                                                    <div className={`question-outer`}>
                                    
                                                                                        <div className="emp-response-outer">
                                                                                            <div className="emp-details">
                                                                                            <div className="user-container">
                                                                                          
                                                                                                <div className="title-container">
                                                                                                    <div className="upper-title">{peer?.employeeName?.split(' ').slice(0,2).join(' ')}</div>
                                                                                                    <div className="lower-title">{peer?.employeeDesignation? peer?.employeeDesignation : ""}</div>
                                                                                                </div>

                                                                                            </div>
                                                                                            </div>
                                                                                            <div className="emp-response">
                                                                                                <div className="text-responses">
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseString) && 
                                                                                                    peer?.responseObject?.responseString
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseBoolean) ? 
                                                                                                    peer?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                                                    :
                                                                                                    ""
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.responseInteger) && 
                                                                                                    peer?.responseObject?.responseInteger
                                                                                                    }
                                                                                                    {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.rating) && 
                                                                                                    peer?.responseObject?.rating
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    !isEmptyNullUndefined(peer?.responseObject?.selection) && 
                                                                                                    <div className="selections">
                                                                                                        {
                                                                                                            peer?.responseObject?.selection?.map((selected, sIndex) => {
                                                                                                                return(
                                                                                                                    <div className="selected-value">{selected?.name}</div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                    
                                                                                      
                                                                                        
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                               
                                                       
                                                     
                                                    </div>

                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                           
                    </Stack>)
                })
            }
            

        </div>

        </div>
    )
}

export default Trial;