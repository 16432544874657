import { useEffect, useRef, useState } from "react";
// import BarGraph from "../../../common/barGraph";
// import { viewReportData } from "../dummyData";
import APIList from "../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import SideDrawer from "../../../common/sideDrawer";
import EmpInfo from "./empInfo";
import { IoIosSave, IoMdClose, IoMdSearch } from "react-icons/io";
import { Button, CircularProgress, FormHelperText, InputBase, MenuItem, Select, Stack, TextField, alpha, styled } from "@mui/material";
import DialogPopup from "../../../common/Dialog";
import checkIcon from "../../../../assets/images/popup-check.png";
import { isEmptyNullUndefined } from "../../../utils/utils";
import ChangedReport from "./changedReport";
import filterIcon from "../../../../assets/images/filter.svg";
import FilterSelect2 from "../../../common/filterSelect2";
import { BsChevronDown } from "react-icons/bs";
import downloadIcon from "../../../../assets/images/dashboard/download.png";
import { BASE_URL } from "../../../../api/apiServices";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import BarGraph from "../../../common/BarGraph";
import MultiBarGraph from "../../../common/MultiBarGraph";
import MultiBarGraphRow from "../../../common/MultiBarGraphRow";
import { AiOutlineExpand } from "react-icons/ai";
import { IoMdContract } from "react-icons/io";
import TableSkel from "../../../common/tableSkeleton";
import { BsGrid } from "react-icons/bs";
import { CiBoxList } from "react-icons/ci";
import PaginationMediBulk from "../../../common/paginationBulk";
import LineGraph from "../../../common/lineGraph";
import LineGraphForAll from "../../../common/lineGraphForAll";
// import PaginationMedi from "../../../common/pagination";
// import { viewReport as report } from "../dummyData";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 
 
const ViewReport = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    // const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [arr1, setArr1] = useState(["Bhupendar Jogi", "Laila Sahay"]);
    const [arr2, setArr2] = useState(["Phil Dunphy", "Bhupendar Jogi 1"]);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [empToView, setempToView] = useState(null);
    
    const [sessionInfo, setSessionInfo] = useState(null);
    const [totalEmployees, setTotalEmployees] = useState(null);
    const [draged, setDraged] = useState(null);
    const [movedFrom, setMovedFrom] = useState(null);
    const [movedTo, setMovedTo] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const [mbbRatings, setMbbRatings] = useState([]);
    const [performanceRatings, setPerformanceRatings] = useState([]);

    const [viewReport, setViewReport] = useState([]);
    const [preReport, setPreReport] = useState([]);

    const [autoSaveLoaer, setAutoSaveLoaer] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isDraftPopup, setIsDraftPopup] = useState(false);   

    const [activeTab, setActiveTab] = useState("");

    const [isOpenReasonDialog, setIsOpenReasonDialog] = useState(false);
    const [targetDiv, setTargetDiv] = useState({mbb:"", perform:""});
    const [reasonErr, setReasonErr] = useState(false);

    const [allPostEmployees, setAllPostEmployees] = useState([]);
    const [allPostFilteredEmployees, setAllPostFilteredEmployees] = useState([]);
    const [allPreEmployees, setAllPreEmployees] = useState([]);
    const [search, setSearch] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);
    const [filteredReport, setFilteredReport] = useState([]);
    const [filteredPostReport, setFilteredPostReport] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});

    const [downloadLoader, setDownloadLoader] = useState(false);
    const [postCalibratedRatings, setPostCalibratedRatings] = useState([]);
    const [preCalibratedRatings, setPreCalibratedRatings] = useState([]);
    const [dataForReport, setDataForReport] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownLoader, setDropdownLoader] = useState(false);

    const [expandedBlock, setExpandedBlock] = useState({mbb:"", performance:""});
    const [viewType, setViewType] = useState("DND");

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [ratingsChangesFor, setRatingsChangesFor] = useState(null);
    
    
    const [preCalibratedRatingsForBulkGraph, setPreCalibratedRatingsForBulkGraph] = useState([]);
    const [graphDataForBulkView, setgraphDataForBulkView] = useState([]);
 console.log("graphDataForBulkView",graphDataForBulkView);

useEffect(() => {
    getDropdownData();
}, [])


const getDropdownData = () => {
    // console.log("getPageData");
    setDropdownLoader(true);
    APIList.getDropdownsfForAudit({
        // range: "My Org",                                                                // My Team Or My Org
        companyId: employeeDetails?.company?.id,
    })
    .then((res) => {
        // console.log("res",res)
        setDropdownData(res?.data);
        setDropdownLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDropdownLoader(false);
    })
}

useEffect(() => {
    let all = [];
    viewReport?.forEach((item) => {
        item?.employees?.forEach((itemq) => {
            itemq["mbbRating"] = item?.mbbRating;
            itemq["preformanceRating"] = item?.preformanceRating;
            all.push(itemq);
        })
    })
    setAllPostEmployees(all);
}, [viewReport])

useEffect(() => {
    let all = [];
    filteredPostReport?.forEach((item) => {
        item?.employees?.forEach((itemq) => {
            itemq["mbbRating"] = item?.mbbRating;
            itemq["preformanceRating"] = item?.preformanceRating;
            all.push(itemq);
        })
    })
    setAllPostFilteredEmployees(all);
}, [filteredPostReport])

// const [searchedPostForListGraph, setSearchedPostForListGraph] = useState(graphDataForBulkView);

useEffect(() => {
    setDataLoader(true);
    setActiveTab("");
    const getSearch = setTimeout(() => {
        if(viewType === "DND"){
        APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filterPayload,
            keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            // setViewReport(res?.data?.postCalibratedRatings);
            // setFilteredReport(res?.data?.postCalibratedRatings);
            // } else {
                // setViewReport(res?.data?.preCalibratedRatings);
                setFilteredReport(res?.data?.preCalibratedRatings);
                setFilteredPostReport(res?.data?.postCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            // setAllPreEmployees(all);

            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });

          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })

             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })

            // setMbbRatings(Tempmbb);
            // setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    } else {
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getTableReportData(0, filterPayload);
        }

         // this is for list view graph only
        APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filterPayload,
            keyword: search
        })
        .then((res) => {
                setgraphDataForBulkView(res?.data?.postCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }

    }, 1000);    

    return () => clearTimeout(getSearch);

}, [search]);

const handleSubmitFilter = () => {
    setActiveTab("");
    if(viewType === "DND"){
    setDataLoader(true);  
    APIList.getSessionReportData({
        // companyId: employeeDetails?.company?.id,
        sessionId: location?.state?.sessionid,
        employeeEligibilityDTO: filterPayload,
        keyword: search
    })
    .then((res) => {
        // console.log("res",res)
        // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
        // setViewReport(res?.data?.postCalibratedRatings);
        // setFilteredReport(res?.data?.postCalibratedRatings);
        // } else {
            // setViewReport(res?.data?.preCalibratedRatings);
            setFilteredReport(res?.data?.preCalibratedRatings);
            setFilteredPostReport(res?.data?.postCalibratedRatings);
            setPostCalibratedRatings(res?.data?.postCalibratedRatings);
            setPreCalibratedRatings(res?.data?.preCalibratedRatings);
        // }
        // setPreReport(res?.data?.preCalibratedRatings);
        setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
        let all = [];
        res?.data?.preCalibratedRatings?.forEach((item) => {
            item?.employees?.forEach((itemq) => {
                itemq["mbbRating"] = item?.mbbRating;
                itemq["preformanceRating"] = item?.preformanceRating;
                all.push(itemq);
            })
        })
        // setAllPreEmployees(all);

        
      // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
      const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
        return a?.mbbRanking - b?.mbbRanking;
    });

      const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
        return a?.preformanceRanking - b?.preformanceRanking;
    });

         let Tempmbb = []; 
         let Tempperform = [];

         mbbRankingSortedData?.forEach((item) => {
             if(!Tempmbb.includes(item?.mbbRating)){
             Tempmbb.push(item?.mbbRating);
             }
         })

         preformanceRankingSortedData?.forEach((item) => {
             if(!Tempperform.includes(item?.preformanceRating)){
                 Tempperform.push(item?.preformanceRating);
             }
         })

        // setMbbRatings(Tempmbb);
        // setPerformanceRatings(Tempperform);
        setDataLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDataLoader(false);
    })     
} else {
    if(currentPage !== 0) {
        setCurrentPage(0);
    } else {
        getTableReportData(0, filterPayload);
    }

    // this is for list view graph only
    APIList.getSessionReportData({
        // companyId: employeeDetails?.company?.id,
        sessionId: location?.state?.sessionid,
        employeeEligibilityDTO: filterPayload,
        keyword: search
    })
    .then((res) => {
            setgraphDataForBulkView(res?.data?.postCalibratedRatings);
            setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
            setDataLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setDataLoader(false);
    })
}
} 

// useEffect(() => {
//     // for post
//     let temp = structuredClone(graphDataForBulkView);

//     let all = [];
 
//     tableData?.forEach((emp) => {
//         all.push(emp?.employeeId);
//     })

//     temp?.forEach((x) => {
//         x?.employees?.forEach((y) => {
//             if(!all?.includes(y?.id)){
//                 x["employees"] = x?.employees?.filter(item => item?.id !== y?.id);
//             }
//         })
//     })
//      setgraphDataForBulkView(temp);

//      //for pre
//     let temp2 = structuredClone(preCalibratedRatings);

//     let all2 = [];
 
//     tableData?.forEach((emp) => {
//         all2.push(emp?.employeeId);
//     })

//     temp2?.forEach((x) => {
//         x?.employees?.forEach((y) => {
//             if(!all2?.includes(y?.id)){
//                 x["employees"] = x?.employees?.filter(item => item?.id !== y?.id);
//             }
//         })
//     })
//      setPreCalibratedRatingsForBulkGraph(temp2);

// }, [tableData])

// console.log("graphDataForBulkView", graphDataForBulkView);

useEffect(() => {
    const autoSaveData = setTimeout(() => {
        
        if(!isEmptyNullUndefined(viewReport) && !isOpenReasonDialog) {
            setgraphDataForBulkView(viewReport);
            setAutoSaveLoaer(true);
            APIList.saveSessionReportAsDraft({
                payload: viewReport,
                sessionId : location?.state?.sessionid
            })
            .then((res) => {
                setAutoSaveLoaer(false);
                setDataForReport(res?.data?.postCalibratedRatings);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setAutoSaveLoaer(false);
            })
        }       
                   
    }, 2000)

    return () => clearTimeout(autoSaveData)
  }, [viewReport, isOpenReasonDialog])

  // autosave table data
useEffect(() => {
    const autoSaveData = setTimeout(() => {
        
        if(!isEmptyNullUndefined(tableData) && isEmptyNullUndefined(ratingsChangesFor)) {
            setAutoSaveLoaer(true);
            APIList.saveSessionReportDataForTable({
                payload: tableData,
                sessionId : location?.state?.sessionid,
                status:"Draft"
            })
            .then((res) => {
                setAutoSaveLoaer(false);
                // setTableData(res?.data);
                // setDataForReport(res?.data?.postCalibratedRatings);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setAutoSaveLoaer(false);
            })
        }       
                   
    }, 2000)

    return () => clearTimeout(autoSaveData)
  }, [tableData])

  const handleSaveAsDraft = () => {
    
    if(viewType === "DND"){
        if(isOpenReasonDialog) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                Please provide the reason for {draged?.name}.
                </div>
            );
            } else {
                setSubmitLoader(true);
                APIList.saveSessionReportAsDraft({
                    payload: viewReport,
                    sessionId : location?.state?.sessionid
                })
                .then((res) => {
                    setIsDraftPopup(true);
                    setOpenDialog(true);
                    setSubmitLoader(false);
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSubmitLoader(false);
                })
            }
    } else {
        if(!isEmptyNullUndefined(ratingsChangesFor)) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;Please enter reason for {ratingsChangesFor?.name}
                </div>
            );
        } else {
            setSubmitLoader(true);
            APIList.saveSessionReportDataForTable({
                payload: tableData,
                sessionId : location?.state?.sessionid,
                status:"Draft"
            })
            .then((res) => {
                setIsDraftPopup(true);
                setOpenDialog(true);
                setSubmitLoader(false);
                // setTableData(res?.data);
                // setDataForReport(res?.data?.postCalibratedRatings);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitLoader(false);
            })
        }
    }
  }

  const handleChangeView = (view) => {

    setSearch("");
    setFilterPayload({});

    if(viewType === "DND"){
        if(isOpenReasonDialog) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                Please provide the reason for {draged?.name}.
                </div>
            );
            } else {
                setSubmitLoader(true);
                APIList.saveSessionReportAsDraft({
                    payload: viewReport,
                    sessionId : location?.state?.sessionid
                })
                .then((res) => {
                    // setIsDraftPopup(true);
                    // setOpenDialog(true);
                    setSubmitLoader(false);
                    setViewType(view);
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSubmitLoader(false);
                    setViewType(view);
                })
            }
    } else {
        if(!isEmptyNullUndefined(ratingsChangesFor)) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;Please enter reason for {ratingsChangesFor?.name}
                </div>
            );
        } else {
            setSubmitLoader(true);
            APIList.saveSessionReportDataForTable({
                payload: tableData,
                sessionId : location?.state?.sessionid,
                status:"Draft"
            })
            .then((res) => {
                // setIsDraftPopup(true);
                // setOpenDialog(true);
                setSubmitLoader(false);
                setViewType(view);
                // setTableData(res?.data);
                // setDataForReport(res?.data?.postCalibratedRatings);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitLoader(false);
                setViewType(view);
            })
        }
    }
    
  }

  const handleNext = () => {
    if(viewType === "DND"){
        if(isOpenReasonDialog) {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            Please provide the reason for {draged?.name}.
            </div>
        );
        } else {
                setSubmitLoader(true);
                APIList.saveSessionReportAsDraft({
                    payload: viewReport,
                    sessionId : location?.state?.sessionid
                })
                .then((res) => {
                    setSubmitLoader(false);
                    // history.push({pathname:`/changed-report`, state: {viewReport:viewReport, sessionid:location?.state?.sessionid}})
                    history.push({pathname:`/promotion-view-report`, state: {viewReport:viewReport, sessionid:location?.state?.sessionid, table:false}});
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                        </div>
                    );
                    setSubmitLoader(false);
                })
      
        }
    } else {        
        if(!isEmptyNullUndefined(ratingsChangesFor)) {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;Please enter reason for {ratingsChangesFor?.name}
                    </div>
                );
        } else {
            setSubmitLoader(true);
            APIList.saveSessionReportDataForTable({
                payload: tableData,
                sessionId : location?.state?.sessionid,
                status:"Draft"
            })
            .then((res) => {
                setSubmitLoader(false);
                history.push({pathname:`/promotion-view-report`, state: {viewReport:tableData, sessionid:location?.state?.sessionid, table:true}});
                // setDataForReport(res?.data?.postCalibratedRatings);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSubmitLoader(false);
            })
        }
        
    }
    

  }

  const handleSubmit = () => {
    setSubmitLoader(true);
    APIList.saveSessionReport({
        companyId: employeeDetails?.company?.id,
        payload: viewReport,
        sessionId : location?.state?.sessionid
    })
    .then((res) => {
        setOpenDialog(true);
        setSubmitLoader(false);
    })
    .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
        setSubmitLoader(false);
    })
  }

    useEffect(() => {
       APIList.getCountOnlySessionById({id: location?.state?.sessionid})
       .then((res) => {
          setSessionInfo(res?.data);
       })
       .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
            </div>
        );
    })
    }, [])
    useEffect(() => {
                
        const pageChange = setTimeout(() => {
        
            if(viewType !== "DND") {
                getTableReportData(currentPage, filterPayload);
            }
                       
        }, 1000)
    
        return () => clearTimeout(pageChange)
    }, [currentPage])

    const handleResetFilters = () => {
        setActiveTab("");
        if(viewType === "DND"){
        setFilterPayload({});
        setDataLoader(true);  
        APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: {},
            keyword: search
        })
        .then((res) => {
            // console.log("res",res)
            // if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
            // setViewReport(res?.data?.postCalibratedRatings);
            setFilteredReport(res?.data?.postCalibratedRatings);
            setFilteredPostReport(res?.data?.postCalibratedRatings);
            // } else {
                // setViewReport(res?.data?.preCalibratedRatings);
            //     setFilteredReport(res?.data?.preCalibratedRatings);
            // }
            // setPreReport(res?.data?.preCalibratedRatings);
            setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
            let all = [];
            res?.data?.preCalibratedRatings?.forEach((item) => {
                item?.employees?.forEach((itemq) => {
                    itemq["mbbRating"] = item?.mbbRating;
                    itemq["preformanceRating"] = item?.preformanceRating;
                    all.push(itemq);
                })
            })
            // setAllPreEmployees(all);
    
            
          // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
          const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.mbbRanking - b?.mbbRanking;
        });
    
          const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
            return a?.preformanceRanking - b?.preformanceRanking;
        });
    
             let Tempmbb = []; 
             let Tempperform = [];
    
             mbbRankingSortedData?.forEach((item) => {
                 if(!Tempmbb.includes(item?.mbbRating)){
                 Tempmbb.push(item?.mbbRating);
                 }
             })
    
             preformanceRankingSortedData?.forEach((item) => {
                 if(!Tempperform.includes(item?.preformanceRating)){
                     Tempperform.push(item?.preformanceRating);
                 }
             })
    
            // setMbbRatings(Tempmbb);
            // setPerformanceRatings(Tempperform);
            setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })   
    } else {
        setFilterPayload({});
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getTableReportData(0, {});
        }

         // this is for list view graph only
         APIList.getSessionReportData({
            // companyId: employeeDetails?.company?.id,
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: {},
            keyword: search
        })
        .then((res) => {
                setgraphDataForBulkView(res?.data?.postCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }  
    }


    useEffect(() => {
        if(viewType === "DND"){

            setDataLoader(true);

            // using this API initialy to keep viewReport data (so viewReport data should not change on filter or search)
            APIList.getSessionReportData1({
                companyId: employeeDetails?.company?.id,
                sessionId: location?.state?.sessionid,
            })
            .then((res) => {
                // console.log("res",res)
                if(!isEmptyNullUndefined(res?.data?.postCalibratedRatings)){
                setViewReport(res?.data?.postCalibratedRatings);
                /////use for graph
                setPostCalibratedRatings(res?.data?.postCalibratedRatings);
                setPreCalibratedRatings(res?.data?.preCalibratedRatings);
                } else {
                    setViewReport(res?.data?.preCalibratedRatings);
                }
                setPreReport(res?.data?.preCalibratedRatings);
                setPreCalibratedRatingsForBulkGraph(res?.data?.preCalibratedRatings);
                setTotalEmployees(res?.data?.preCalibratedRatings?.reduce((total, item) => total + item?.employees?.length, 0));
                let all = [];
                res?.data?.preCalibratedRatings?.forEach((item) => {
                    item?.employees?.forEach((itemq) => {
                        itemq["mbbRating"] = item?.mbbRating;
                        itemq["preformanceRating"] = item?.preformanceRating;
                        all.push(itemq);
                    })
                })
                setAllPreEmployees(all);
    
                
              // First, sort the calibrationEntityDTO array by mbbRanking and preformanceRanking
              const mbbRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
                return a?.mbbRanking - b?.mbbRanking;
            });
    
              const preformanceRankingSortedData = res?.data?.preCalibratedRatings?.sort((a, b) => {
                return a?.preformanceRanking - b?.preformanceRanking;
            });
        
                 let Tempmbb = []; 
                 let Tempperform = [];
        
                 mbbRankingSortedData?.forEach((item) => {
                     if(!Tempmbb.includes(item?.mbbRating)){
                     Tempmbb.push(item?.mbbRating);
                     }
                 })
    
                 preformanceRankingSortedData?.forEach((item) => {
                     if(!Tempperform.includes(item?.preformanceRating)){
                         Tempperform.push(item?.preformanceRating);
                     }
                 })
    
                // let Tempmbb = []; 
                // let Tempperform = [];
    
                // res?.data?.preCalibratedRatings?.forEach((item) => {
                //     if(!Tempmbb.includes(item?.mbbRating)){
                //     Tempmbb.push(item?.mbbRating);
                //     }
                //     if(!Tempperform.includes(item?.preformanceRating)){
                //         Tempperform.push(item?.preformanceRating);
                //     }
                // })
    
                setMbbRatings(Tempmbb);
                setPerformanceRatings(Tempperform);
                setDataLoader(false);
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setDataLoader(false);
            })
    
        //     setViewReport(report);
        } else {
            setFilterPayload({});
            if(currentPage !== 0) {
                setCurrentPage(0);
            } else {
                getTableReportData(0, {});
            }
        }
    
    }, [viewType])


// console.log("tableData",tableData)
// const [changedUser, setChangedUser] = useState(null);
    const getTableReportData = (page, filters) => {
        setDataLoader(true);
        APIList.getSessionReportForTable({
            sessionId: location?.state?.sessionid,
            employeeEligibilityDTO: filters,
            filterData: filters?.length > 0 ? true : false,
            keyword: search,
            managerId: employeeDetails?.id,
            companyId: employeeDetails?.company?.id,
            page: page,
            size: 10
        })
        .then((res) => {
           setTableData(res?.data?.data);
           setTotalPages(res?.data?.totalPages);
           setDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDataLoader(false);
        })
    }
    
    // console.log("mbbRatings",mbbRatings)
    // console.log("performanceRatings",performanceRatings)
    // const handleDrag = (e, item, arr) => {
    //     setMovedFrom(arr);
    //     setDraged(item);
    // }
    // const handleDragOver = (e, arr) => {
    //     e.preventDefault();
    //     setActiveRow(arr);
    // }

    // const onEnd = (arr) => {
    //     // console.log("onEnd",arr)
    // }

    // const handleDrop = (arr) => {
    //     arr === "arr1" ? !arr1.includes(draged) && setArr1([draged, ...arr1 ]) : !arr2.includes(draged) && setArr2([draged, ...arr2 ]);
    //     // if(arr !== "arr1" || arr !== "arr2")
    //     setActiveRow(null);
    //     if (arr !== movedFrom) {
    //     setTimeout(() => {
    //         let temparr1 = movedFrom === "arr1" ? structuredClone(arr1) : structuredClone(arr2);
    //         temparr1 = temparr1.filter((x) => x !== draged);
    //         movedFrom === "arr1" ? setArr1(temparr1) : setArr2(temparr1);
        
    //     }, 250);
    // }
    //     setTimeout(() => {
    //         setDraged(null);
        
    //     }, 500);
    // }

    // for dynamic dnd
    const handleDrag = (emp, mbb, performance) => {
        let isToast = false;
        if(isOpenReasonDialog) {
            !isToast &&
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                Please provide the reason for {draged?.name}.
                </div>
            );
            isToast = true;
        } else {
            setMovedFrom({
                mbb:mbb,
                performance:performance
            });
            setDraged(emp);
        }
        
    }
    const handleDragOver = (e, mbb, performance) => {
        if(isOpenReasonDialog) {
            // toast.error(
            //     <div style={{display:"flex", flexDirection:"row"}}>
            //     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            //     Please provide the reason for {draged?.name}.
            //     </div>
            // );
        } else {
            e.preventDefault();
            setActiveRow(`${mbb}-${performance}`);
         }

    }

    const onEnd = (arr) => {
        // console.log("onEnd",arr)
    }

    const handleDrop = (mbb, performance) => {
        if(isOpenReasonDialog) {
            // toast.error(
            //     <div style={{display:"flex", flexDirection:"row"}}>
            //     <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            //     Please provide the reason for {draged?.name}.
            //     </div>
            // );
        } else {

            setMovedTo({
                mbb:mbb,
                performance:performance
            });

            let tempReport = structuredClone(viewReport);

            if(movedFrom?.mbb === mbb && movedFrom?.performance === performance){
    
            } else {
                let reqObj = tempReport?.filter((x) => x?.mbbRating === mbb && x?.preformanceRating === performance)[0];
                reqObj?.employees.push(draged);
            }
    
            if(movedFrom?.mbb === mbb && movedFrom?.performance === performance){
    
            } else {
                let toRemoveFromObj = tempReport?.filter((x) => x?.mbbRating === movedFrom?.mbb && x?.preformanceRating === movedFrom?.performance)[0];
                toRemoveFromObj["employees"] = toRemoveFromObj?.employees?.filter((x) => x?.id !== draged?.id);
                // console.log("toRemoveFromObj",toRemoveFromObj, draged, toRemoveFromObj?.employees?.filter((x) => x?.id !== draged?.id))
            }
    
            if(movedFrom?.mbb === mbb && movedFrom?.performance === performance){
    
            } else {
                setIsOpenReasonDialog(true);
                setTargetDiv({mbb:mbb, perform:performance});
            }
            
            // setTimeout(() => {
            //     setDraged(null);
            
            // }, 500);
            
            setViewReport(tempReport);
            setActiveRow(null);
            setActiveTab("");
        }

    }


    const handleChangeReason = (e) => {
        let tempReport = structuredClone(viewReport);
        let reqObj = tempReport?.filter((x) => x?.mbbRating === targetDiv?.mbb && x?.preformanceRating === targetDiv?.perform)[0];
        let emp = reqObj?.employees?.filter(x => x?.id === draged?.id)[0];
        emp["reason"] = e.target.value;
        setViewReport(tempReport);
        setActiveTab("");
        setReasonErr(false);
        // console.log(viewReport);
    }

    const handleSubmitReason = () => {
        let tempReport = structuredClone(viewReport);
        let reqObj = tempReport?.filter((x) => x?.mbbRating === targetDiv?.mbb && x?.preformanceRating === targetDiv?.perform)[0];
        let emp = reqObj?.employees?.filter(x => x?.id === draged?.id)[0];
        if(isEmptyNullUndefined(emp?.reason)){
            setReasonErr(true);
        } else {
            setIsOpenReasonDialog(false);
            setTimeout(() => {
                setDraged(null);        
            }, 500);
        }        
    }

    const handleCancelReason = () => {
        let tempReport = structuredClone(viewReport);

        let reqObj = tempReport?.filter((x) => x?.mbbRating === movedFrom?.mbb && x?.preformanceRating === movedFrom?.performance)[0];
        reqObj?.employees.push(draged);

        let toRemoveFromObj = tempReport?.filter((x) => x?.mbbRating === movedTo?.mbb && x?.preformanceRating === movedTo?.performance)[0];
        toRemoveFromObj["employees"] = toRemoveFromObj?.employees?.filter((x) => x?.id !== draged?.id);

        setViewReport(tempReport);
        setActiveTab("");
        setIsOpenReasonDialog(false);
    }

    const getDiviserPre = (mbb, performance) => {
        if(activeTab === "Column") {
            return Number(allPreEmployees?.filter(x => x?.preformanceRating === performance)?.length);
        } else if(activeTab === "Row") {
            return Number(allPreEmployees?.filter(x => x?.mbbRating === mbb)?.length);
        } else {
            return totalEmployees;
        } 
    }

    const getDiviserPost = (mbb, performance) => {
        if(activeTab === "Column") {
            return Number(allPostEmployees?.filter(x => x?.preformanceRating === performance)?.length);
        } else if(activeTab === "Row") {
            return Number(allPostEmployees?.filter(x => x?.mbbRating === mbb)?.length);
        } else {
            return totalEmployees;
        } 
    }

    const autoSearchText = (e) => {
        setSearch(e.target.value);
      };

    const isInFiltered = (item) => {
        let all = [];
        filteredReport?.forEach((itemf) => {
            itemf?.employees?.forEach((itemq) => {
                all.push(itemq?.id);
            })
        })
// console.log("all", all, item)
        if(all?.includes(item?.id)){
            return true;
        } else {
            return false;
        }
    }  

    const filteredDataForGraphs = () => {
        let temp = structuredClone(viewReport);

        temp?.forEach((item) => {
            item?.employees?.forEach((emp) => {
                if(!isInFiltered(emp)){
                item["employees"] = item?.employees?.filter(x => x?.id !== emp?.id)
            }
            })
            
        })

        return temp;
    }

    const getFilteredCount = (emps) => {
        let count = 0;
        if(emps?.length === 0){
          return 0;
        } else {
          emps?.forEach((item) => {
            if(isInFiltered(item)){
                count = count + 1;
            }
          })
        }

        return count;
    }

    const getYaxis = () => {
        let arr = [];
        mbbRatings?.forEach((mbb) => {
            let inArr = [];
            inArr.push(mbb);

            performanceRatings?.forEach((performance) => {
                dataForReport?.forEach((item) => {
                    
                    if (item?.mbbRating === mbb && item?.preformanceRating === performance && item?.employees?.length > 0) {
                        let empArr = [];
                        item?.mbbRating === mbb &&
                        item?.preformanceRating === performance &&
                        item?.employees.forEach((emp) => {
                            if (emp?.name) {
                                empArr.push(emp?.name)
                            }
                        }
                        )

                    inArr.push(empArr.join(`, \n`));
                    } else {
                        if (item?.mbbRating === mbb && item?.preformanceRating === performance) {
                            inArr.push("-");

                        }
                    }
                    
                }
                )
            }
            )

            arr.push(inArr);
        })
        return arr;
    }
    
    const data = [
        [" ", ...performanceRatings?.map((performance) => performance)],
        ...getYaxis()
      ];

    //   console.log("data",data)

    const handleDownloadReport = async(e) => {
        
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const dataBlob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(dataBlob, `${sessionInfo?.name}.xlsx`);

    //     setDownloadLoader(true);
    //     // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
    //     axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/session/download/report/${location?.state?.sessionid}`,
    //     {
    // },
    //     {headers: {
    //         'Authorization': `Bearer ${userToken?.id_token}`,
    //         'Content-Type': 'application/json',
    //       },  'responseType': 'arraybuffer'      
    //     }).then((response) => 
    //     {
    //          const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
           
    //       // Trigger the download
    //       saveAs(blob, `${sessionInfo?.name}.xlsx`);
    //       setDownloadLoader(false);
            
    //     })
    //     .catch((err) => {
    //              toast.error(
    //             <div style={{display:"flex", flexDirection:"row"}}>
    //             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //             &nbsp;&nbsp;{err?.title}
    //             </div>
    //         );
    //         setDownloadLoader(false);
    //     });    
     
      }

      const handleBlockExpansion = (mb, per) => {
        if((expandedBlock?.mbb === mb) && (expandedBlock?.performance === per)) {
            setExpandedBlock({mbb:"", performance:""});
        } else {
            setExpandedBlock({mbb:mb, performance:per});
        }        
      }
    //   console.log("graphDataForBulkView", graphDataForBulkView);
      const HandleChangeGraphDataForBulkView = (emp) => {
        setActiveTab("");
        // console.log("emp", emp);
        let changedEmp = {}
        let temp = structuredClone(graphDataForBulkView);

        temp?.forEach((x) => {
            x?.employees?.forEach((y) => {                
            if(y?.id === emp?.employeeId) {
                changedEmp = y;
            }
            })
            x["employees"] = x?.employees?.filter((y) => y?.id !== emp?.employeeId);
        })
        temp?.forEach((x) => {
            if((x?.mbbRating === emp?.postRating?.mbbRating) && (x?.preformanceRating === emp?.postRating?.preformanceRating)) {
                x["employees"].push(changedEmp);

            }
        })
        // console.log("temp", temp, changedEmp);
        setgraphDataForBulkView(temp);
      }

      const handleChange = (e, emp) => {

        if(!isEmptyNullUndefined(ratingsChangesFor) && ratingsChangesFor?.employeeId !== emp?.employeeId) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;Please enter reason for {ratingsChangesFor?.name}
                </div>
            );
        } else {
            let tempChanged = structuredClone(tableData);
  
            let reqEmp = tempChanged?.filter((x) => x?.employeeId === emp?.employeeId)[0];
      
            reqEmp.postRating[e.target.name] = e.target.value;
      
            // setChangedUser(reqEmp);
            if(isEmptyNullUndefined(reqEmp["reason"])){
                setRatingsChangesFor(reqEmp);
            }

            if((reqEmp?.preRating?.preformanceRating === reqEmp?.postRating?.preformanceRating) && (reqEmp?.preRating?.mbbRating === reqEmp?.postRating?.mbbRating)) {
                setRatingsChangesFor(null);
            }
            
            setTableData(tempChanged);
            HandleChangeGraphDataForBulkView(reqEmp);
        }      
        
      }
      const handleChangeReasonInTable = (e, emp) => {
      
        let tempChanged = structuredClone(tableData);
  
        let reqEmp = tempChanged?.filter((x) => x?.employeeId === emp?.employeeId)[0];
  
        reqEmp["reason"] = e.target.value;
  
        // setChangedUser(reqEmp);
        if(!isEmptyNullUndefined(reqEmp["reason"])){
            if(ratingsChangesFor?.employeeId === emp?.employeeId) {
                setRatingsChangesFor(null);
            }            
        }
        setTableData(tempChanged);
        HandleChangeGraphDataForBulkView(reqEmp);
      }

    return(

        <div className="dashboard-main">
            <div className="rating-calibration-report-view">
                <div className="view-report-row">        
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br/> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br/> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br/> Count</div>
                    </div>           
                </div>

                

                

                {
                    activeTab === "Final Report" ? 
                    <ChangedReport sessionId={location?.state?.sessionid} />
                    :
                    <>
                    <div className="outer-layout mt-3">

                    <div className="second-div">
                    <Search>
                        <SearchIconWrapper>
                        <IoMdSearch />
                        </SearchIconWrapper>
                        <StyledInputBase
                        placeholder="Search by username or email"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={autoSearchText}
                        value={search}
                        />
                    </Search>
                    <div className="filter-tab" 
                    onClick={() => setShowFilters(!showFilters)}
                    > 
                        <img src={filterIcon} alt="" />
                        Filter
                        <BsChevronDown
                        style={{ transform: !showFilters ? "rotate(0deg)" : "rotate(180deg)", marginLeft:"0.5rem" }}
                    />
                    </div>
                    {/* <div className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
                        i
                    </div> */}
                    </div>

                    {
                showFilters && 
                <div className="filters-block">
                    <div className="filters">
                        {
                            !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                return (
                                    <FilterSelect2 title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                )
                            })
                        }
                        </div>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={dataLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                        
                </div>
            }

                    <div className="tabs-outer">
                    <div className="down-report">
                        {
                            downloadLoader ? 
                                <>
                                Downloading... <CircularProgress size={29} />
                                </>
                            :
                                <button onClick={handleDownloadReport}>Download report <img src={downloadIcon} alt="" /></button>
                        }
                    </div>
                    <div className="tabs">
                        <div className={`tab ${activeTab === "All" ? "active" : ""}`} onClick={() => setActiveTab("All")}>All</div>
                        <div className={`tab ${activeTab === "Column" ? "active" : ""}`} onClick={() => setActiveTab("Column")}>Column</div>
                        <div className={`tab ${activeTab === "Row" ? "active" : ""}`} onClick={() => setActiveTab("Row")}>Row</div>
                    </div>
                    </div>
                    {
                        dataLoader ? 
                        <div className="loader-outer">
                        <CircularProgress size={29} />
                        </div>
                        :
                    <div className="first-column">
                        <div className="first-row">     
                        <div className="d-flex">
                        </div>   
                        {/* <div className="tableDiv"> 
                                <div className="table-heading">
                                    <div className="col border-0 bgn" style={{width:"10%"}} >&nbsp;</div>
                                    {
                                        performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`}} >{performance}</div>
                                            )                                            
                                        })
                                    }
                                    {
                                        activeTab === "Row" && 
                                        <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`, fontWeight:"500", fontSize:"0.9rem"}} >Total ({totalEmployees})</div> 
                                    }
                                </div>
                                <div className="table-heading">
                                    <div className="col border-0 bgn" style={{width: "10%"}} >&nbsp;</div>
                                    {
                                         performanceRatings?.map((performance) => {
                                            return(
                                                <div className="col" style={{width:`${100/(mbbRatings?.length + 1) - 10}%`}} >
                                                    <div className="pre-post">
                                                        <div className="col-pre" style={{width:`50%`}} >Pre</div>
                                                        <div className="col-post" style={{width:`50%`}} >Post</div>
                                                    </div>
                                                </div>
                                            )                                            
                                        })
                                    }
                                    {
                                        activeTab === "Row" && 
                                        <div className="col" style={{width:`${100/(mbbRatings?.length + 1) - 10}%`}} >
                                        <div className="pre-post">
                                            <div className="col-pre" style={{width:`50%`}} >Pre</div>
                                            <div className="col-post" style={{width:`50%`}} >Post</div>
                                        </div>
                                    </div>
                                    }
                                    
                                </div>
                                {
                                    mbbRatings?.map((mbb) => {
                                    
                                        return(
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn">{mbb}</div>
                                                {
                                                   performanceRatings?.map((performance) => { 
                                                        return (
                                                            <div className="col" >

                                                                {
                                                                    preReport?.map((item) => {
                                                                        return (
                                                                            item?.mbbRating === mbb &&
                                                                            item?.preformanceRating === performance &&
                                                                            <div className="pre-post" >
                                                                                {
                                                                                    item?.employees?.length === 0 ?
                                                                                    <div className="col-pre" style={{ width: `50%` }} > - </div>
                                                                                    :
                                                                                    <div className="col-pre" style={{ width: `50%` }} > {item?.employees?.length} ({(isNaN((Number(item?.employees?.length) / getDiviserPre(mbb, performance))) ? 0 : Number(item?.employees?.length) / getDiviserPre(mbb, performance) * 100).toFixed(0)}%)</div>
                                                                                }
                                                                                
                                                                                <div className="col-post" style={{ width: `50%` }} >
                                                                                    {
                                                                                        viewReport?.map((item) => {
                                                                                            return (
                                                                                                item?.mbbRating === mbb &&
                                                                                                item?.preformanceRating === performance && 
                                                                                                
                                                                                                <> {getFilteredCount(item?.employees) === 0 ? "-" : getFilteredCount(item?.employees)} {getFilteredCount(item?.employees) !== 0 && `(${((isNaN((Number(getFilteredCount(item?.employees)) / getDiviserPost(mbb, performance))) ? 0 : Number(getFilteredCount(item?.employees)) / getDiviserPost(mbb, performance)) * 100).toFixed(0)}%)`}</>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                }

                                                {
                                                    activeTab === "Row" && 
                                                    <div className="col" >

                                                        <div className="pre-post" >
                                                            <div className="col-pre" style={{ width: `50%` }} >
                                                            {
                                                                allPreEmployees?.filter(x => x?.mbbRating === mbb)?.length
                                                            }
                                                            </div>
                                                            <div className="col-post" style={{ width: `50%` }} >
                                                            {
                                                                allPostFilteredEmployees?.filter(x => x?.mbbRating === mbb)?.length
                                                            }
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                                }

                                        
                                                
                                           </div>
                                        )
                                    })
                                } 
                                {
                                    activeTab === "Column" && 
                                            <div className="table-row-full">
                                                <div className="col border-0 bgn" style={{fontWeight:"500", fontSize:"0.9rem"}}>Total ({totalEmployees})</div>
                                                {
                                                    performanceRatings?.map((performance) => { 
                                                        return(
                                                            <div className="col" > 
                                                            
                                                                

                                                                            <div className="pre-post" >
                                                                            <div className="col-pre" style={{width:`50%`}} >
                                                                                {
                                                                                    allPreEmployees?.filter(x => x?.preformanceRating === performance)?.length
                                                                                }
                                                                            </div>
                                                                            <div className="col-post" style={{width:`50%`}} > 
                                                                                {
                                                                                    allPostFilteredEmployees?.filter(x => x?.preformanceRating === performance)?.length
                                                                                }
                                                                            </div>
                                                                            </div>
                                                            
                                                            </div>
                                                        )                                            
                                                    })
                                                }
                                                
                                           </div>
                                }
                               
                            </div>   */}
                            {
                                dataLoader ? 
                                <CircularProgress />
                                :
                            <div>
                                {(activeTab === "All") && (
                                    // <BarGraph data={postCalibratedRatings} />
                                    viewType === "DND" ?
                                    <LineGraphForAll data={filteredDataForGraphs()} preData={filteredReport} />
                                    :
                                    <LineGraphForAll data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} />
                                ) }
                                {(activeTab === "Column") && (
                                    // <MultiBarGraph data={postCalibratedRatings} />

                                    // <MultiBarGraphRow data={postCalibratedRatings} />
                                    
                                        viewType === "DND" ? 
                                        <LineGraph data={filteredDataForGraphs()} preData={filteredReport} ratingType="performance" />
                                        :                                        
                                        <LineGraph data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} ratingType="performance" />

                                ) }
                                {(activeTab === "Row") && (
                                    // <MultiBarGraphRow data={postCalibratedRatings} />

                                    // <MultiBarGraph data={postCalibratedRatings} preData={preCalibratedRatings} />
                                    viewType === "DND" ? 
                                    <LineGraph data={filteredDataForGraphs()} preData={filteredReport} ratingType="mbb" />
                                    :
                                    <LineGraph data={graphDataForBulkView} preData={preCalibratedRatingsForBulkGraph} ratingType="mbb" />
                                ) }
                            </div>
                            }                             
                        </div>  
                        
                    </div>
}
                      
                </div>         

                <div className="tabs-outer-1 mt-4 p-0">
                    {/* <div className="tabs">
                        <div className={`tab ${viewType === "DND" ? "active" : ""}`} onClick={() => setViewType("DND")}>DND</div>
                        <div className={`tab ${viewType === "Table" ? "active" : ""}`} onClick={() => setViewType("Table")}>Table</div>
                    </div> */}
                    <div className="icon-tabs">
                        <div className={`tab tab-1 ${viewType === "DND" ? "active" : ""}`} onClick={() =>  handleChangeView("DND") }><BsGrid /></div>
                        <div className={`tab tab-2 ${viewType === "Table" ? "active" : ""}`} onClick={() => handleChangeView("Table") }><CiBoxList /></div>
                    </div>
                </div>    

                {/* <div className="bar-charts">
                    <BarGraph viewReport={viewReportData} />
                </div> */}
                
                {
                    (viewType === "DND") && 
                    <div className="color-legends-block">
                        <div className="default"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> - Default Ratings</div>
                        <div className="Changed"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> - Changed Ratings</div>
                    </div>
                }

                <div className="outer-layout mt-3">
                    {
                        
                        
                            (viewType === "DND") ? 
                            dataLoader ? 
                            <div className="loader-outer">
                            <CircularProgress size={29} />
                            </div>
                            :
                            <div className="first-column">
                            <div className="first-row">     
                            <div className="d-flex">
                                {/* <div className="w-80">Medibuddy Beliefs (MBB) Performance</div>
                                <div className="w-20">dropdown</div> */}
                            </div>   
                            <div className="tableDiv">
                                    <div className="table-heading" style={{top : ((expandedBlock?.mbb !== "") && (expandedBlock?.performance !== "")) ? "12.9rem" : "-12px"}}>
                                        <div className="col border-0 bgn" style={{width:
                                            // `${100/(mbbRatings?.length + 1)}%`
                                            "10%"
                                            }} >&nbsp;</div>
                                        {
                                            performanceRatings?.map((performance) => {
                                                return(
                                                    <div className="col" style={{width:`${100/(performanceRatings?.length + 1) - (10/performanceRatings?.length)}%`}} >{performance}</div>
                                                )                                            
                                            })
                                        }
                                    </div>
                                    {
                                         mbbRatings?.map((mbb) => {                                    
                                            return(
                                                <div className="table-row-full">
                                                    <div className="col border-0 bgn">{mbb}</div>
                                                    {
                                                       performanceRatings?.map((performance) => {
                                                            return(
                                                                <div className={`col tblData-dnd ${(activeRow === `${mbb}-${performance}`) ? "active" : ""} ${((expandedBlock?.mbb === mbb) && (expandedBlock?.performance === performance)) ? "expanded" : ""}`} onDragOver={(e) => handleDragOver(e, mbb, performance)} onDrop={() => handleDrop(mbb, performance)}>
                                                                    {
                                                                        ((expandedBlock?.mbb === mbb) && (expandedBlock?.performance === performance)) &&
                                                                        <div className="expand-heading">{mbb} - {performance}</div>
                                                                    }
                                                                    <div className="inner-col">
                                                                        {
                                                                            viewReport?.map((item) => {
    
                                                                                return (
                                                                                    item?.mbbRating === mbb &&
                                                                                    item?.preformanceRating === performance &&
                                                                                    item?.employees.map((emp) => {
                                                                                        if (!isInFiltered(emp)) {
                                                                                            return null;
                                                                                        }
                                                                                        return (
                                                                                            <div>
                                                                                                
                                                                                                <div
                                                                                                    className={`dragable-emp ${emp?.reason?.length > 0 ? "green-border" : emp?.defaultRatings ? "yellow-border" : ""}`}
                                                                                                    draggable
                                                                                                    onDragStart={(e) => handleDrag(emp, mbb, performance)}
                                                                                                    onDragEnd={() => onEnd(emp)}
                                                                                                    onClick={() => { setIsOpenDrawer(true); setempToView(emp) }}
                                                                                                >
                                                                                                    {emp?.name}
    
                                                                                                </div>
    
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                )
                                                                            })
                                                                        }
    
    
    
                                                                    </div>
                                                                    {
                                                                        viewReport?.map((item) => {
    
                                                                            return (
                                                                                item?.mbbRating === mbb &&
                                                                                item?.preformanceRating === performance &&
                                                                                item?.employees.map((emp) => {
                                                                                    if (!isInFiltered(emp)) {
                                                                                        return null;
                                                                                    }
                                                                                    return (
                                                                                        isOpenReasonDialog && mbb === targetDiv?.mbb && performance === targetDiv?.perform && (emp?.id === draged?.id) &&
                                                                                        <div className="reason-input">
                                                                                            <div className="heading">Reason <span onClick={() => handleCancelReason()}> <IoMdClose /> </span></div>
                                                                                            <TextField
                                                                                                id="outlined-multiline-flexible"
                                                                                                label="Reason"
                                                                                                placeholder="Enter reason"
                                                                                                multiline
                                                                                                rows={3}
                                                                                                autoFocus={true}
                                                                                                value={item?.employees?.filter(x => x?.id === draged?.id)[0]?.reason ? item?.employees?.filter(x => x?.id === draged?.id)[0]?.reason : ""}
                                                                                                onChange={(e) => handleChangeReason(e)}
                                                                                                sx={{ width: "100%", bgcolor: "var(--graybg)" }}
                                                                                            />
    
                                                                                            {
                                                                                                reasonErr &&
                                                                                                <FormHelperText sx={{ fontSize: "0.75rem", color: "red" }}>Please provide reason</FormHelperText>
                                                                                            }
    
                                                                                            <Button onClick={handleSubmitReason}>Submit</Button>
                                                                                        </div>
    
                                                                                    )
                                                                                })
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        ((expandedBlock?.mbb === mbb) && (expandedBlock?.performance === performance)) ?
                                                                        <IoMdContract onClick={() => handleBlockExpansion(mbb, performance)} />
                                                                        :
                                                                        <AiOutlineExpand onClick={() => handleBlockExpansion(mbb, performance)} /> 
                                                                    }
                                                                 
                                                                </div>
                                                            )                                            
                                                        })
                                                    }                                                  
                                                   
                                               </div>
                                            )
                                        })
                                    }
                                   
                                   
                                </div>                             
                                                                     
                            </div>  
                            
                            </div>
                            :
                            <div className="changed-report">
                            <div className="tablee">
                            <table class="feedback-emp-list-table"> 
                            
                                <tr className="table-head-emp-list"> 
                                    <th className="e-name">
                                    E name 
                                    </th> 
                                    <th className="mid-cols">
                                    Func/ sub-func
                                    </th> 
                                    <th className="mid-cols">
                                    Is Recommended for Promotion
                                    </th> 
                                    <th className="mid-cols">
                                    Rating Revised
                                    </th> 
                                    <th className="mid-cols">
                                    Pre MBSR Performance
                                    </th> 
                                    <th className="mid-cols">
                                    Pre MBSR MBB
                                    </th> 
                                    <th className="mid-cols">
                                    Post MBSR Performance
                                    </th> 
                                    <th className="mid-cols">
                                    Post MBSR MBB
                                    </th> 
                                    
                                    <th className="last-col">Reason</th> 
                                </tr>
                                
                                {
                                    dataLoader ? 
                                    <TableSkel />
                                    :
                                    tableData?.length > 0 ?
                                    tableData?.map((data) => {
                                            return(
                                                <tr>
                                                    <td>
                                                        <div className="emp-i">
                                                            <div className="name">{data?.name}</div>
                                                            <div className="emp-id">{data?.employeeLevel}</div>
                                                        </div>
                                                    </td>
                                                    <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                                    <td>
                                                        <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                                        {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                                        </span>
                                                    </td>
                                                    <td>
                                                    <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                                        {
                                                            ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                                        }
                                                    </span>
                                                    </td>
                                                    <td>{data?.preRating?.preformanceRating}</td>
                                                    <td>{data?.preRating?.mbbRating}</td>
                                                    <td>
                                                        {/* {data?.postRating?.preformanceRating} */}
                                                        <Select
                                                            name="preformanceRating"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={data?.postRating?.preformanceRating} 
                                                            // label="Mbb rating"
                                                            onChange={(e) => handleChange(e, data)}
                                                            sx={{
                                                                // width:"100%", 
                                                                color:"var(--teryiary)",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    border:"none"
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                performanceRatings?.map((rating) => {
                                                                    return(
                                                                        <MenuItem value={rating}>{rating}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                    <td>
                                                        {/* {data?.postRating?.mbbRating} */}
                                                        <Select
                                                            name="mbbRating"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={data?.postRating?.mbbRating}
                                                            // label="Mbb rating"
                                                            onChange={(e) => handleChange(e, data)}
                                                            sx={{
                                                                // width:"100%", 
                                                                color:"var(--teryiary)",
                                                                "& .MuiOutlinedInput-notchedOutline": {
                                                                    border:"none"
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                mbbRatings?.map((rating) => {
                                                                    return(
                                                                        <MenuItem value={rating}>{rating}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                    <td>
                                                        
                                                            <input
                                                            placeholder={(((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating))) ? "" : "Type here"}
                                                            type="text"
                                                            value={data?.reason}
                                                            onChange={(e) => handleChangeReasonInTable(e,data)}
                                                            style={{ backgroundColor: 'var(--graybg)', border:"none", padding:"5px", borderRadius:"5px" }}
                                                            disabled={(((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)))}
                                                            />
                                                            {
                                                                (ratingsChangesFor?.employeeId === data?.employeeId) && 
                                                                <div style={{color:"red", fontSize:"0.75rem"}}>Please enter reason</div>
                                                            }
                                                               
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    :
                                    <div className="no-result">No result found</div>
                                
                                
                                }
                                
                            </table> 

                            </div>
                            {
                                !isEmptyNullUndefined(tableData) && (totalPages > 1) &&
                                <PaginationMediBulk currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                            }
                            </div>
                        
                       
                    }
                      
                                      
                </div>
          
                <div className="d-flex justify-content-end gap-3 pt-2 pb-4 main-btn">
                    <div className="valuesBtn draft">
                        {
                            autoSaveLoaer ? 
                                <>
                                <IoIosSave style={{fontSize:"1.15rem"}} />&nbsp; Saving<span className="auto-save-loader">.....</span>
                                </>
                                :
                                "Auto Save 2 sec"
                        }                                                
                        </div>
                    <div className="valuesBtn save-draft"
                        onClick={() => handleSaveAsDraft()}
                        >
                        {
                            submitLoader ?
                                <CircularProgress size={29} sx={{ color: "var(--primary)" }} />
                                :
                                "Save as Draft"
                        }

                    </div>
                    <button className="valuesBtn next"
                        onClick={() => handleNext()}
                    >
                        {
                            submitLoader ?
                                <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                                :
                                "Next"
                        }
                    </button>
                </div>
                    </>
                }


            </div>

            {
            isOpenDrawer && 
            <div className="calibration-side-drawer">
                <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                    <EmpInfo emp={empToView} />
                </SideDrawer>
            </div>
            }

        {
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/hrb">
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                    {
                        isDraftPopup ? 
                        "Your Session Report has been securely saved as a draft. Continue editing whenever you're ready!"
                        :
                        "Session Report successfully submitted. Thank you for your dedication to the growth and development of team."
                    }                
                </div>
                <button className="button"
                    onClick={() => { history.push("/hrb")}}
                >Done</button>
                </div>
            </DialogPopup>
        }
        {/* {
            isOpenReasonDialog && 
            <DialogPopup openDialog={isOpenReasonDialog} setOpenDialog={setIsOpenReasonDialog} redirect={false} >
                <div className="feedback-dialog-wrap">
                dialog opening
                </div>
            </DialogPopup>
        } */}

       

        </div>
    )
}

export default ViewReport;