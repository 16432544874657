const { createAction } = require('redux-actions');
const ANNUALCYCLE_DATA_REQUEST = 'MEDIBUDDY/ANNUALCYCLE_DATA_REQUEST';
const ANNUALCYCLE_DATA_REQUEST_SUCCESS = 'MEDIBUDDY/ANNUALCYCLE_DATA_REQUEST_SUCCESS';
const ANNUALCYCLE_DATA_REQUEST_FAILED = 'MEDIBUDDY/ANNUALCYCLE_DATA_REQUEST_FAILED';

const AnnualcycleDataRequest = createAction(ANNUALCYCLE_DATA_REQUEST);
const AnnualcycleDataRequestSuccess = createAction(ANNUALCYCLE_DATA_REQUEST_SUCCESS);
const AnnualcycleDataRequestFailed = createAction(ANNUALCYCLE_DATA_REQUEST_FAILED);

const MAN_FEEDBACK_CLOSE_LOOP_REQUEST = 'MEDIBUDDY/MAN_FEEDBACK_CLOSE_LOOP_REQUEST';
const MAN_FEEDBACK_CLOSE_LOOP_REQUEST_SUCCESS = 'MEDIBUDDY/MAN_FEEDBACK_CLOSE_LOOP_REQUEST_SUCCESS';
const MAN_FEEDBACK_CLOSE_LOOP_REQUEST_FAILED = 'MEDIBUDDY/MAN_FEEDBACK_CLOSE_LOOP_REQUEST_FAILED';

const ManFeedbackCloseLoopRequest = createAction(MAN_FEEDBACK_CLOSE_LOOP_REQUEST);
const ManFeedbackCloseLoopRequestSuccess = createAction(MAN_FEEDBACK_CLOSE_LOOP_REQUEST_SUCCESS);
const ManFeedbackCloseLoopRequestFailed = createAction(MAN_FEEDBACK_CLOSE_LOOP_REQUEST_FAILED);

export const actions = {
    AnnualcycleDataRequest,
    AnnualcycleDataRequestSuccess,
    AnnualcycleDataRequestFailed,

    ManFeedbackCloseLoopRequest,
    ManFeedbackCloseLoopRequestSuccess,
    ManFeedbackCloseLoopRequestFailed,
};

export const types = {
    ANNUALCYCLE_DATA_REQUEST,
    ANNUALCYCLE_DATA_REQUEST_SUCCESS,
    ANNUALCYCLE_DATA_REQUEST_FAILED,

    MAN_FEEDBACK_CLOSE_LOOP_REQUEST,
    MAN_FEEDBACK_CLOSE_LOOP_REQUEST_SUCCESS,
    MAN_FEEDBACK_CLOSE_LOOP_REQUEST_FAILED,
};
