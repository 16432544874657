import { useState } from "react";

const MediTooltip = ({msg, children}) => {

    const [showMsg, setShowMsg] = useState(false);
    
    return(
        <div className="tooltip-main">
            
            {
                showMsg && 
                <div className="msg">{msg}</div>
            }            
            
            <div className="child-name" onMouseOver={() => setShowMsg(true)} onMouseOut={() => setShowMsg(false)}>
              {children} 
            </div>

        </div>
    )
}

export default MediTooltip;