import { useEffect, useState } from "react";
import APIList from "../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { dateFnsLocalizer } from "react-big-calendar";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { isEmptyNullUndefined } from "../../../../utils/utils";
import AccSkeleton from "../../../../common/akkSkeleton";
import Avatar from "react-avatar";

const ViewFeedback = () => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [feedbackData, setFeedbackData] = useState(null);
    const [feedbackDataLoader, setFeedbackDataLoader] = useState(false);
    const [empInfo, setEmpInfo] = useState(null);
    const [empInfoLoader, setEmpInfoLoader] = useState(false);

    useEffect(() => {

        setEmpInfoLoader(true);        
        APIList.getEmployeeById({id:params?.id})
        .then((res) => {
            setEmpInfo(res?.data);
            setEmpInfoLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            )
            setEmpInfoLoader(false);
        });

        setFeedbackDataLoader(true);
        APIList.getFeedbackById({id:location?.state?.employeeId ,type:"manager"})
        .then((res) => {
            if(!isEmptyNullUndefined(res?.data) || Object.keys(res?.data)?.length !== 0) {
                setFeedbackData(res?.data[0]);
            } 
            setFeedbackDataLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.title}
                </div>
            )
            setFeedbackDataLoader(false);
        });
    }, [])
    
// console.log("empInfo-feedbackData", empInfo, feedbackData)
    return(
        <div className="view-feedback-main">
            {
                (feedbackDataLoader || empInfoLoader) ? 
                <AccSkeleton />
                :
                <div className="feedbackAssessment m-3">
                        <div className="d-flex justify-content-between align-items-center bor-b">
                            <div className="left d-flex align-items-center gap-3">
                                {empInfo?.profilePhoto ?
                                    <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
                                    :
                                    <Avatar 
                                        name={empInfo?.name} 
                                        size="75" 
                                        className="userImg"
                                        color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                                    />
                                }
                                    <div>
                                        <p className="title">{empInfo?.name}</p>
                                        <p className="id">{empInfo?.employeeId}</p>
                                    </div>
                            </div>
                            <div>
                            {/* <button className="viewBtn" onClick={() => handleOpenSideDrawer(empInfo, "empProfile")}>View Profile</button> */}
                            </div>
                        </div>
                        
                       
                        
                </div>  
            }
            
        </div>
    )
};

export default ViewFeedback;