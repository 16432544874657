import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import APIList from "../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import TableSkel from "../../../../common/tableSkeleton";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { data } from "autoprefixer";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { isEmptyNullUndefined } from "../../../../utils/utils";

const PrmotionApprove = () => {


    const history = useHistory();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [sessionInfo, setSessionInfo] = useState(null);
    const [viewReport, setViewReport] = useState([]);
    const [pData, setPData] = useState([]);
    const [pDataLoader, setPDataLoader] = useState(false);
    const [selectedEmps, setSelectedEmps] = useState([]);
    const [approveLoader, setApproveLoader] = useState(false);

    useEffect(() => {

        setViewReport(location?.state?.viewReport);

        APIList.getCountOnlySessionById({ id: location?.state?.sessionid })
            .then((res) => {
                setSessionInfo(res?.data);
            })
            .catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                );
            })

        setPDataLoader(true);
        APIList.getElligibleForPromotion({ id: location?.state?.sessionid })
            .then((res) => {
                setPData(res?.data);

                let yeaArr = [];
                res?.data?.forEach((x) => {
                    if (!isEmptyNullUndefined(x?.promotionApproved)) {
                        if (x?.promotionApproved === "Yes") {
                            yeaArr.push(x?.employeeId);
                        }
                    }
                })
                setSelectedEmps(yeaArr);
                setPDataLoader(false);
                //    if(isEmptyNullUndefined(res?.data)){
                //     history.push({pathname:`/changed-report`, state: {viewReport:location?.state?.viewReport, sessionid:location?.state?.sessionid}})
                //    }
            })
            .catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setPDataLoader(false);
            })

    }, [])

    const getRejects = () => {
        let arr = [];

        pData?.forEach((x) => {
            if (!selectedEmps?.includes(x?.employeeId)) {
                arr.push(x?.employeeId)
            }
        })

        return arr;
    }


    const handleApprove = (sess, aType) => {
        setApproveLoader(true);
        APIList.approvePromotions({
            approve: selectedEmps,
            reject: getRejects()
        })
            .then((res) => {
                toast.success(
                    <div className="flex flex-row">
                        <BsFillCheckCircleFill
                            style={{ width: "2rem", height: "2rem" }}
                        />
                        {` Approved successfully`}
                    </div>
                );
                history.push({ pathname: `/changed-report`, state: { viewReport: viewReport, sessionid: location?.state?.sessionid, table: location?.state?.table} })
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setApproveLoader(false);
            })

    }

    const handleSelectEmps = (e, data) => {

        let temp = structuredClone(selectedEmps);

        if (e.target.value === "Yes") {
            if (!temp?.includes(data?.employeeId)) {
                temp?.push(data?.employeeId);
            }
        }
        if (e.target.value === "No") {
            if (temp?.includes(data?.employeeId)) {
                temp = temp?.filter((x) => x !== data?.employeeId);
            }
        }

        // if(temp?.includes(data?.employeeId)) {
        //     temp = temp?.filter((x) => x !== data?.employeeId);
        // } else {
        //     temp?.push(data?.employeeId);
        // }

        setSelectedEmps(temp);

    }

    const handleSelectAll = (e) => {

        if (e.target.value === "allYes") {
            const allIds = pData?.map(x => x?.employeeId);
            setSelectedEmps(allIds);
        }
        if (e.target.value === "allNo") {
            setSelectedEmps([]);
        }

        // if(isAllAdded()) {
        //     setSelectedEmps([]);
        // } else {            
        //     const allIds = pData?.map( x => x?.employeeId);
        //     setSelectedEmps(allIds);
        // }        
    }

    const isAllAdded = () => {
        const allIds = pData?.map(x => x?.employeeId);
        const AllSelectedIds = selectedEmps;

        const allEntriesPresent = allIds.every(item => AllSelectedIds.includes(item));

        return allEntriesPresent;
    }

    // const pData = [
    //     {
    //         "employeeId": "2e6e7adc-c851-45a4-b535-ae1cec3f7952",
    //         "employeeLevel": "Grade 1",
    //         "employeeType": "On Roll ",
    //         "manager": "Yuvraj Manual Manager",
    //         "name": "demo user 2",
    //         "employeeSubFunction": "18",
    //         "employeeFunction": "Technical Program Management",
    //         "employmentStatus": "Active",
    //         "preRating": {
    //             "preformanceRating": "Strong",
    //             "mbbRating": "Exceeds "
    //         },
    //         "postRating": {
    //             "preformanceRating": "Adequate",
    //             "mbbRating": "Outstanding"
    //         },
    //         "reason": "asasasa",
    //         "eligibleForPromotion": "Yes",
    //         "promotionApproved": "Yes"
    //     }
    // ]
    return (
        <div className="promo-page-rating-calibration">
            <div className="rating-calibration-report-view">
                <div className="view-report-row">
                    <div className="blockBlue">
                        <div className="number">{sessionInfo?.organiser?.name}</div>
                        <div className="text">Host<br /> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHost?.name}</div>
                        <div className="text">Co Host<br /> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.name}</div>
                        <div className="text">Session<br /> Name</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.participantCount}</div>
                        <div className="text">Total<br /> Participants</div>
                    </div>
                    <div className="block">
                        <div className="number">{sessionInfo?.coHortCount}</div>
                        <div className="text">Cohort<br /> Count</div>
                    </div>
                </div>
            </div>

            <div className="page-title">Promotion Review</div>

            {
                approveLoader ?
                    <div className="second-div">
                        <button className="approve-all-btn">
                            <CircularProgress size={27} />
                        </button>
                    </div>
                    :
                    <div className="second-div">
                        {
                            isEmptyNullUndefined(pData) ?
                                <button className="approve-next-btn" onClick={() => history.push({ pathname: `/changed-report`, state: { viewReport: location?.state?.viewReport, sessionid: location?.state?.sessionid } })}>
                                    Next
                                </button>
                                :
                                <button className="approve-all-btn" onClick={() => handleApprove(null, "multiple")}>
                                    Approve
                                </button>
                        }

                    </div>
            }

            <div className="tablee">
                <table class="feedback-emp-list-table">

                    <tr className="table-head-emp-list">
                        <th className="e-name" onClick={handleSelectAll}>
                            {/* {
                    isAllAdded() ?
                    <MdOutlineCheckBox />
                    :
                    <MdOutlineCheckBoxOutlineBlank />
                } */}
                            E name
                        </th>
                        <th className="mid-cols">
                            Func/ sub-func
                        </th>
                        <th className="mid-cols-small">
                            Is Recommended for Promotion
                        </th>
                        <th className="mid-cols-small">
                            <div>
                                Promotion approval
                            </div>
                            <FormControl sx={{ width: "6rem" }}>
                                <InputLabel id="demo-simple-select-label" sx={{ borderWidth: "0px" }}>Select</InputLabel>
                                <Select
                                    // size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isAllAdded() ? "allYes" : (selectedEmps?.length === 0) ? "allNo" : null}
                                    label="Select"
                                    onChange={(e) => handleSelectAll(e)}
                                    size="small"
                                    sx={{
                                        width: "100%",
                                        //backgroundColor: "#dedede",
                                        "& fieldset": {
                                            border: "1px solid #ffffff",
                                            borderColor: "#ffffff",
                                            borderWidth: "0px",
                                        },
                                        "& .MuiInputBase-input:select": {
                                            border: "1px solid #ffffff",
                                            borderColor: "#ffffff",
                                            borderWidth: "0px",
                                        },
                                        "& .MuiInputBase-input:focus": {
                                            border: "2px solid #ffffff", // focus
                                        },
                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                            backgroundColor: "#ffffff",
                                            margin: "0px",
                                            paddingLeft: "0.5rem",
                                        },
                                        //   ...requiredSelectStyled
                                    }}
                                >
                                    <MenuItem value="allYes" sx={{ fontSize: "0.9rem", fontFamily: "poppins" }}>All Yes</MenuItem>
                                    <MenuItem value="allNo" sx={{ fontSize: "0.9rem", fontFamily: "poppins" }}>All No</MenuItem>
                                </Select>
                            </FormControl>

                        </th>
                        <th className="mid-cols-small">
                            Rating Revised
                        </th>
                        <th className="mid-cols-medium">
                            Pre MBSR Performance
                        </th>
                        <th className="mid-cols-medium">
                            Pre MBSR MBB
                        </th>
                        <th className="mid-cols-medium">
                            Post MBSR Performance
                        </th>
                        <th className="mid-cols-medium">
                            Post MBSR MBB
                        </th>

                        <th className="last-col-large">Reason</th>
                    </tr>

                    {
                        pDataLoader ?
                            <TableSkel />
                            :

                            pData?.map((data) => {
                                if(!data?.eligibleForPromotionApproval){
                                    return null;
                                }
                                return (
                                    <tr>
                                        <td>
                                            <div className="title" onClick={() => handleSelectEmps(data)}>
                                                {/* {
                                           selectedEmps?.includes(data?.employeeId) ?
                                            <MdOutlineCheckBox />
                                            :
                                            <MdOutlineCheckBoxOutlineBlank />
                                        } */}
                                                <div className="emp-i">
                                                    <div className="name">{data?.name}</div>
                                                    <div className="emp-id">{data?.employeeLevel}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data?.employeeFunction}/ {data?.employeeSubFunction}</td>
                                        <td>
                                        <span className={`${(data?.eligibleForPromotion === "Yes") ? "revised" :"not-revised"}`}>
                                            {isEmptyNullUndefined(data?.eligibleForPromotion) ? "-" : data?.eligibleForPromotion}
                                        </span>
                                        </td>
                                        <td>
                                            <FormControl sx={{ width: "6rem" }}>
                                                {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:"0.9rem"}}>Select</InputLabel> */}
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedEmps?.includes(data?.employeeId) ? "Yes" : "No"}
                                                    label="Age"
                                                    onChange={(e) => handleSelectEmps(e, data)}
                                                    sx={{
                                                        color: selectedEmps?.includes(data?.employeeId) ? "#69C88E" : "red",
                                                        width: "100%",
                                                        //backgroundColor: "#dedede",
                                                        "& fieldset": {
                                                            border: "1px solid #ffffff",
                                                        },
                                                        "& .MuiInputBase-input:focus": {
                                                            border: "2px solid #ffffff", // focus
                                                        },
                                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                                            backgroundColor: "#ffffff",
                                                            margin: "0px",
                                                            paddingLeft: "0.5rem",
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="Yes" sx={{ color: "#69C88E" }}>Yes</MenuItem>
                                                    <MenuItem value="No" sx={{ color: "red" }}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td>
                                            <span className={`${((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "not-revised" :"revised"}`}>
                                            {
                                                ((data?.preRating?.preformanceRating === data?.postRating?.preformanceRating) && (data?.preRating?.mbbRating === data?.postRating?.mbbRating)) ? "No" : "Yes"
                                            }
                                            </span>
                                        </td>
                                        <td>{data?.preRating?.preformanceRating}</td>
                                        <td>{data?.preRating?.mbbRating}</td>
                                        <td>{data?.postRating?.preformanceRating}</td>
                                        <td>{data?.postRating?.mbbRating}</td>
                                        <td><div className="the-reason">{data?.reason}</div></td>
                                    </tr>
                                )
                            })



                    }

                </table>
                {
                    (!pDataLoader && isEmptyNullUndefined(pData)) ?
                        <div className="no-data">No data found</div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default PrmotionApprove;