import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { GrClose } from "react-icons/gr";
import { Stack } from '@mui/material';


export default function SideDrawer({isOpen, setIsOpen, children}) { 
// console.log("isOpen",isOpen);

const [disappear, setDisappear] = useState(false);

function HandleOutsideClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.classList.value.includes("Mui") &&
          !event.target.classList.value.includes("PrivatePickers") &&
          !event.target.classList.value.includes("1v994a0") &&
          !event.target.classList.value.includes("1dozdou") &&
          !event.target.classList.value.includes("1n2mv2k") &&
          !event.target.classList.value.includes("l0iinn") &&
          !event.target.classList.value.includes("i6bazn") &&
          !event.target.classList.value.includes("mvmu")
        ) {
            setDisappear(true);
            setTimeout(() => {
              setIsOpen(false);
            }, 400);            
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  HandleOutsideClick(wrapperRef);

  const handleCloase = () => {
    setDisappear(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 400);
  }
 

  return (
          <div className={`${disappear ? "d-sidedrawer-notification":"sidedrawer-notification"}`} ref={wrapperRef}>

            <div className='close-drawer-icon'>
              <BsXLg onClick={() => handleCloase()} />  
            </div>

            <div className='drawer-children-main'>
              {children}
            </div>  

          </div>
  );
}